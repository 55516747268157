export const Characters = {
    Empty: '',
    Space: ' ',
    Underline: '_',
    Slash: '/',
    Colon: ':',
    Semicolon: ';',
    Comma: ',',
    Period: '.',
    Pipe: '|'
};
