import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { duplicateService } from '@/services/duplicate';
import { timeUnits } from '@/utils/helpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useDuplicateItemEdit() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { fetchAllBets, betOptions } = useGameBetList();

    const model = reactive({
        id: route.params.id,
        name: null,
        type: 0,
        betId: null,
        cost: 0,
        prizes: '',
        round: 0,
        game: 1,
        timeUnit: 0,
        timeDuration: 0,
        prepareSeconds: 0,
        openSeconds: 0,
        closeSeconds: 0,
        rankingSeconds: 0,
        publishTime: new Date(),
        unpublishTime: null,
        thumbnail: null,
        thumbnailFile: null
    });

    const validations = {
        name: { required },
        prepareSeconds: { required, integer, min: minValue(1) },
        openSeconds: { required, integer, min: minValue(1) },
        closeSeconds: { required, integer, min: minValue(1) },
        rankingSeconds: { required, integer, min: minValue(0) },
        unpublishTime: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchAllBets();

        const response = await duplicateService.item.get(model.id);
        if (response.code === 200) {
            const data = response.data;
            data.publishTime = new Date(data.publishTime);
            if (data.unpublishTime) {
                data.unpublishTime = new Date(data.unpublishTime);
            } else {
                data.unpublishTime = null;
            }

            Object.assign(model, data);
        } else {
            toast.error(response.data);
            router.push(DUPLICATE_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await duplicateService.item.update(
                model.id,
                model.name,
                model.prepareSeconds,
                model.openSeconds,
                model.closeSeconds,
                model.rankingSeconds,
                model?.unpublishTime?.getTime() ?? null,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push(DUPLICATE_ROUTER.ITEM.INDEX());
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, fetch, betOptions, actions, timeUnits };
}
