import http from '@/utils/axios';

export const arenaService = {
    setting: {
        get() {
            return http.doGet('/admin/arena/setting');
        },
        update(levelTimeout: any, groupTimeout: any, findingMatchTimeout: any) {
            return http.doPut('/admin/arena/setting', {
                levelTimeout: levelTimeout,
                groupTimeout: groupTimeout,
                findingMatchTimeout: findingMatchTimeout
            });
        },
        remove() {
            return http.doDelete('/admin/arena/setting');
        }
    },

    item: {
        create(
            cloneId: any,
            name: any,
            betId: any,
            maxRejection: any,
            price: any,
            jackpotInit: any,
            jackpotWinPercent: any,
            jackpotDonation: any,
            prizes: any,
            ticketType: any,
            startTime: any,
            endTime: any,
            closeTime: any,
            publishTime: any,
            unpublishTime: any
        ) {
            return http.doPost('admin/arena/item', {
                cloneId: cloneId,
                name: name,
                betId: betId,
                maxRejection: maxRejection,
                price: price,
                jackpotInit: jackpotInit,
                jackpotWinPercent: jackpotWinPercent,
                jackpotDonation: jackpotDonation,
                prizes: prizes,
                ticketType: ticketType,
                startTime: startTime,
                endTime: endTime,
                closeTime: closeTime,
                publishTime: publishTime,
                unpublishTime: unpublishTime
            });
        },

        update(
            id: any,
            name: any,
            betId: any,
            maxRejection: any,
            price: any,
            jackpotInit: any,
            jackpotWinPercent: any,
            jackpotDonation: any,
            prizes: any,
            ticketType: any,
            startTime: any,
            endTime: any,
            closeTime: any,
            publishTime: any,
            unpublishTime: any
        ) {
            return http.doPost('admin/arena/item/' + id, {
                name: name,
                betId: betId,
                maxRejection: maxRejection,
                price: price,
                jackpotInit: jackpotInit,
                jackpotWinPercent: jackpotWinPercent,
                jackpotDonation: jackpotDonation,
                prizes: prizes,
                ticketType: ticketType,
                startTime: startTime,
                endTime: endTime,
                closeTime: closeTime,
                publishTime: publishTime,
                unpublishTime: unpublishTime
            });
        },

        enable(id: any) {
            return http.doPut('admin/arena/item/' + id + '/enable');
        },

        disable(id: any) {
            return http.doPut('admin/arena/item/' + id + '/disable');
        },

        archive(id: any) {
            return http.doPut('admin/arena/item/' + id + '/archive');
        },

        remove(id: any) {
            return http.doDelete('admin/arena/item/' + id);
        },

        get(id: any) {
            return http.doGet('admin/arena/item/' + id);
        },

        list({ offset, limit }: any) {
            return http.doGet('admin/arena/item', { offset, limit });
        }
    },

    medal: {
        getLinked({ arenaId }: any) {
            return http.doGet('admin/arena/' + arenaId + '/medal');
        },

        link(arenaId: any, medalIds: any) {
            return http.doPut('admin/arena/' + arenaId + '/medal/link', {
                medalIds: medalIds
            });
        },

        unlink(arenaId: any, medalIds: any) {
            return http.doPut('admin/arena/' + arenaId + '/medal/unlink', {
                medalIds: medalIds
            });
        },

        create(name: any, rank: any, image: any) {
            return http.doPost(
                'admin/arena/medal',
                {
                    name: name,
                    rank: rank
                },
                {
                    image: image
                }
            );
        },

        update(id: any, name: any, rank: any, image: any) {
            return http.doPost(
                'admin/arena/medal/' + id,
                {
                    name: name,
                    rank: rank
                },
                {
                    image: image
                }
            );
        },

        remove(id: any) {
            return http.doDelete('admin/arena/medal/' + id);
        },

        get(id: any) {
            return http.doGet('admin/arena/medal/' + id);
        },

        list() {
            return http.doGet('admin/arena/medal');
        }
    },

    rank: {
        list({ offset, limit, arenaId, accountId, sortOrder }: any) {
            return http.doGet('admin/arena/rank/' + arenaId, { offset, limit, accountId, sortOrder });
        }
    },

    ticket: {
        update(type: any, name: any) {
            return http.doPut('admin/arena/ticket', {
                type: type,
                name: name
            });
        },

        get(type: any) {
            return http.doGet('admin/arena/ticket/' + type);
        },

        list() {
            return http.doGet('admin/arena/ticket');
        },

        remove(type: any) {
            return http.doDelete('admin/arena/ticket/' + type);
        }
    }
};
