import { useRfmGroupList } from '@/modules/rfm/group/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { rfmService } from '@/services/rfm';
import { useFetchList } from '@/utils/useFetchList';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';

export function useRfmRecencyList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { rfmGroupId, rfmGroupOptions, fetchRfmGroupAll } = useRfmGroupList();

    const { itemList, total, fetchList } = useFetchList({
        request: rfmService.recency.list,
        params: computed(() => ({ groupId: rfmGroupId.value })),
        beforeFetch: async () => await fetchRfmGroupAll(),
        transformResponse: (list: any[]) =>
            list.map((item: any) => ({
                ...item,
                groupName: rfmGroupOptions.value.find(g => g.value == item.groupId)?.label
            }))
    });

    const { itemList: recencyAll, fetchList: fetchRecencyAll } = useFetchList({ request: rfmService.recency.list });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await rfmService.recency.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, rfmGroupOptions, rfmGroupId, actions, recencyAll, fetchRecencyAll };
}
