import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetchList';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarBundleItem() {
    const dialog = useVDialog();
    const route = useRoute();
    const toast = useToast();

    const bundleId = route.params.bundleId ?? null;
    const bundle = ref(null);

    const { itemList, total, fetchList } = useFetchList({
        request: avatarService.bundle.listItem,
        params: { bundleId },
        preCheck: async () => {
            const response = await avatarService.bundle.get(bundleId);
            if (response.code === 200) {
                bundle.value = response.data;
                return true;
            }
            // Cannot find bundleId
            toast.error(response.data);
            return false;
        }
    });

    const actions = {
        unlink: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to unlink this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.bundle.unlink(bundleId, itemId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, bundle, actions };
}
