import http from '@/utils/axios';

export default {
    list({ offset, limit, criteria }: any) {
        return http.doGet('admin/locale', { offset, limit, criteria });
    },

    listSupported() {
        return http.doGet('admin/locale/supported');
    },

    listSystemSupported() {
        return http.doGet('admin/locale/system/supported');
    },

    add(locale: string) {
        return http.doPut('admin/locale/' + locale);
    },

    remove(locale: string) {
        return http.doDelete('admin/locale/' + locale);
    },

    setDefault(locale: string) {
        return http.doPut('admin/locale/' + locale + '/default');
    },

    publish(locale: string) {
        return http.doPut('admin/locale/' + locale + '/publish');
    },

    unpublish(locale: string) {
        return http.doPut('admin/locale/' + locale + '/unpublish');
    }
};
