
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppCard, AppButton, AppDateTimePicker } from '@/components';
import { useVersionAdd } from '@/modules/version/model/add';
import { VersionOptions } from '@/modules/version/model/mixins';

export default defineComponent({
    name: 'VersionAdd',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton, AppDateTimePicker },
    setup() {
        const { model, v$, storeId, create } = useVersionAdd();

        return { v$, model, storeId, VersionOptions, create };
    }
});
