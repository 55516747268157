import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { STORE_MODULES } from '@/store';
import { AvatarDefaultFilter } from '@/store/avatar/default';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { Ref } from 'vue';
import { useToast } from 'vue-toastification';

export function useAvatarDefaultList() {
    const dialog = useVDialog();
    const toast = useToast();

    const filter: Ref<AvatarDefaultFilter> = stateFromStore<AvatarDefaultFilter>({
        moduleName: STORE_MODULES.AvatarDefault.name,
        getterFuncName: 'filter',
        setterFuncName: 'changeFilter'
    });

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: avatarService.default.list,
        params: filter,
        pageFromStore: pageFromStore(STORE_MODULES.AvatarDefault.name)
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.default.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchList();
                        }
                    }
                }
            });
        }
    };

    return { filter, itemList, total, currentPage, fetchList, actions };
}
