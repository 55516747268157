import { VERSION_ROUTER } from '@/modules/version/router';
import versionService from '@/services/version';
import { STORE_MODULES } from '@/store';
import { stateFromStore } from '@/utils/storeHelper';
import { validateDateAfter, validateFormat } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useVersionAdd() {
    const router = useRouter();
    const toast = useToast();

    const storeId = stateFromStore<number>({
        moduleName: STORE_MODULES.Version.name,
        getterFuncName: 'store',
        setterFuncName: 'setStore'
    });

    const model = reactive({
        version: '',
        assetLink: '',
        releasedDate: null
    });

    const validations = {
        assetLink: { required },
        releasedDate: { validateDate: validateDateAfter() },
        version: {
            required,
            format: validateFormat(/^\d+(.\d+){0,3}$/, 'Please enter in the correct format: Ex: 1.0.2')
        }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await versionService.create(
            storeId.value,
            model.version,
            model.assetLink,
            model.releasedDate?.getTime()
        );
        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(VERSION_ROUTER.INDEX());
        } else {
            toast.error(response.data);
        }
    };

    return { storeId, model, v$, create };
}
