
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect } from '@/components';
import { AppFileUpload, AppImageHolder, AppSwitch, AppButton } from '@/components';
import { useShopItemAdd } from '@/modules/shop/model/add';
import { ShopItemType, Options } from '@/modules/shop/model/mixins';

export default defineComponent({
    name: 'ShopAdd',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppFileUpload, AppImageHolder, AppSwitch, AppButton },
    setup() {
        const { model, v$, fetch, actions, iapOptions } = useShopItemAdd();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, iapOptions, ShopItemType, Options };
    }
});
