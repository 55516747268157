import { duplicateService } from '@/services/duplicate';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';

export function useDuplicateRank() {
    const route = useRoute();

    const filter = reactive({
        accountId: null,
        duplicateItemId: route.params.itemId,
        duplicateCycleId: route.params.cycleId
    });

    const { itemList, total, fetchList, currentPage } = useFetchList({
        request: duplicateService.account.getRankList,
        params: filter,
        pageFromStore: pageFromStore(STORE_MODULES.DuplicateRank.name)
    });

    return { filter, itemList, total, currentPage, fetchList };
}
