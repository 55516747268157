
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppCard, AppInput, AppButton } from '@/components';
import { usePicwordSetting } from '@/modules/picword/setting/model/edit';

export default defineComponent({
    name: 'PicwordSetting',
    components: { AppFormRow, AppCard, AppInput, AppButton },
    setup() {
        const { model, v$, fetch, actions } = usePicwordSetting();

        onMounted(async () => {
            await fetch();
        });

        return { model, v$, actions };
    }
});
