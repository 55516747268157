import { useAvatarColorList } from '@/modules/avatar/color/model/list';
import { useAvatarLayerList } from '@/modules/avatar/layer/model/list';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarImageList() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        itemId: route.params.itemId,
        itemName: null,
        categoryId: null
    });

    const { colorMap, fetchList: fetchColors } = useAvatarColorList();
    const { layerMap, fetchList: fetchLayers } = useAvatarLayerList();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: avatarService.image.list,
        pageFromStore: pageFromStore(STORE_MODULES.AvatarImage.name),
        params: model,
        beforeFetch: async () => {
            await Promise.all([fetchColors(), fetchLayers()]);

            const response = await avatarService.item.get(model.itemId);
            if (response.code === 200) {
                model.itemName = response.data.name;
                model.categoryId = response.data.categoryId;
            } else {
                toast.error(response.data);
                router.push(AVATAR_ROUTER.ITEM.INDEX());
            }
        },
        transformResponse: (list: any[]) =>
            list.map(item => ({
                ...item,
                color: colorMap.value.get(item.colorId).name,
                layer: layerMap.value.get(item.layerId).name
            }))
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this image?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.image.remove(model.itemId, id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchList();
                        }
                    }
                }
            });
        }
    };

    return { model, itemList, total, currentPage, fetchList, actions };
}
