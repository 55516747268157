
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppSelect, AppCard, AppFileUpload, AppImageHolder } from '@/components';
import { useBoosterItemEdit } from '@/modules/booster/model/edit';

export default defineComponent({
    name: 'BoosterItemEdit',
    components: { AppFormRow, AppInput, AppButton, AppSelect, AppCard, AppFileUpload, AppImageHolder },
    setup() {
        const { v$, model, fetch, actions, Options } = useBoosterItemEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, fetch, actions, Options };
    }
});
