import { arenaService } from '@/services/arena';
import { STORE_MODULES } from '@/store';
import { ArenaItemRankFilter } from '@/store/arena/item/rank';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import useVuelidate from '@vuelidate/core';
import { integer } from '@vuelidate/validators';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export function useArenaItemRank() {
    const route = useRoute();

    const arenaId = route.params.id;

    const filter = stateFromStore<ArenaItemRankFilter>({
        moduleName: STORE_MODULES.ArenaRank.name,
        getterFuncName: 'filter',
        setterFuncName: 'setFilter'
    });

    const validations = {
        accountId: { integer }
    };

    const v$ = useVuelidate(validations, filter);

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: arenaService.rank.list,
        pageFromStore: pageFromStore(STORE_MODULES.ArenaRank.name),
        params: computed(() => ({ arenaId, ...filter.value }))
    });

    return { v$, filter, itemList, total, currentPage, fetchList };
}
