import http from '@/utils/axios';

export const progressService = {
    item: {
        create(cloneId: any, name: any, fromTime: any, toTime: any) {
            return http.doPost('admin/progress', {
                cloneId: cloneId,
                name: name,
                fromTime: fromTime,
                toTime: toTime
            });
        },

        update(progressId: any, name: any) {
            return http.doPut('admin/progress/' + progressId, {
                name: name
            });
        },

        enable(progressId: any) {
            return http.doPut('admin/progress/' + progressId + '/enable');
        },

        disable(progressId: any) {
            return http.doPut('admin/progress/' + progressId + '/disable');
        },

        remove(progressId: any) {
            return http.doDelete('admin/progress/' + progressId);
        },

        list({ offset, limit }: any) {
            return http.doGet('admin/progress', { offset, limit });
        },

        get(progressId: any) {
            return http.doGet('admin/progress/' + progressId);
        }
    },

    level: {
        create(progressId: any, name: any, type: any, reward: any, quantity: any, itemId: any, image: any) {
            return http.doPost(
                'admin/progress/' + progressId + '/level',
                {
                    name: name,
                    type: type,
                    reward: reward,
                    quantity: quantity,
                    itemId: itemId
                },
                {
                    image: image
                }
            );
        },

        update(progressId: any, level: any, name: any, image: any) {
            return http.doPost(
                'admin/progress/' + progressId + '/level/' + level,
                {
                    name: name
                },
                {
                    image: image
                }
            );
        },

        remove(progressId: any, level: any) {
            return http.doDelete('admin/progress/' + progressId + '/level/' + level);
        },

        list({ progressId }: any) {
            return http.doGet('admin/progress/' + progressId + '/level');
        },

        get(progressId: any, level: any) {
            return http.doGet('admin/progress/' + progressId + '/level/' + level);
        },

        listLinkableItem(progressId: any) {
            return http.doGet('admin/progress/' + progressId + '/level/items/linkable');
        },

        getText(progressId: any, level: any, locale: any) {
            return http.doGet('admin/progress/' + progressId + '/level/' + level + '/text/' + locale);
        },

        updateText(progressId: any, level: any, locale: any, title: any, description: any) {
            return http.doPut('admin/progress/' + progressId + '/level/' + level + '/text/' + locale, {
                title: title,
                description: description
            });
        },

        removeText(progressId: any, level: any, locale: any) {
            return http.doDelete('admin/progress/' + progressId + '/level/' + level + '/text/' + locale);
        }
    }
};
