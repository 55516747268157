import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';
import { PromotionBoosterLinkableType, useMixins } from './mixins';

export function usePromotionBoosterList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { fetchLinkableItems, getItemLink, getItemName } = useMixins();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: promotionService.booster.list,
        pageFromStore: pageFromStore(STORE_MODULES.PromotionBooster.name),
        beforeFetch: async () => await fetchLinkableItems(),
        transformResponse: (list: any[]) =>
            list.map(promotionBooster => ({
                ...promotionBooster,
                linkedItemName: getItemName(PromotionBoosterLinkableType.PromotionItem, promotionBooster.promotionId),
                linkedItemLink: getItemLink(PromotionBoosterLinkableType.PromotionItem, promotionBooster.promotionId)
            }))
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.booster.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, fetchList, actions };
}
