import { PromotionBoosterLinkableType, useMixins } from '@/modules/promotion/booster/model/mixins';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { promotionService } from '@/services/promotion';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function usePromotionBoosterDetail() {
    const router = useRouter();
    const route = useRoute();

    const { fetchLinkableItems, getItemName, getItemLink } = useMixins();

    const model = reactive({
        id: route.params?.id,
        name: null,
        promotionId: null,
        boosters: [],

        get promotionName() {
            return getItemName(PromotionBoosterLinkableType.PromotionItem, this.promotionId);
        },
        get promotionLink() {
            return getItemLink(PromotionBoosterLinkableType.PromotionItem, this.promotionId);
        }
    });

    const fetch = async () => {
        await fetchLinkableItems();

        if (model.id) {
            const response = await promotionService.booster.get(model.id);
            if (response.code === 200) {
                const result = response.data;
                model.name = result.name;
                model.promotionId = result.promotionId;
                model.boosters = result.boosters.map((booster: any) => ({
                    ...booster,
                    name: getItemName(PromotionBoosterLinkableType.BoosterItem, booster.type),
                    link: getItemLink(PromotionBoosterLinkableType.BoosterItem, booster.type)
                }));
            } else {
                router.push(PROMOTION_ROUTER.BOOSTER.INDEX());
            }
        }
    };

    return { model, fetch };
}
