import { GAME_ROUTER } from '@/modules/game/router';
import { gameService } from '@/services/game';
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function useGameBetEdit() {
    const toast = useToast();
    const router = useRouter();

    const { model, updateValidator, fetch, remove } = useMixins();

    const v$ = useVuelidate(updateValidator, model);

    const gotoGameBetIndex = () => {
        router.push(GAME_ROUTER.BET.INDEX(model.variantId));
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await gameService.bet.update(
                model.id,
                model.name,
                model.levelTimeout,
                model.findingMatchTimeout,
                model.winLoseGroup,
                model.allowInvitation,
                model.lockType
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                gotoGameBetIndex();
            }
        },

        remove: () => remove(model.id, gotoGameBetIndex)
    };

    return { model, v$, fetch, actions };
}
