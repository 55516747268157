
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useQuestItemEdit } from '@/modules/quest/item/model/edit';
import { QuestItemGameType } from '@/modules/quest/item/model/mixins';

export default defineComponent({
    name: 'QuestItemEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, fetch, questTypeOptions, actions } = useQuestItemEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, questTypeOptions, QuestItemGameType };
    }
});
