
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppButton } from '@/components';
import { useVersionList } from '@/modules/version/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { formatDate, renderOption } from '@/utils/helpers';
import { VERSION_ROUTER } from '@/modules/version/router';
import { VersionOptions } from '@/modules/version/model/mixins';

export default defineComponent({
    name: 'VersionList',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppButton },
    setup() {
        const { storeId, itemList, total, currentPage, fetchList, actions } = useVersionList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: 'id' },
                { label: 'Store', display: (row: any) => renderOption(VersionOptions.Store, row.store) },
                { label: 'Version', field: 'version' },
                { label: 'Released date', display: (row: any) => formatDate(row.releasedDate) },
                { label: 'Dropped date', display: (row: any) => formatDate(row.droppedDate) },
                { label: 'Status', display: (row: any) => renderOption(VersionOptions.Status, row.status) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: VERSION_ROUTER.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-thumbs-up',
                        theme: 'warning',
                        tooltip: 'Undrop',
                        isShow: row.canUndrop,
                        onclick: () => actions.undrop(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-thumbs-down',
                        theme: 'warning',
                        tooltip: 'Drop',
                        isShow: row.canDrop,
                        onclick: () => actions.drop(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-check',
                        theme: 'success',
                        tooltip: 'Activate',
                        isShow: row.canActive,
                        onclick: () => actions.activate(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        isShow: row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, storeId, VersionOptions, VERSION_ROUTER };
    }
});
