import http from '@/utils/axios';

export const picwordService = {
    setting: {
        get() {
            return http.doGet('admin/picword/setting');
        },

        update(
            totalMap: number,
            maxLevel: number,
            extrawordCapacity: number,
            heartToPet: number,
            interactiveSeconds: number,
            interactiveCost: number,
            extrawordGoalToCredit: string
        ) {
            return http.doPut('admin/picword/setting', {
                totalMap,
                maxLevel,
                extrawordCapacity,
                heartToPet,
                interactiveSeconds,
                interactiveCost,
                extrawordGoalToCredit
            });
        },

        remove() {
            return http.doDelete('admin/picword/setting');
        }
    },

    map: {
        list({ offset, limit }: any) {
            return http.doGet('admin/picword/map', { offset, limit });
        },

        get(id: any) {
            return http.doGet('admin/picword/map/' + id);
        },

        create(
            id: any,
            name: any,
            packageId: any,
            price: any,
            chestByStar: any,
            chestRewardCredit: any,
            chestRewardHeart: any,
            chestRewardBooster: any
        ) {
            return http.doPost('admin/picword/map', {
                id,
                name,
                packageId,
                price,
                chestByStar,
                chestRewardCredit,
                chestRewardHeart,
                chestRewardBooster
            });
        },

        update(
            id: any,
            name: any,
            chestByStar: any,
            chestRewardCredit: any,
            chestRewardHeart: any,
            chestRewardBooster: any
        ) {
            return http.doPut('admin/picword/map/' + id, {
                name,
                chestByStar,
                chestRewardCredit,
                chestRewardHeart,
                chestRewardBooster
            });
        },

        remove(id: any) {
            return http.doDelete('admin/picword/map/' + id);
        }
    },

    pet: {
        list({ offset, limit }: any) {
            return http.doGet('admin/picword/pet', { offset, limit });
        },

        get(id: any) {
            return http.doGet('admin/picword/pet/' + id);
        },

        create(
            id: any,
            interactiveSeconds: any,
            gachaWeights: any,
            totalLevel: any,
            experienceByLevel: any,
            creditReward: any,
            heartReward: any,
            boosterReward: any
        ) {
            return http.doPost('admin/picword/pet', {
                id,
                gachaWeights,
                totalLevel,
                experienceByLevel,
                creditReward,
                heartReward,
                boosterReward,
                interactiveSeconds
            });
        },

        update(
            id: any,
            interactiveSeconds: any,
            gachaWeights: any,
            totalLevel: any,
            experienceByLevel: any,
            creditReward: any,
            heartReward: any,
            boosterReward: any
        ) {
            return http.doPut('admin/picword/pet/' + id, {
                gachaWeights,
                totalLevel,
                experienceByLevel,
                creditReward,
                heartReward,
                boosterReward,
                interactiveSeconds
            });
        },

        remove(id: any) {
            return http.doDelete('admin/picword/pet/' + id);
        }
    }
};
