
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton } from '@/components';
import useAppRoleAdd from '@/modules/role/app/model/add';

export default defineComponent({
    name: 'AppRoleAdd',
    components: { AppFormRow, AppInput, AppSelect, AppButton, AppCard },
    setup() {
        const { model, v$, roleOption, addAppRole } = useAppRoleAdd();

        return { v$, model, roleOption, addAppRole };
    }
});
