
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { usePicwordPetAdd } from '@/modules/picword/pet/model/add';

export default defineComponent({
    name: 'PicwordPetAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, rewardV$, actions, SelectableBooster } = usePicwordPetAdd();

        return { v$, rewardV$, model, actions, SelectableBooster };
    }
});
