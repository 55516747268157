import { RFM_ROUTER } from '@/modules/rfm/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { rfmService } from '@/services/rfm';
import { STORE_MODULES } from '@/store';
import { stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useRfmGroupList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const { itemList, total, fetchList: fetchRfmGroupAll } = useFetchList({ request: rfmService.group.list });

    const rfmGroupOptions = computed(() => itemList.value.map(item => ({ value: item.id, label: item.name })));
    const rfmGroupId = stateFromStore<number>({
        moduleName: STORE_MODULES.Rfm.name,
        getterFuncName: 'groupId',
        setterFuncName: 'changeGroupId'
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await rfmService.group.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchRfmGroupAll();
                        }
                    }
                }
            });
        }
    };

    const routes = {
        create: () => router.push(RFM_ROUTER.GROUP.ADD()),
        edit: (id: any) => router.push(RFM_ROUTER.GROUP.EDIT(id)),
        recency: (id: any) => {
            rfmGroupId.value = id;
            router.push(RFM_ROUTER.RECENCY.INDEX());
        },
        frequency: (id: any) => {
            rfmGroupId.value = id;
            router.push(RFM_ROUTER.FREQUENCY.INDEX());
        },
        monetary: (id: any) => {
            rfmGroupId.value = id;
            router.push(RFM_ROUTER.MONETARY.INDEX());
        },
        persona: (id: any) => {
            rfmGroupId.value = id;
            router.push(RFM_ROUTER.PERSONA.INDEX());
        },
        segmentation: (id: any) => {
            rfmGroupId.value = id;
            router.push(RFM_ROUTER.SEGMENTATION());
        }
    };

    return { itemList, total, fetchRfmGroupAll, rfmGroupOptions, rfmGroupId, actions, routes };
}
