import { useVDialog } from '@/plugins/dialog/v-dialog';
import { questService } from '@/services/quest';
import { useFetchList } from '@/utils/useFetchList';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';

export function useQuestTypeList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList({ request: questService.type.list });

    const questTypeOptions = computed(() => itemList.value.map((type: any) => ({ label: type.name, value: type.id })));
    const questTypeOptionWithAll = computed(() => [{ label: 'All', value: '' }, ...questTypeOptions.value]);

    const actions = {
        disable: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this type?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.type.disable(typeId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        disableNext: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this type in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.type.disableNext(typeId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enableNext: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this type in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.type.enableNext(typeId);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.type.remove(typeId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, questTypeOptions, questTypeOptionWithAll, actions };
}
