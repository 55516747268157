import http from '@/utils/axios';

export const boosterService = {
    list: ({ offset, limit }: any) => {
        return http.doGet('/admin/booster/item', { offset, limit });
    },

    get: (type: any) => {
        return http.doGet('/admin/booster/item/' + type);
    },

    create: (type: any, name: any, costs: any, thumbnail: any) => {
        return http.doPost('/admin/booster/item', { type, name, costs }, { thumbnail });
    },

    update: (type: any, name: any, costs: any, thumbnail: any) => {
        return http.doPost('/admin/booster/item/' + type, { name, costs }, { thumbnail });
    },

    remove: (type: any) => {
        return http.doDelete('/admin/booster/item/' + type);
    }
};
