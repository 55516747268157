import http from '@/utils/axios';

export const surpriseService = {
    setting: {
        get() {
            return http.doGet('/admin/surprise/setting');
        },

        update(
            autoSendPercent: any,
            autoSendSurpriseItems: any,
            surpriseCategoryForBet: any,
            botAutoSendPercent: any,
            botAutoReceivePercent: any
        ) {
            return http.doPut('/admin/surprise/setting', {
                autoSendPercent: autoSendPercent,
                autoSendSurpriseItems: autoSendSurpriseItems,
                surpriseCategoryForBet: surpriseCategoryForBet,
                botAutoSendPercent: botAutoSendPercent,
                botAutoReceivePercent: botAutoReceivePercent
            });
        },

        remove() {
            return http.doDelete('/admin/surprise/setting');
        }
    },
    category: {
        list({ offset, limit }: any) {
            return http.doGet('/admin/surprise/category', { offset, limit });
        },

        get(id: any) {
            return http.doGet('/admin/surprise/category/' + id);
        },

        create(name: any, isPublished: any) {
            return http.doPost('/admin/surprise/category', {
                name: name,
                isPublished: isPublished
            });
        },

        update(id: any, name: any, isPublished: any) {
            return http.doPut('/admin/surprise/category/' + id, {
                name: name,
                isPublished: isPublished
            });
        },

        remove(id: any) {
            return http.doDelete('/admin/surprise/category/' + id);
        }
    },

    item: {
        create(name: any, categoryId: any, price: any, isPublished: any, image: any) {
            return http.doPost(
                '/admin/surprise/item',
                {
                    name: name,
                    categoryId: categoryId,
                    price: price,
                    isPublished: isPublished
                },
                {
                    image: image
                }
            );
        },

        update(id: any, name: any, price: any, isPublished: any, image: any) {
            return http.doPost(
                '/admin/surprise/item/' + id,
                {
                    name: name,
                    price: price,
                    isPublished: isPublished
                },
                {
                    image: image
                }
            );
        },

        list({ offset, limit, categoryId }: any) {
            return http.doGet('/admin/surprise/item', { offset, limit, categoryId });
        },

        get(id: any) {
            return http.doGet('/admin/surprise/item/' + id);
        },

        remove(id: any) {
            return http.doDelete('/admin/surprise/item/' + id);
        }
    }
};
