import { useRfmPersonaList } from '@/modules/rfm/persona/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';

export function usePromotionPrototypeList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { fetchPersonaAll, personaMap } = useRfmPersonaList();

    const {
        fetchList: fetchItemsInPage,
        itemList: itemsInPage,
        total,
        currentPage
    } = useFetchList({
        request: promotionService.prototype.list,
        pageFromStore: pageFromStore(STORE_MODULES.PromotionPrototype.name),
        beforeFetch: async () => await fetchPersonaAll(),
        transformResponse: (list: any[]) =>
            list.map((item: any) => {
                item.personas.forEach((per: any) => {
                    per.rfmPersonaName = personaMap.value.get(per.rfmPersonaId)?.name;
                });
                return item;
            })
    });

    const prototypeMap = computed(() => new Map(prototypeAll.value.map((item: any) => [item.id, item])));
    const { fetchList: fetchPrototypeAll, itemList: prototypeAll } = useFetchList({
        request: promotionService.prototype.listAll,
        beforeFetch: async () => await fetchPersonaAll(),
        transformResponse: (list: any[]) =>
            list.map((item: any) => {
                item.personas.forEach((per: any) => {
                    per.rfmPersonaName = personaMap.value.get(per.rfmPersonaId)?.name;
                });
                return item;
            })
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.prototype.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchItemsInPage();
                        }
                    }
                }
            });
        }
    };

    return {
        itemsInPage,
        fetchItemsInPage,
        total,
        currentPage,
        actions,
        fetchPrototypeAll,
        prototypeAll,
        prototypeMap
    };
}
