import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { promotionService } from '@/services/promotion';
import { timeUnits } from '@/utils/helpers';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function usePromotionPlanAdd() {
    const router = useRouter();
    const toast = useToast();

    const { fetchLinkableItems, linkableItemOption, selectedItemType } = useMixins();

    const beforeFetch = async () => {
        await fetchLinkableItems();
    };

    const model = reactive({
        name: null,
        timeUnit: 0,
        timeDuration: 0,
        publishTime: new Date(),
        unpublishTime: null,
        itemId: null,
        image: null,
        imageFile: null,
        get type() {
            return selectedItemType.value;
        },
        set type(value) {
            this.itemId = null;
            selectedItemType.value = value;
        }
    });

    const validations = {
        name: { required },
        itemId: { required },
        type: { required },
        timeUnit: { required },
        timeDuration: { required, numeric, minValue: minValue(1) },
        publishTime: { required, validateDate: validateDateAfter() }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await promotionService.plan.create(
                model.name,
                model.itemId,
                model.type,
                model.timeUnit,
                model.timeDuration,
                model.publishTime.getTime(),
                model.unpublishTime?.getTime() ?? null,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push(PROMOTION_ROUTER.PLAN.INDEX());
            }
        }
    };

    return { model, v$, beforeFetch, actions, timeUnits, linkableItemOption, selectedItemType };
}
