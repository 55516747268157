import { promotionService } from '@/services/promotion';
import { timeUnits } from '@/utils/helpers';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import { Routes, useMixins } from './mixins';

export function usePromotionPlanEdit() {
    const route = useRoute();
    const toast = useToast();

    const { fetchLinkableItems, linkableItemOption, selectedItemType } = useMixins();

    const beforeFetch = async () => {
        await fetchLinkableItems();
    };

    const model = reactive({
        id: route.params?.id,
        name: null,
        status: null,
        timeUnit: 0,
        timeDuration: 0,
        publishTime: null,
        unpublishTime: null,
        itemId: null,
        image: null,
        imageStatus: null,
        imageFile: null,

        get type() {
            return selectedItemType.value;
        },
        set type(value) {
            selectedItemType.value = value;
        }
    });

    const validations = {
        name: { required },
        unpublishTime: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const result = await promotionService.plan.get(model.id);
        if (result.code === 200) {
            result.data.publishTime = new Date(result.data.publishTime);
            result.data.unpublishTime = result.data.unpublishTime ? new Date(result.data.unpublishTime) : null;

            Object.assign(model, result.data);
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const unpublishTime = model.unpublishTime?.getTime() ?? null;
            const response = await promotionService.plan.update(model.id, model.name, unpublishTime, model.imageFile);
            if (response.code === 200) {
                toast.success('Successfully');
                Routes.Index();
            }
        }
    };

    return { model, v$, beforeFetch, fetch, actions, timeUnits, linkableItemOption, selectedItemType };
}
