
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useQuestItemAdd } from '@/modules/quest/item/model/add';
import { QuestItemGameType } from '@/modules/quest/item/model/mixins';

export default defineComponent({
    name: 'QuestItemAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, fetchQuestTypeOption, questTypeOptions, actions } = useQuestItemAdd();

        onMounted(async () => {
            await fetchQuestTypeOption();
        });

        return { v$, model, actions, questTypeOptions, QuestItemGameType };
    }
});
