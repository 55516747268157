
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSwitch, AppSelect, AppButton } from '@/components';
import { useGameVariantAdd } from '@/modules/game/bet/model/add';
import { GameBetOptions, GameBetType } from '@/modules/game/bet/model/mixins';

export default defineComponent({
    name: 'GameBetAdd',
    components: { AppFormRow, AppInput, AppCard, AppSwitch, AppSelect, AppButton },
    setup() {
        const { model, v$, fetch, actions } = useGameVariantAdd();

        onMounted(async () => {
            await fetch();
        });

        return { model, v$, GameBetOptions, GameBetType, actions };
    }
});
