
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useTicketEdit } from '@/modules/scratch/ticket/model/edit';
import { ScratchTicketOptions, ScratchTicketType } from '@/modules/scratch/ticket/model/mixins';

export default defineComponent({
    name: 'ScratchEdit',
    components: { AppFormRow, AppInput, AppSelect, AppDateTimePicker, AppButton, AppCard },
    setup() {
        const { model, v$, timeUnits, fetch, update } = useTicketEdit();

        onMounted(() => {
            fetch();
        });

        return { model, v$, ScratchTicketOptions, ScratchTicketType, timeUnits, update };
    }
});
