import http from '@/utils/axios';

export default {
    list({ offset, limite, store }: any) {
        return http.doGet('admin/version', { offset, limite, store });
    },

    create(store: any, version: any, assetLink: any, releasedDate: any) {
        return http.doPost('admin/version', {
            store: store,
            version: version,
            assetLink: assetLink,
            releasedDate: releasedDate
        });
    },

    update(id: any, assetLink: any, releasedDate: any) {
        return http.doPut('admin/version/' + id, {
            assetLink: assetLink,
            releasedDate: releasedDate
        });
    },

    get(id: string) {
        return http.doGet('admin/version/' + id);
    },

    remove(id: string) {
        return http.doDelete('admin/version/' + id);
    },

    activate(id: string) {
        return http.doPut('admin/version/' + id + '/activate');
    },

    drop(id: string) {
        return http.doPut('admin/version/' + id + '/drop');
    },

    undrop(id: string) {
        return http.doPut('admin/version/' + id + '/undrop');
    }
};
