
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload, AppImageHolder } from '@/components';
import { usePiggybankEdit } from '@/modules/piggybank/model/edit';
import { PIGGYBANK_ROUTER } from '@/modules/piggybank/router';
import { PiggyBankStatus } from '@/modules/piggybank/model/mixins';
import { renderOption } from '@/utils/helpers';

export default defineComponent({
    name: 'PiggyBankEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, fetch, fetchIapOptions, actions, iapOptions } = usePiggybankEdit();

        onMounted(async () => {
            await fetchIapOptions();
            await fetch();
        });

        return { v$, model, actions, iapOptions, renderOption, PiggyBankStatus, PIGGYBANK_ROUTER };
    }
});
