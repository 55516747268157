
import { defineComponent, markRaw, onMounted, reactive, toRef } from 'vue';
import { AppCard, AppTable, AppButton } from '@/components';
import { usePromotionBoosterDetail } from '@/modules/promotion/booster/model/detail';
import { IColumn } from '@/components/table/model';
import { RouterLink } from 'vue-router';
import { PromotionBoosterLinkableType } from '@/modules/promotion/booster/model/mixins';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { Options as BoosterOptions } from '@/modules/booster/model/mixins';
import { renderOption } from '@/utils/helpers';

export default defineComponent({
    name: 'PromotionBoosterDetail',
    components: { AppCard, AppTable, AppButton },
    setup() {
        const { model, fetch } = usePromotionBoosterDetail();

        const table = reactive({
            rows: toRef(model, 'boosters'),
            columns: [
                { label: 'Type', display: row => renderOption(BoosterOptions.ItemType, row.type) },
                {
                    label: 'Name',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.name,
                        props: { to: row.link }
                    })
                },
                { label: 'Quantity', field: 'quantity' }
            ] as Array<IColumn>,
            actions: []
        });

        onMounted(async () => {
            await fetch();
        });

        return { model, table, PROMOTION_ROUTER, PromotionBoosterLinkableType };
    }
});
