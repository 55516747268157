import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarCategoryAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        parentId: route.query?.parentId ?? null,
        parentName: null,
        name: '',
        position: 0,
        gender: 0,
        isPublished: false,
        isRequired: false,
        posX: 0,
        posY: 0,
        isBody: false,
        imageFile: null
    });

    const validations = {
        name: { required },
        gender: { required },
        position: { required, min: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        if (model.parentId) {
            const response = await avatarService.category.get(model.parentId);
            if (response.code === 200) {
                model.parentName = response.data.name;
            } else {
                toast.error(response.data);
                router.push(AVATAR_ROUTER.CATEGORY.INDEX());
            }
        }
    };

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await avatarService.category.create(
            model.parentId,
            model.name,
            model.position,
            model.gender,
            model.isPublished,
            model.isRequired,
            model.posX,
            model.posY,
            model.isBody,
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(AVATAR_ROUTER.CATEGORY.INDEX({ parentId: model.parentId }));
        }
    };

    return { model, v$, fetch, create };
}
