import { useGameVariantList } from '@/modules/game/variant/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { gameService } from '@/services/game';
import { validateFormat } from '@/utils/validatorHelpers';
import { integer, minValue, required, requiredIf } from '@vuelidate/validators';
import { reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useMixins() {
    const dialog = useVDialog();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const { variantOptions, fetchList: getVariantList } = useGameVariantList();

    const model = reactive({
        id: Number(route.params.id) || null,
        variantId: Number(route.query.variantId || null),
        name: '',
        type: GameBetType.Normal.value,
        cost: 0,
        maxRound: 0,
        maxPoint: 0,
        tablePotPercent: 0,
        tableCreditRatio: '',
        levelTimeout: 0,
        findingMatchTimeout: null,
        winLoseGroup: false,
        allowInvitation: false,
        lockType: GameBetLockType.Visible.value,
        canDelete: false,
        canEdit: false,

        get variantOptions() {
            return variantOptions.value;
        },
        get isShowFindingMatchTimeout() {
            return this.type == GameBetType.Normal.value;
        }
    });

    const updateValidator = {
        variantId: { required },
        name: { required },
        type: { required },
        cost: { required, integer, minValue: minValue(0) },
        maxRound: { required, integer, minValue: minValue(1) },
        maxPoint: { required, integer, minValue: minValue(1) },
        winLoseGroup: { required },
        allowInvitation: { required },
        levelTimeout: { required, integer, minValue: minValue(1) },
        findingMatchTimeout: {
            required: requiredIf(() => model.type == GameBetType.Normal.value),
            integer,
            minValue: minValue(1)
        },
        lockType: { required }
    };

    const creationValidator = {
        ...updateValidator,
        tablePotPercent: { required, integer, minValue: minValue(0) },
        tableCreditRatio: { format: validateFormat(/^\d+(;\d+)*$/) }
    };

    const fetch = async () => {
        if (model.variantOptions.length === 0) {
            await getVariantList();
        }

        if (model.id) {
            const response = await gameService.bet.get(model.id);
            if (response.code == 200) {
                const result = response.data;
                model.id = result.id;
                model.variantId = result.variantId;
                model.name = result.name;
                model.type = result.type;
                model.cost = result.cost;
                model.maxRound = result.maxRound;
                model.maxPoint = result.maxPoint;
                model.tablePotPercent = result.tablePotPercent;
                model.tableCreditRatio = result.tableCreditRatio;
                model.levelTimeout = result.levelTimeout;
                model.findingMatchTimeout = result.findingMatchTimeout;
                model.winLoseGroup = result.winLoseGroup;
                model.allowInvitation = result.allowInvitation;
                model.lockType = result.lockType;
                model.canDelete = result.canDelete;
                model.canEdit = result.canEdit;
            } else {
                toast.error(response.data);
                router.go(-1);
            }
        }
    };

    const remove = async (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
        dialog.confirm({
            message: 'Are you sure you want to remove this item?',
            onConfirm: async (result: boolean) => {
                if (result) {
                    const response = await gameService.bet.remove(id);
                    if (response.code === 200) {
                        toast.success('Remove successfully');
                        await onSuccess();
                    } else {
                        toast.error(response.data);
                    }
                }
            }
        });
    };

    const changeBetType = (type: number) => {
        if (type == GameBetType.Duplicate.value) {
            model.lockType = GameBetLockType.Hidden.value;
        }
        if (type != GameBetType.Normal.value) {
            model.findingMatchTimeout = null;
        }
    };

    // monitor change of bet type to change lock type
    watch(() => model.type, changeBetType);

    return { model, creationValidator, updateValidator, fetch, remove };
}

export const GameBetType = {
    Normal: { value: 1, label: 'Normal', background: 'info' },
    Premium: { value: 2, label: 'Premium', background: 'warning' },
    Duplicate: { value: 3, label: 'Duplicate', background: 'success' },
    Scenario: { value: 4, label: 'Scenario', background: 'primary' }
};

export const GameBetLockType = {
    Visible: { value: 0, label: 'Visible', background: 'info' },
    Hidden: { value: 1, label: 'Hidden', background: 'info' }
};

export const GameBetOptions = {
    GameBetType: Object.entries(GameBetType).map(([, data]) => data),
    GameBetLockType: Object.entries(GameBetLockType).map(([, data]) => data)
};
