import { useVDialog } from '@/plugins/dialog/v-dialog';
import { useVModal } from '@/plugins/modal/v-modal';
import { avatarService } from '@/services/avatar';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import useVuelidate from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarItemList() {
    const vModal = useVModal();
    const dialog = useVDialog();
    const route = useRoute();
    const toast = useToast();

    const model = reactive({
        query: {
            categoryId: route.query?.categoryId ?? null,
            name: (route.query?.name ?? null) as string,
            isNew: (route.query?.isNew ?? '') as string
        },
        bundle: {
            selectedItemId: null,
            linkableBundle: []
        }
    });

    const validations = {
        query: {
            name: { min: minLength(3) }
        }
    };

    const v$ = useVuelidate(validations, model);

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: avatarService.item.list,
        params: model.query,
        pageFromStore: pageFromStore(STORE_MODULES.AvatarItem.name),
        preCheck: () => {
            v$.value.$touch();
            return !v$.value.$invalid;
        }
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        linkBundle: async () => {
            if (model.bundle.selectedItemId && model.bundle.linkableBundle.length) {
                const bundleIds = model.bundle.linkableBundle.filter(item => item.hasLinked).map(item => item.id);
                const response = await avatarService.bundle.updateForItem(
                    model.bundle.selectedItemId,
                    bundleIds.join(',')
                );

                if (response.code === 200) {
                    toast.success('Update successfully');
                } else {
                    toast.error(response.data);
                }
            }

            actions.closeLinkBundle();
        },
        openLinkBundle: async (id: any) => {
            const response = await avatarService.bundle.getListForItem(id);
            if (response.code === 200) {
                model.bundle.selectedItemId = id;
                model.bundle.linkableBundle = response.data;
                vModal.open({ name: 'linkbundle' });
            } else {
                toast.error(response.data);
            }
        },
        closeLinkBundle: () => {
            model.bundle = { selectedItemId: null, linkableBundle: [] };
            vModal.close({ name: 'linkbundle' });
        }
    };

    return { model, v$, itemList, total, currentPage, fetchList, actions };
}
