export const QuestItemGameType = [
    { label: 'Win', value: 0, background: 'info' },
    { label: 'Complete', value: 1, background: 'success' },
    { label: 'ArenaWin', value: 2, background: 'info' },
    { label: 'ArenaComplete', value: 3, background: 'success' },
    { label: 'SlotWin', value: 4, background: 'info' },
    { label: 'SlotSpin', value: 5, background: 'success' },
    { label: 'ScratchCardComplete', value: 6, background: 'success' },
    { label: 'ScratchCardWin', value: 7, background: 'info' },
    { label: 'DuplicateGameComplete', value: 8, background: 'success' }
];
