import { useRfmGroupList } from '@/modules/rfm/group/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { rfmService } from '@/services/rfm';
import { color } from '@/utils/helpers';
import { useFetchList } from '@/utils/useFetchList';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';

export function useRfmPersonaList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { rfmGroupId, rfmGroupOptions, fetchRfmGroupAll } = useRfmGroupList();

    const { itemList, total, fetchList } = useFetchList({
        request: rfmService.persona.list,
        params: computed(() => ({ groupId: rfmGroupId.value })),
        beforeFetch: async () => await fetchRfmGroupAll(),
        transformResponse: (list: any[]) =>
            list.map((item: any) => ({
                ...item,
                groupName: rfmGroupOptions.value.find(g => g.value == item.groupId)?.label,
                nameWithColor: `<font color="${color(item.id)}"><i class="fas fa-bookmark"></i></font> ${item.name}`
            }))
    });

    const { itemList: personaAll, fetchList: fetchPersonaAll } = useFetchList({ request: rfmService.persona.list });
    const personaMap = computed(() => new Map(personaAll.value.map((item: any) => [item.id, item])));
    const personaOptions = computed(() => {
        return personaAll.value.map(item => ({ value: item.id, label: item.name, groupId: item.groupId }));
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await rfmService.persona.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return {
        itemList,
        total,
        fetchList,
        rfmGroupId,
        rfmGroupOptions,
        personaOptions,
        fetchPersonaAll,
        personaAll,
        personaMap,
        actions
    };
}
