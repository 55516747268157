
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useArenaItemMedal } from '@/modules/arena/item/model/medal';

export default defineComponent({
    name: 'ArenaItemMedal',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { model, itemList, total, fetchList, actions } = useArenaItemMedal();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Rank', field: 'rank' },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-link',
                        theme: 'info',
                        tooltip: 'Link',
                        isShow: !row.linkedArena,
                        onclick: () => actions.link(row.id)
                    })
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-unlink',
                        theme: 'warning',
                        tooltip: 'Unlink',
                        isShow: row.linkedArena,
                        onclick: () => actions.unlink(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, model, fetchList, actions };
    }
});
