
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useSysItemList } from '@/modules/item/system/model/list';
import { ITEM_ROUTER } from '@/modules/item/router';
import { renderOption } from '@/utils/helpers';
import { ItemSystemFeatures } from '@/modules/item/system/model/mixins';

export default defineComponent({
    name: 'SysItem',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton },
    setup() {
        const { selectedFeature, filter, v$, itemList, total, currentPage, fetchList, actions, appOptionWithAll } =
            useSysItemList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                { label: 'Ruby', field: 'ruby' },
                { label: 'Feature', display: (row: any) => renderOption(ItemSystemFeatures, row.feature) },
                { label: 'Quantity', field: 'quantity' },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'App (current cycle)', field: 'curAppNames' },
                { label: 'App (next cycle)', field: 'nextAppNames' },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: ITEM_ROUTER.SYSTEM.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        href: ITEM_ROUTER.SYSTEM.TRANSLATE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-link',
                        theme: 'info',
                        tooltip: 'Link',
                        href: ITEM_ROUTER.SYSTEM.LINK(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList(currentPage.value);
        });

        return { selectedFeature, filter, v$, table, fetchList, actions, appOptionWithAll, ItemSystemFeatures };
    }
});
