
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton, AppImageHolder } from '@/components';
import { usePromotionPlanDetail } from '@/modules/promotion/plan/model/detail';
import { formatDate, renderTimeUnit, renderOption } from '@/utils/helpers';
import { Options, Routes } from '@/modules/promotion/plan/model/mixins';

export default defineComponent({
    name: 'PromotionPlanDetail',
    components: { AppCard, AppButton, AppImageHolder },
    setup() {
        const { model, beforeFetch, fetch, actions } = usePromotionPlanDetail();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { model, actions, formatDate, Routes, Options, renderOption, renderTimeUnit };
    }
});
