
import { defineComponent, onMounted } from 'vue';
import { AppDateTimePicker, AppFileUpload, AppImageHolder } from '@/components';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { usePiggybankBoost } from '@/modules/piggybank/model/boost';
import { renderOption } from '@/utils/helpers';
import { PiggyBankBoostStatus } from '@/modules/piggybank/model/mixins';

export default defineComponent({
    name: 'PiggybankBoost',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppDateTimePicker, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, fetch, actions } = usePiggybankBoost();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, renderOption, PiggyBankBoostStatus };
    }
});
