import { ITEM_ROUTER } from '@/modules/item/router';
import { itemService } from '@/services/item';
import { timeUnits } from '@/utils/helpers';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export default function useItemSettingAdd() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        timeUnit: 0,
        timeDuration: 0,
        startTime: new Date()
    });

    const validations = {
        timeDuration: { required, numeric, minValue: minValue(1), maxValue: maxValue(3200) },
        startTime: { required, validateDate: validateDateAfter() }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async (id: any) => {
        const response = await itemService.setting.get(id);
        if (response.code === 200) {
            const result = response.data;
            model.id = result.id;
            model.timeUnit = result.timeUnit;
            model.timeDuration = result.timeDuration;
            model.startTime = new Date(result.startTime);
        } else {
            toast.error(response.data);
            router.push(ITEM_ROUTER.APP.SETTING.INDEX());
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await itemService.setting.update(
            model.id,
            model.timeUnit,
            model.timeDuration,
            model.startTime.getTime()
        );

        if (response.code === 200) {
            toast.success('Successfully');
            router.push(ITEM_ROUTER.APP.SETTING.INDEX());
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, timeUnits, update, fetch };
}
