import { pageSize as globalPageSize } from '@/utils/helpers';
import { Ref, isRef, reactive, ref, toRaw } from 'vue';
import { useToast } from 'vue-toastification';

interface IFetchListParams {
    request: (params: any) => Promise<{ code: number; data: any }>;
    params?: Record<string, any> | Ref<Record<string, any>>;
    preCheck?: () => boolean | Promise<boolean>; // to validate data/form before fetching
    beforeFetch?: () => Promise<void>;
    onSuccess?: () => void | Promise<void>;
    transformResponse?: (list: any[]) => any[] | Promise<any[]>;
    pageFromStore?: Ref<number>;
    pageSize?: number;
}

export function useFetchList({
    request,
    params = {},
    beforeFetch,
    preCheck,
    onSuccess,
    transformResponse,
    pageFromStore,
    pageSize = globalPageSize
}: IFetchListParams) {
    const toast = useToast();

    let hasBeforeFetchLoaded = beforeFetch ? false : true;
    const itemList = ref<any[]>([]);
    const total = ref(0);

    // Pagination
    const hasPaging = pageFromStore ? true : false;
    const paging = reactive({
        limit: pageSize,
        get offset() {
            return (pageFromStore.value - 1) * this.limit;
        }
    });

    const normalizeParams = (): Record<string, any> => {
        if (isRef(params)) {
            return toRaw(params.value);
        }
        return toRaw(params);
    };

    const fetch = async () => {
        if (!hasBeforeFetchLoaded) {
            await beforeFetch();
            hasBeforeFetchLoaded = true;
        }

        if (preCheck && !(await preCheck())) {
            return;
        }

        const normalizedParams = normalizeParams();
        const finalParams = hasPaging ? { ...paging, ...normalizedParams } : { ...normalizedParams };

        const response = await request(finalParams);
        if (response.code === 200) {
            let list = [];
            if (hasPaging && response.data?.list) {
                list = response.data.list || [];
                total.value = response.data.total || 0;
            } else {
                list = response.data || [];
                total.value = list.length || 0;
            }

            if (transformResponse) {
                list = await transformResponse(list);
            }

            itemList.value = list;

            if (onSuccess) {
                await onSuccess();
            }
        } else {
            toast.error(response.data);
            itemList.value = [];
            total.value = 0;
        }
    };

    const fetchList = async (page?: number) => {
        if (page && hasPaging) {
            pageFromStore.value = page;
        }

        await fetch();

        // case: offset is out of the total item
        if (total.value > 0 && itemList.value.length === 0 && pageFromStore.value !== 1) {
            // retry with page = 1
            pageFromStore.value = 1;
            await fetch();
        }
    };

    return {
        fetchList,
        itemList,
        total,
        currentPage: pageFromStore
    };
}
