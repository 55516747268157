
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useAvatarBundleAdd } from '@/modules/avatar/bundle/model/add';
import { AvatarBundleOptions } from '@/modules/avatar/bundle/model/mixins';

export default defineComponent({
    name: 'AvatarBundleAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, actions } = useAvatarBundleAdd();

        return { v$, model, actions, AvatarBundleOptions };
    }
});
