import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-140f7b80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "disabled"]
const _hoisted_4 = ["selected"]
const _hoisted_5 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_error = _resolveComponent("app-error")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.ID,
            class: "select-label"
          }, _toDisplayString(_ctx.label), 9, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("select", {
        class: _normalizeClass(["select2", { 'form-control': _ctx.type === 'default', 'custom-select': _ctx.type === 'custom' }]),
        id: _ctx.ID,
        name: _ctx.ID,
        disabled: _ctx.disabled,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValueChange($event)))
      }, [
        (_ctx.isNoneSelected)
          ? (_openBlock(), _createElementBlock("option", {
              key: 0,
              selected: _ctx.isNoneSelected,
              disabled: ""
            }, "-- Select an option --", 8, _hoisted_4))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            key: option.value,
            value: option.value,
            selected: _ctx.modelValue === option.value
          }, _toDisplayString(option.label), 9, _hoisted_5))
        }), 128))
      ], 42, _hoisted_3)
    ]),
    _createVNode(_component_app_error, { validations: _ctx.validations }, null, 8, ["validations"])
  ]))
}