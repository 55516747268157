import http from '@/utils/axios';

export const gameService = {
    report: {
        get() {
            return http.doGet('/admin/game/report');
        },

        update(warningThreshold: any, penaltyThreshold: any, penaltyGame: any) {
            return http.doPut('/admin/game/report', {
                warningThreshold: warningThreshold,
                penaltyThreshold: penaltyThreshold,
                penaltyGame: penaltyGame
            });
        },

        remove() {
            return http.doDelete('/admin/game/report');
        }
    },

    parameter: {
        get() {
            return http.doGet('/admin/game/parameter');
        },

        update(
            phaseNames: any,
            phaseTimes: any,
            rankPenaltyPercent: any,
            botPayPercent: any,
            botPlayableTime: any,
            betRange: any,
            rankBoostCharge: any,
            rankBoostRejectLimit: any,
            lobbyLimitTable: any
        ) {
            return http.doPut('/admin/game/parameter', {
                phaseNames: phaseNames,
                phaseTimes: phaseTimes,
                rankPenaltyPercent: rankPenaltyPercent,
                botPayPercent: botPayPercent,
                botPlayableTime: botPlayableTime,
                betRange: betRange,
                rankBoostCharge: rankBoostCharge,
                rankBoostRejectLimit: rankBoostRejectLimit,
                lobbyLimitTable: lobbyLimitTable
            });
        },

        remove() {
            return http.doDelete('/admin/game/parameter');
        }
    },

    variant: {
        list() {
            return http.doGet('/admin/game/variant');
        },

        get(id: string) {
            return http.doGet('/admin/game/variant/' + id);
        },

        create(id: any, name: any, minPlayer: any, maxPlayer: any, hasTeam: any) {
            return http.doPost('/admin/game/variant', {
                id: id,
                name: name,
                minPlayer: minPlayer,
                maxPlayer: maxPlayer,
                hasTeam: hasTeam
            });
        },

        enable(id: string) {
            return http.doPut('/admin/game/variant/' + id + '/enable');
        },

        disable(id: string) {
            return http.doPut('/admin/game/variant/' + id + '/disable');
        },

        remove(id: string) {
            return http.doDelete('/admin/game/variant/' + id);
        },

        getText(id: string, locale: string) {
            return http.doGet('/admin/game/variant/' + id + '/text/' + locale);
        },

        updateText(id: string, locale: string, title: any) {
            return http.doPut('/admin/game/variant/' + id + '/text/' + locale, {
                title: title
            });
        },

        removeText(id: string, locale: string) {
            return http.doDelete('/admin/game/variant/' + id + '/text/' + locale);
        }
    },

    bet: {
        list({ variantId }: any = {}) {
            return http.doGet('/admin/game/bet', { variantId });
        },

        get(id: any) {
            return http.doGet('/admin/game/bet/' + id);
        },

        create(
            variantId: any,
            name: any,
            type: any,
            cost: any,
            maxRound: any,
            maxPoint: any,
            tablePotPercent: any,
            tableCreditRatio: any,
            levelTimeout: any,
            findingMatchTimeout: any,
            winLoseGroup: any,
            allowInvitation: any,
            lockType: any
        ) {
            return http.doPost('/admin/game/bet', {
                variantId,
                name,
                type,
                cost,
                maxRound,
                maxPoint,
                tablePotPercent,
                tableCreditRatio,
                levelTimeout,
                findingMatchTimeout,
                winLoseGroup,
                allowInvitation,
                lockType
            });
        },

        update(
            id: any,
            name: any,
            levelTimeout: any,
            findingMatchTimeout: any,
            winLoseGroup: any,
            allowInvitation: any,
            lockType: any
        ) {
            return http.doPut('/admin/game/bet/' + id, {
                name,
                levelTimeout,
                findingMatchTimeout,
                winLoseGroup,
                allowInvitation,
                lockType
            });
        },

        remove(id: any) {
            return http.doDelete('/admin/game/bet/' + id);
        }
    }
};
