
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton, AppImageHolder } from '@/components';
import { useDuplicateItemDetail } from '@/modules/duplicate/item/model/detail';
import { formatDate, renderTimeUnit } from '@/utils/helpers';
import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { GAME_ROUTER } from '@/modules/game/router';

export default defineComponent({
    name: 'DuplicateItemDetail',
    components: { AppCard, AppButton, AppImageHolder },
    setup() {
        const { model, fetch, actions } = useDuplicateItemDetail();

        onMounted(async () => {
            await fetch();
        });

        return { model, formatDate, actions, renderTimeUnit, renderStatus, DUPLICATE_ROUTER, GAME_ROUTER };
    }
});

const statusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'Published', value: 2, background: 'info' },
    { label: 'Unpublished', value: 3, background: 'warning' },
    { label: 'Disabled', value: 4, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
