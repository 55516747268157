import http from '@/utils/axios';

export const leagueService = {
    item: {
        list() {
            return http.doGet('admin/league/item');
        },

        create(
            timeUnit: any,
            timeDuration: any,
            game: any,
            maxPlayer: any,
            basePoint: any,
            defaultPoint: any,
            rankRatio: any,
            startTime: any
        ) {
            return http.doPost('admin/league/item', {
                timeUnit: timeUnit,
                timeDuration: timeDuration,
                game: game,
                maxPlayer: maxPlayer,
                basePoint: basePoint,
                defaultPoint: defaultPoint,
                rankRatio: rankRatio,
                startTime: startTime
            });
        },

        update(
            itemId: any,
            timeUnit: any,
            timeDuration: any,
            game: any,
            maxPlayer: any,
            basePoint: any,
            defaultPoint: any,
            rankRatio: any,
            startTime: any
        ) {
            return http.doPost('admin/league/item/' + itemId, {
                timeUnit: timeUnit,
                timeDuration: timeDuration,
                game: game,
                maxPlayer: maxPlayer,
                basePoint: basePoint,
                defaultPoint: defaultPoint,
                rankRatio: rankRatio,
                startTime: startTime
            });
        },

        get(itemId: any) {
            return http.doGet('admin/league/item/' + itemId);
        },

        remove(itemId: any) {
            return http.doDelete('admin/league/item/' + itemId);
        }
    },

    tier: {
        list({ itemId }: any) {
            return http.doGet('admin/league/tier/item/' + itemId);
        },

        create(itemId: any, tier: any, name: any, point: any, image: any) {
            return http.doPost(
                'admin/league/tier/item/' + itemId,
                {
                    tier: tier,
                    name: name,
                    point: point
                },
                {
                    image: image
                }
            );
        },

        update(itemId: any, tier: any, name: any, point: any, image: any) {
            return http.doPost(
                'admin/league/tier/item/' + itemId + '/tier/' + tier,
                {
                    name: name,
                    point: point
                },
                {
                    image: image
                }
            );
        },

        get(itemId: any, tier: any) {
            return http.doGet('admin/league/tier/item/' + itemId + '/tier/' + tier);
        },

        remove(itemId: any, tier: any) {
            return http.doDelete('admin/league/tier/item/' + itemId + '/tier/' + tier);
        }
    },

    reward: {
        list({ itemId, tier }: any) {
            return http.doGet('admin/league/reward/item/' + itemId + '/tier/' + tier);
        },

        create(itemId: any, tier: any, position: any, tierReward: any, starReward: any, rubyReward: any) {
            return http.doPost('admin/league/reward/item/' + itemId + '/tier/' + tier, {
                position: position,
                tierReward: tierReward,
                starReward: starReward,
                rubyReward: rubyReward
            });
        },

        update(itemId: any, tier: any, position: any, tierReward: any, starReward: any, rubyReward: any) {
            return http.doPut('admin/league/reward/item/' + itemId + '/tier/' + tier + '/position/' + position, {
                tierReward: tierReward,
                starReward: starReward,
                rubyReward: rubyReward
            });
        },

        get(itemId: any, tier: any, position: any) {
            return http.doGet('admin/league/reward/item/' + itemId + '/tier/' + tier + '/position/' + position);
        },

        remove(itemId: any, tier: any, position: any) {
            return http.doDelete('admin/league/reward/item/' + itemId + '/tier/' + tier + '/position/' + position);
        }
    }
};
