import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { promotionService } from '@/services/promotion';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useRouter } from 'vue-router';
import { Routes, getPromotionPlanLink } from './mixins';

export function usePromotionCycleList() {
    const router = useRouter();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: promotionService.cycle.list,
        pageFromStore: pageFromStore(STORE_MODULES.PromotionCycle.name),
        transformResponse: async (list: any[]) => {
            const itemIds = new Set(list.map(cycle => cycle.promotionPlanId));
            const response = await promotionService.plan.listInIds(Array.from(itemIds).join(','));
            if (response.code !== 200) {
                router.push(PROMOTION_ROUTER.ITEM.INDEX());
                return [];
            }

            const promotionPlanNames = new Map<number, string>(response.data.map((item: any) => [item.id, item.name]));
            return list.map(cycle => {
                return {
                    ...cycle,
                    promotionPlanName: promotionPlanNames.get(cycle.promotionPlanId),
                    promotionPlanLink: getPromotionPlanLink(cycle.promotionPlanId)
                };
            });
        }
    });

    return { itemList, total, currentPage, fetchList, Routes };
}
