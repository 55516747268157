import { ARENA_ROUTER } from '@/modules/arena/router';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { QUEST_ROUTER } from '@/modules/quest/router';
import { SCRATCH_ROUTER } from '@/modules/scratch/router';
import router from '@/router';
import { starterPackService } from '@/services/starter';
import { computed, ref } from 'vue';

export const StarterPackRewardType = {
    AvatarBundle: { value: 1, label: 'AvatarBundle' },
    AvatarBackground: { value: 2, label: 'AvatarBackground' },
    QuestBoost: { value: 3, label: 'QuestBoost' },
    GoldenScratchTicket: { value: 4, label: 'GoldenScratchTicket' },
    SilverScratchTicket: { value: 5, label: 'SilverScratchTicket' },
    WeekdayArenaTicket: { value: 6, label: 'WeekdayArenaTicket' },
    WeekendArenaTicket: { value: 7, label: 'WeekendArenaTicket' },
    NoAds: { value: 8, label: 'NoAds' }
};

export const StarterPackRewardOptions = {
    StarterPackRewardType: Object.entries(StarterPackRewardType).map(([, data]) => data)
};

export class StarterPackReward {
    constructor(public type: number = 0, public itemId: number = 0, public itemName: string = '') {}

    getLink(): string {
        if (!this.itemId) return '';
        switch (this.type) {
            case StarterPackRewardType.AvatarBundle.value:
                return router.resolve(AVATAR_ROUTER.BUNDLE.EDIT(this.itemId))?.href;
            case StarterPackRewardType.AvatarBackground.value:
                return router.resolve(AVATAR_ROUTER.BACKGROUND.EDIT(this.itemId))?.href;
            case StarterPackRewardType.QuestBoost.value:
                return router.resolve(QUEST_ROUTER.BOOST.DETAIL(this.itemId))?.href;
            case StarterPackRewardType.GoldenScratchTicket.value:
            case StarterPackRewardType.SilverScratchTicket.value:
                return router.resolve(SCRATCH_ROUTER.TICKET.EDIT(this.itemId))?.href;
            case StarterPackRewardType.WeekdayArenaTicket.value:
            case StarterPackRewardType.WeekendArenaTicket.value:
                return router.resolve(ARENA_ROUTER.TICKET.EDIT(this.itemId))?.href;
            default:
                return '';
        }
    }
}

export function useStarterPackRewardMixins() {
    const selectedRewardType = ref<number>(StarterPackRewardType.AvatarBundle.value);
    const linkableItems = ref([] as Array<StarterPackReward>);
    const linkableItemOption = computed(() => {
        return linkableItems.value
            .filter(item => item.type === selectedRewardType.value)
            .map(item => ({ value: item.itemId, label: item.itemName }));
    });
    const fetchAllLinkableItems = async () => {
        const response = await starterPackService.reward.getLinkableRewards();
        if (response.code === 200) {
            linkableItems.value.push(...response.data);
        }
    };
    const getLinkableItem = (id: number, type: number): StarterPackReward => {
        if (type === StarterPackRewardType.NoAds.value) {
            return new StarterPackReward(StarterPackRewardType.NoAds.value, 0, StarterPackRewardType.NoAds.label);
        }
        const foundItem = linkableItems.value.find(item => item.itemId === id && item.type === type);
        return foundItem ? new StarterPackReward(foundItem.type, foundItem.itemId, foundItem.itemName) : null;
    };

    return { linkableItems, fetchAllLinkableItems, getLinkableItem, linkableItemOption };
}
