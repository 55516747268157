
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppCard, AppSimpleTable, AppTable, AppButton } from '@/components';
import { usePromotionPrototypeList } from '@/modules/promotion/prototype/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';

export default defineComponent({
    name: 'PrototypeList',
    components: { AppTable, AppButton, AppCard },
    setup() {
        const { itemsInPage, fetchItemsInPage, total, currentPage, actions } = usePromotionPrototypeList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemsInPage, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: 'id' },
                { label: 'Name', field: 'name' },
                {
                    label: 'Personas',
                    component: (row: any) => ({
                        type: markRaw(AppSimpleTable),
                        props: {
                            items: row.personas,
                            fields: ['rfmPersonaName', 'packageId', 'price', 'credit', 'rate'],
                            isVisibleHeader: false
                        }
                    })
                },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: PROMOTION_ROUTER.PROTOTYPE.DETAIL(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        href: PROMOTION_ROUTER.PROTOTYPE.CLONE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchItemsInPage();
        });

        return { table, fetchItemsInPage, actions, PROMOTION_ROUTER };
    }
});
