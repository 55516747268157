import { TOURNAMENT_ROUTER } from '@/modules/tournament/router';
import { tournamentService } from '@/services/tournament';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useTournamentCycle() {
    const router = useRouter();
    const toast = useToast();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: tournamentService.cycle.list,
        pageFromStore: pageFromStore(STORE_MODULES.TournamentCycle.name),
        transformResponse: async (cycles: any[]) => {
            const leaderboardIds = new Set(cycles.map(cycle => cycle.tournamentLeaderboardId));
            const response = await tournamentService.leaderboard.getList(Array.from(leaderboardIds).join(','));

            if (response.code === 200) {
                const leaderboardNameMap = new Map(response.data.map((item: any) => [item.id, item.name]));
                return cycles.map(cycle => ({
                    ...cycle,
                    leaderboardName: leaderboardNameMap.get(cycle.tournamentLeaderboardId)
                }));
            } else {
                toast.error(response.data);
                router.push(TOURNAMENT_ROUTER.LEADERBOARD.INDEX());
                return;
            }
        }
    });

    return { itemList, total, currentPage, fetchList };
}
