import { QUEST_ROUTER } from '@/modules/quest/router';
import { useQuestTypeList } from '@/modules/quest/type/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { questService } from '@/services/quest';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useQuestItemEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { questTypeOptions, fetchList: fetchQuestTypeOption } = useQuestTypeList();

    const model = reactive({
        id: route.params.itemId,
        name: null,
        typeId: null,
        game: 0,
        fromAvgGame: -1,
        toAvgGame: -1,
        fromGameBet: -1,
        toGameBet: -1,
        gameType: 0,
        reward: 0,
        adsBoost: 0,
        adsBoostRate: 100,
        canDelete: false,
        canDisable: true,
        canDisableNext: true,
        canEnable: false,
        canEnableNext: false
    });

    const validations = {
        name: { required },
        game: { required, integer, min: minValue(1) },
        reward: { required, integer, min: minValue(1) },
        adsBoost: { required, integer, min: minValue(0) },
        adsBoostRate: { required, integer, min: minValue(100) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchQuestTypeOption();

        const response = await questService.item.get(model.id);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(QUEST_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await questService.item.update(
                model.id,
                model.name,
                model.game,
                model.reward,
                model.adsBoost,
                model.adsBoostRate
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push(QUEST_ROUTER.ITEM.INDEX());
            } else {
                toast.error(response.data);
            }
        },
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.disable(model.id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        disableNext: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.disableNext(model.id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enable: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.enable(model.id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enableNext: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.enableNext(model.id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(QUEST_ROUTER.ITEM.INDEX());
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, questTypeOptions, actions };
}
