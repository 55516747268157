
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useAvatarBundleEdit } from '@/modules/avatar/bundle/model/edit';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { AvatarBundleOptions } from '@/modules/avatar/bundle/model/mixins';

export default defineComponent({
    name: 'AvatarBundleEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, fetch, actions } = useAvatarBundleEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, AvatarBundleOptions, AVATAR_ROUTER };
    }
});
