import http from '@/utils/axios';

export const tournamentService = {
    leaderboard: {
        create(name: any, timeUnit: any, timeDuration: any, publishTime: any, unpublishTime: any) {
            return http.doPost('admin/tournament/leaderboard', {
                name: name,
                timeUnit: timeUnit,
                timeDuration: timeDuration,
                publishTime: publishTime,
                unpublishTime: unpublishTime
            });
        },

        update(id: any, name: any, unpublishTime: any) {
            return http.doPut('admin/tournament/leaderboard/' + id, {
                name: name,
                unpublishTime: unpublishTime
            });
        },

        remove(id: any) {
            return http.doDelete('admin/tournament/leaderboard/' + id);
        },

        enable(id: any) {
            return http.doPut('admin/tournament/leaderboard/' + id + '/enable');
        },

        disable(id: any) {
            return http.doPut('admin/tournament/leaderboard/' + id + '/disable');
        },

        get(id: any) {
            return http.doGet('admin/tournament/leaderboard/' + id);
        },

        list({ offset, limit }: any) {
            return http.doGet('admin/tournament/leaderboard', { offset, limit });
        },

        getList(itemIds: any) {
            return http.doGet('admin/tournament/leaderboard/status', {
                itemIds: itemIds
            });
        }
    },

    cycle: {
        list({ offset, limit }: any) {
            return http.doGet('admin/tournament/cycle', { offset, limit });
        }
    },

    medal: {
        getLinked({ leaderboardItemId }: any) {
            return http.doGet('admin/tournament/' + leaderboardItemId + '/medal');
        },

        link(leaderboardItemId: any, medalIds: any) {
            return http.doPut('admin/tournament/' + leaderboardItemId + '/medal/link', {
                medalIds: medalIds
            });
        },

        unlink(leaderboardItemId: any, medalIds: any) {
            return http.doPut('admin/tournament/' + leaderboardItemId + '/medal/unlink', {
                medalIds: medalIds
            });
        },

        create(name: any, rank: any, image: any) {
            return http.doPost(
                'admin/tournament/medal/item',
                {
                    name: name,
                    rank: rank
                },
                {
                    image: image
                }
            );
        },

        update(id: any, name: any, rank: any, image: any) {
            return http.doPost(
                'admin/tournament/medal/item/' + id,
                {
                    name: name,
                    rank: rank
                },
                {
                    image: image
                }
            );
        },

        remove(id: any) {
            return http.doDelete('admin/tournament/medal/item/' + id);
        },

        get(id: any) {
            return http.doGet('admin/tournament/medal/item/' + id);
        },

        list() {
            return http.doGet('admin/tournament/medal/item');
        }
    }
};
