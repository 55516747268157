
import { defineComponent, markRaw, onMounted, reactive, toRef } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard, AppSelect, AppTable } from '@/components';
import { usePromotionBoosterEdit } from '@/modules/promotion/booster/model/edit';
import { IColumn, IAction } from '@/components/table/model';
import { RouterLink } from 'vue-router';
import { Options as BoosterOptions } from '@/modules/booster/model/mixins';
import { renderOption } from '@/utils/helpers';

export default defineComponent({
    name: 'PromotionBoosterEdit',
    components: { AppFormRow, AppInput, AppButton, AppCard, AppSelect, AppTable },
    setup() {
        const { v$, model, fetch, promotionOption, actions } = usePromotionBoosterEdit();

        const table = reactive({
            rows: toRef(model, 'boosters'),
            columns: [
                { label: 'Type', display: row => renderOption(BoosterOptions.ItemType, row.type) },
                {
                    label: 'Name',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.name,
                        props: { to: row.link }
                    })
                },
                { label: 'Quantity', field: 'quantity' }
            ] as Array<IColumn>,
            actions: [] as Array<IAction>
        });

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, table, promotionOption, actions };
    }
});
