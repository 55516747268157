import { duplicateService } from '@/services/duplicate';
import { useFetchList } from '@/utils/useFetchList';
import { useRoute } from 'vue-router';

export function useDuplicateCycleDeck() {
    const route = useRoute();
    const params = route.params;

    const deckId = { itemId: params.itemId, cycleId: params.cycleId };
    const { itemList, fetchList } = useFetchList({
        request: duplicateService.cycle.getDeck,
        params: deckId
    });

    return { itemList, fetchList };
}
