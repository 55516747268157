
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppFileUpload } from '@/components';
import { AppButton } from '@/components';
import { useStarterPackAdd } from '@/modules/starter/pack/model/add';

export default defineComponent({
    name: 'StarterPackAdd',
    components: { AppFormRow, AppInput, AppSelect, AppFileUpload, AppCard, AppButton },
    setup() {
        const { model, v$, fetch, actions, iapOptions } = useStarterPackAdd();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, iapOptions };
    }
});
