
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { usePromotionCycleList } from '@/modules/promotion/cycle/model/list';
import { formatDate, renderOption } from '@/utils/helpers';
import { RouterLink } from 'vue-router';
import { statusOption } from '@/modules/promotion/cycle/model/mixins';

export default defineComponent({
    name: 'PromotionCycleList',
    components: { AppTable, AppCard },
    setup() {
        const { itemList, total, currentPage, fetchList, Routes } = usePromotionCycleList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                {
                    label: 'Promotion Plan',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.promotionPlanName,
                        props: { to: row.promotionPlanLink }
                    })
                },
                { label: 'Status', display: row => renderOption(statusOption, row.status) },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'End Time', display: (row: any) => formatDate(row.endTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        isShow: row.canEdit,
                        onclick: () => Routes.detail(row.id)
                    }),
                    group: 0
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchList();
        });

        return { table, itemList, fetchList, Routes };
    }
});
