import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Add Reward"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.StarterPackRewardOptions.StarterPackRewardType,
              modelValue: _ctx.model.type,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.type) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.type
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        (_ctx.model.type !== _ctx.StarterPackRewardType.NoAds.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_app_form_row, { label: "Item name" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_select, {
                    options: _ctx.linkableItemOption,
                    modelValue: _ctx.model.data,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.data) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.data
                  }, null, 8, ["options", "modelValue", "validations"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "Item id" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    type: "number",
                    modelValue: _ctx.model.data,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.data) = $event)),
                    modelModifiers: { number: true },
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.model.type === _ctx.StarterPackRewardType.NoAds.value)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 1,
              label: "Month"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  type: "number",
                  modelValue: _ctx.model.quantity,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.quantity) = $event)),
                  modelModifiers: { number: true },
                  validations: _ctx.v$.quantity,
                  disabled: ""
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_app_form_row, {
              key: 2,
              label: _ctx.model.type === _ctx.StarterPackRewardType.QuestBoost.value ? 'Duration' : 'Quantity'
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  type: "number",
                  modelValue: _ctx.model.quantity,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.quantity) = $event)),
                  modelModifiers: { number: true },
                  validations: _ctx.v$.quantity,
                  disabled: 
                        _ctx.model.type === _ctx.StarterPackRewardType.AvatarBundle.value ||
                        _ctx.model.type === _ctx.StarterPackRewardType.AvatarBackground.value
                    
                }, null, 8, ["modelValue", "validations", "disabled"])
              ]),
              _: 1
            }, 8, ["label"])),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Create",
              icon: "fa-save",
              theme: "info",
              onClick: _withModifiers(_ctx.create, ["prevent"])
            }, null, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}