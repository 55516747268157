
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppFileUpload, AppImageHolder } from '@/components';
import { AppButton } from '@/components';
import { useStarterPackEdit } from '@/modules/starter/pack/model/edit';

export default defineComponent({
    name: 'StarterPackEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, fetch, actions, iapOptions } = useStarterPackEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, iapOptions };
    }
});
