
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import { useDuplicateCycleDetail } from '@/modules/duplicate/cycle/model/detail';
import { formatDate, renderOption } from '@/utils/helpers';
import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { GAME_ROUTER } from '@/modules/game/router';
import { DuplicateCycleStatus } from '@/modules/duplicate/cycle/model/mixins';

export default defineComponent({
    name: 'DuplicateCycleDetail',
    components: { AppCard, AppButton },
    setup() {
        const { cycle, fetch } = useDuplicateCycleDetail();

        onMounted(async () => {
            await fetch();
        });

        return { cycle, formatDate, renderOption, DuplicateCycleStatus, DUPLICATE_ROUTER, GAME_ROUTER };
    }
});
