import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = {
  key: 1,
  class: "card-footer"
}
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Update Promotion Plan Text"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Locale" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.localeSupportedOptions,
              modelValue: _ctx.model.locale,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.locale) = $event)),
              validations: _ctx.v$.locale,
              onChange: _ctx.fetch
            }, null, 8, ["options", "modelValue", "validations", "onChange"])
          ]),
          _: 1
        }),
        (_ctx.model.locale)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_app_form_row, { label: "Title" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.model.title,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.title) = $event)),
                    validations: _ctx.v$.title
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "Description" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.model.description,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.description) = $event)),
                    validations: _ctx.v$.description
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.model.locale)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_app_button, {
                  name: "Update",
                  theme: "info",
                  icon: "fa-save",
                  disabled: !_ctx.v$.$anyDirty || !_ctx.model.locale,
                  onClick: _withModifiers(_ctx.actions.updateText, ["prevent"])
                }, null, 8, ["disabled", "onClick"]),
                (_ctx.model.hasData)
                  ? (_openBlock(), _createBlock(_component_app_button, {
                      key: 0,
                      name: "Delete",
                      icon: "fa-trash-alt",
                      theme: "danger",
                      onClick: _withModifiers(_ctx.actions.removeText, ["prevent"])
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}