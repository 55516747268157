
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect } from '@/components';
import { AppFileUpload, AppImageHolder, AppSwitch, AppButton } from '@/components';
import { useShopItemEdit } from '@/modules/shop/model/edit';
import { renderOption } from '@/utils/helpers';
import { SHOP_ROUTER } from '@/modules/shop/router';
import { ShopItemType, Options } from '@/modules/shop/model/mixins';

export default defineComponent({
    name: 'ShopEdit',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppFileUpload, AppImageHolder, AppSwitch, AppButton },
    setup() {
        const { model, v$, fetch, actions, iapOptions } = useShopItemEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, iapOptions, actions, renderOption, ShopItemType, Options, SHOP_ROUTER };
    }
});
