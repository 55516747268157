
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker } from '@/components';
import { useBonusLoginAdd } from '@/modules/bonus/login/model/add';
import { timeUnits } from '@/utils/helpers';
import { BonusLoginType } from '@/modules/bonus/login/model/mixins';

export default defineComponent({
    name: 'BonusLoginAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker },
    setup() {
        const { model, v$, create } = useBonusLoginAdd();

        return { v$, model, create, timeUnits, BonusLoginType };
    }
});
