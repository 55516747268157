import { ARENA_ROUTER } from '@/modules/arena/router';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { CREDIT_ROUTER } from '@/modules/credit/ticket/router';
import { QUEST_ROUTER } from '@/modules/quest/router';
import { SCRATCH_ROUTER } from '@/modules/scratch/router';

export const ItemSystemFeatures = [
    { value: 1, label: 'AvatarBundle', background: 'info', route: AVATAR_ROUTER.BUNDLE.INDEX() },
    { value: 2, label: 'ArenaTicket', background: 'success', route: ARENA_ROUTER.TICKET.INDEX() },
    { value: 3, label: 'QuestBoost', background: 'info', route: QUEST_ROUTER.BOOST.INDEX() },
    { value: 4, label: 'CreditTicket', background: 'success', route: CREDIT_ROUTER.TICKET.INDEX() },
    { value: 5, label: 'ScratchTicket', background: 'info', route: SCRATCH_ROUTER.TICKET.INDEX() }
];
