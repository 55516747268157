import http from '@/utils/axios';

export const newsService = {
    list({ offset, limit }: any) {
        return http.doGet('admin/news', { offset, limit });
    },

    get(id: string) {
        return http.doGet('admin/news/' + id);
    },

    create(
        name: any,
        cloneId: any,
        forAppId: any,
        target: any,
        startTime: any,
        endTime: any,
        publishTime: any,
        unpublishTime: any,
        fromRegisterTime: any,
        toRegisterTime: any,
        image: any,
        thumbnail: any
    ) {
        return http.doPost(
            'admin/news',
            {
                name: name,
                cloneId: cloneId,
                forAppId: forAppId,
                target: target,
                startTime: startTime,
                endTime: endTime,
                publishTime: publishTime,
                unpublishTime: unpublishTime,
                fromRegisterTime: fromRegisterTime,
                toRegisterTime: toRegisterTime
            },
            {
                image: image,
                thumbnail: thumbnail
            }
        );
    },

    update(
        id: string,
        name: any,
        target: any,
        startTime: any,
        endTime: any,
        publishTime: any,
        unpublishTime: any,
        image: any,
        thumbnail: any
    ) {
        return http.doPost(
            'admin/news/' + id,
            {
                name: name,
                target: target,
                startTime: startTime,
                endTime: endTime,
                publishTime: publishTime,
                unpublishTime: unpublishTime
            },
            {
                image: image,
                thumbnail: thumbnail
            }
        );
    },

    updateContent(
        id: string,
        name: any,
        target: any,
        fromRegisterTime: any,
        toRegisterTime: any,
        image: any,
        thumbnail: any
    ) {
        return http.doPost(
            'admin/news/' + id + '/content',
            {
                name: name,
                target: target,
                fromRegisterTime: fromRegisterTime,
                toRegisterTime: toRegisterTime
            },
            {
                image: image,
                thumbnail: thumbnail
            }
        );
    },

    remove(id: string) {
        return http.doDelete('admin/news/' + id);
    },

    updateText(id: string, locale: string, title: any, summary: any, content: any) {
        return http.doPost('admin/news/' + id + '/text/' + locale, {
            title: title,
            summary: summary,
            content: content
        });
    },

    removeText(id: string, locale: string) {
        return http.doDelete('admin/news/' + id + '/text/' + locale);
    },

    getText(id: string, locale: string) {
        return http.doGet('admin/news/' + id + '/text/' + locale);
    },

    enable(id: string) {
        return http.doPut('admin/news/' + id + '/enable');
    },

    disable(id: string) {
        return http.doPut('admin/news/' + id + '/disable');
    }
};
