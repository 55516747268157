import http from '@/utils/axios';

const item = {
    create(
        name: any,
        cloneId: any,
        forAppId: any,
        fromTime: any,
        toTime: any,
        displayType: any,
        expectedPackage: any,
        prototypeId: any,
        image: any
    ) {
        return http.doPost(
            'admin/promotion',
            { name, cloneId, forAppId, fromTime, toTime, displayType, expectedPackage, prototypeId },
            { image }
        );
    },

    update(id: any, name: any, image: any) {
        return http.doPost('admin/promotion/' + id, { name }, { image });
    },

    list({ offset, limit, status, rfmPersonaId }: any) {
        return http.doGet('admin/promotion', { offset, limit, status, rfmPersonaId });
    },

    get(id: any) {
        return http.doGet('admin/promotion/' + id);
    },

    remove(id: any) {
        return http.doDelete('admin/promotion/' + id);
    },

    disable(id: any) {
        return http.doPut('admin/promotion/' + id + '/disable');
    },

    enable(id: any) {
        return http.doPut('admin/promotion/' + id + '/enable');
    },

    export() {
        return http.doGet('admin/promotion/export');
    }
};

const text = {
    get(id: any, locale: string) {
        return http.doGet('admin/promotion/' + id + '/text/' + locale);
    },

    update(id: any, locale: string, title: any, description: any) {
        return http.doPut('admin/promotion/' + id + '/text/' + locale, {
            title: title,
            description: description
        });
    },

    remove(id: any, locale: string) {
        return http.doDelete('admin/promotion/' + id + '/text/' + locale);
    }
};

const prototype = {
    create(name: any, personaList: any, packageList: any, priceList: any, creditList: any, rateList: any) {
        return http.doPost('admin/promotion/prototype', {
            name,
            personaList,
            packageList,
            priceList,
            creditList,
            rateList
        });
    },

    list({ offset, limit }: any) {
        return http.doGet('admin/promotion/prototype', { offset, limit });
    },

    listAll({ forAppId }: any = {}) {
        const params = forAppId ? { appId: forAppId } : {};
        return http.doGet('admin/promotion/prototype/all', params);
    },

    get(prototypeId: any) {
        return http.doGet('admin/promotion/prototype/' + prototypeId);
    },

    remove(prototypeId: any) {
        return http.doDelete('admin/promotion/prototype/' + prototypeId);
    }
};

const code = {
    create(
        name: any,
        code: any,
        feature: any,
        data: any,
        quantity: any,
        receiveLimit: any,
        startTime: any,
        endTime: any
    ) {
        return http.doPost('admin/promotion/code', {
            name,
            code,
            feature,
            data,
            quantity,
            receiveLimit,
            startTime,
            endTime
        });
    },

    enable(itemId: any) {
        return http.doPut('admin/promotion/code/' + itemId + '/enable');
    },

    disable(itemId: any) {
        return http.doPut('admin/promotion/code/' + itemId + '/disable');
    },

    remove(itemId: any) {
        return http.doDelete('admin/promotion/code/' + itemId);
    },

    list({ offset, limit }: any) {
        return http.doGet('admin/promotion/code', { offset, limit });
    },

    get(itemId: any) {
        return http.doGet('admin/promotion/code/' + itemId);
    }
};

const booster = {
    list: async ({ offset, limit }: any) => {
        return http.doGet('/admin/promotion/booster', { offset, limit });
    },

    get: (id: any) => {
        return http.doGet('/admin/promotion/booster/' + id);
    },

    getLinkableItems: () => http.doGet('/admin/promotion/booster/items/linkable'),

    create: (name: string, promotionId: number, boosterList: Array<number>, quantityList: Array<number>) => {
        return http.doPost('/admin/promotion/booster', { name, promotionId, boosterList, quantityList });
    },

    update: (id: any, name: any) => {
        return http.doPost('/admin/promotion/booster/' + id, { name: name });
    },

    remove: (id: any) => {
        return http.doDelete('/admin/promotion/booster/' + id);
    }
};

const plan = {
    list({ offset, limit }: any) {
        return http.doGet('admin/promotion/plan', { offset, limit });
    },

    listInIds(itemIds: any) {
        return http.doGet('admin/promotion/plan/status', { itemIds: itemIds });
    },

    get(planId: any) {
        return http.doGet('admin/promotion/plan/' + planId);
    },

    getLinkableItems() {
        return http.doGet('admin/promotion/plan/items/linkable');
    },

    create(
        name: any,
        itemId: any,
        type: any,
        timeUnit: any,
        timeDuration: any,
        publishTime: any,
        unpublishTime: any,
        image: any
    ) {
        return http.doPost(
            'admin/promotion/plan',
            { name, itemId, type, timeUnit, timeDuration, publishTime, unpublishTime },
            { image }
        );
    },

    update: (id: any, name: any, unpublishTime: any, image: any) => {
        return http.doPut('/admin/promotion/plan/' + id, { name, unpublishTime }, { image });
    },

    enable(planId: any) {
        return http.doPut('admin/promotion/plan/' + planId + '/enable');
    },

    disable(planId: any) {
        return http.doPut('admin/promotion/plan/' + planId + '/disable');
    },

    remove(planId: any) {
        return http.doDelete('admin/promotion/plan/' + planId);
    },

    updateText(planId: any, locale: any, title: any, description: any) {
        return http.doPut('/admin/promotion/plan/' + planId + '/text/' + locale, { title, description });
    },

    removeText(planId: any, locale: any) {
        return http.doDelete('/admin/promotion/plan/' + planId + '/text/' + locale);
    },

    getText(planId: any, locale: any) {
        return http.doGet('/admin/promotion/plan/' + planId + '/text/' + locale);
    }
};

const cycle = {
    list({ offset, limit }: any) {
        return http.doGet('admin/promotion/cycle', { offset, limit });
    },

    get(id: any) {
        return http.doGet('admin/promotion/cycle/' + id);
    }
};

export const promotionService = {
    prototype,
    item,
    code,
    text,
    booster,
    plan,
    cycle
};
