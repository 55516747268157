
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppSelect, AppButton, AppCard, AppDateTimePicker } from '@/components';
import { useVersionEdit } from '@/modules/version/model/edit';
import { VersionOptions } from '@/modules/version/model/mixins';

export default defineComponent({
    name: 'VersionEdit',
    components: { AppFormRow, AppInput, AppSelect, AppButton, AppCard, AppDateTimePicker },
    setup() {
        const route = useRoute();
        const { model, v$, fetch, update } = useVersionEdit();

        onMounted(async () => {
            await fetch(route.params.id);
        });

        return { model, v$, VersionOptions, update };
    }
});
