
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useTicketAdd } from '@/modules/scratch/ticket/model/add';
import { ScratchTicketType, ScratchTicketOptions } from '@/modules/scratch/ticket/model/mixins';

export default defineComponent({
    name: 'ScratchAdd',
    components: { AppFormRow, AppInput, AppSelect, AppDateTimePicker, AppButton, AppCard },
    setup() {
        const { model, v$, fetchIapOptions, iapOptions, timeUnits, actions } = useTicketAdd();

        onMounted(() => {
            fetchIapOptions();
        });

        return { model, v$, iapOptions, ScratchTicketOptions, ScratchTicketType, timeUnits, actions };
    }
});
