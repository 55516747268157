import localeService from '@/services/locale';
import { STORE_MODULES } from '@/store';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { Ref, computed, ref } from 'vue';
import { useToast } from 'vue-toastification';

export function useLocale() {
    const toast = useToast();

    const localeListSupported = ref([]);
    const criteria: Ref<string> = stateFromStore<string>({
        moduleName: STORE_MODULES.Locale.name,
        getterFuncName: 'criteria',
        setterFuncName: 'setCriteria'
    });
    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: localeService.list,
        pageFromStore: pageFromStore(STORE_MODULES.Locale.name),
        params: computed(() => ({ criteria: criteria.value }))
    });

    const getListSupported = async () => {
        const response = await localeService.listSupported();
        if (response.code === 200) {
            localeListSupported.value = response.data;
        } else {
            toast.error(response.data);
            localeListSupported.value = [];
        }
    };

    const localeSupportedOptions = computed(() =>
        localeListSupported.value.map(l => ({ label: l.language, value: l.id }))
    );

    const actions = {
        add: async (locale: string) => {
            const response = await localeService.add(locale);
            if (response.code === 200) {
                toast.success('Add successfully');
                fetchList();
            } else {
                toast.error(response.data);
            }
        },

        publish: async (locale: string) => {
            const response = await localeService.publish(locale);
            if (response.code === 200) {
                toast.success('Publish successfully');
                fetchList();
            } else {
                toast.error(response.data);
            }
        },

        unpublish: async (locale: string) => {
            const response = await localeService.unpublish(locale);
            if (response.code === 200) {
                toast.success('Unpublish successfully');
                fetchList();
            } else {
                toast.error(response.data);
            }
        },

        setDefault: async (locale: string) => {
            const response = await localeService.setDefault(locale);
            if (response.code === 200) {
                toast.success('Successfully');
                fetchList();
            } else {
                toast.error(response.data);
            }
        },

        remove: async (locale: string) => {
            const response = await localeService.remove(locale);
            if (response.code === 200) {
                toast.success('Delete successfully');
                fetchList();
            } else {
                toast.error(response.data);
            }
        }
    };

    return {
        itemList,
        localeListSupported,
        localeSupportedOptions,
        total,
        fetchList,
        currentPage,
        getListSupported,
        criteria,
        actions
    };
}
