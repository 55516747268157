import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetchList';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';

export function useAvatarLayerList() {
    const toast = useToast();
    const vDialog = useVDialog();

    const { itemList, total, fetchList } = useFetchList({ request: avatarService.layer.list });

    const layerOption = computed(() => itemList.value.map(l => ({ label: l.name, value: l.id })));
    const layerMap = computed(() => new Map(itemList.value.map(l => [l.id, l])));

    const actions = {
        remove: (id: any) => {
            vDialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async result => {
                    if (result) {
                        const response = await avatarService.layer.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, actions, layerOption, layerMap };
}
