
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSwitch, AppSelect, AppButton } from '@/components';
import { useGameBetEdit } from '@/modules/game/bet/model/edit';
import { GameBetLockType, GameBetOptions, GameBetType } from '@/modules/game/bet/model/mixins';
export default defineComponent({
    name: 'GameBetEdit',
    components: { AppFormRow, AppInput, AppCard, AppSwitch, AppSelect, AppButton },
    setup() {
        const { model, v$, fetch, actions } = useGameBetEdit();

        onMounted(async () => {
            await fetch();
        });

        return { model, v$, GameBetOptions, GameBetType, GameBetLockType, actions };
    }
});
