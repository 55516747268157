
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppFormRow, AppInput, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useDuplicateRank } from '@/modules/duplicate/cycle/item/model/rank';
import { ACCOUNT_ROUTER } from '@/modules/account/router';

export default defineComponent({
    name: 'DuplicateRanks',
    components: { AppTable, AppCard, AppFormRow, AppInput, AppButton },
    setup() {
        const { filter, itemList, total, currentPage, fetchList } = useDuplicateRank();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Account ID', field: 'accountId' },
                { label: 'Name', field: 'name' },
                { label: 'Value', field: 'value' },
                { label: 'Actions', field: 'accountId' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-users',
                        theme: 'info',
                        tooltip: 'Accounts',
                        href: ACCOUNT_ROUTER.APP.DETAIL(row.accountId)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { filter, itemList, table, fetchList };
    }
});
