
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppError } from '@/components';
import { usePicwordMapEdit } from '@/modules/picword/map/model/edit';
import { SelectableBooster } from '@/modules/picword/map/model/mixins';

export default defineComponent({
    name: 'PicwordMapAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppError },
    setup() {
        const { model, v$, fetch, actions } = usePicwordMapEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, SelectableBooster };
    }
});
