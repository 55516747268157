import { useVDialog } from '@/plugins/dialog/v-dialog';
import { picwordService } from '@/services/picword';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';

export function usePicwordMapList() {
    const toast = useToast();
    const dialog = useVDialog();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: picwordService.map.list,
        pageFromStore: pageFromStore(STORE_MODULES.PicwordMap.name)
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await picwordService.map.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, fetchList, actions };
}
