
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton, AppSimpleTable } from '@/components';
import { usePicwordMapDetail } from '@/modules/picword/map/model/detail';
import { PICWORD_ROUTES } from '@/modules/picword/router';

export default defineComponent({
    name: 'PicwordMapDetail',
    components: { AppCard, AppButton, AppSimpleTable },
    setup() {
        const { model, fetch, actions } = usePicwordMapDetail();

        onMounted(async () => {
            await fetch();
        });

        return { model, actions, PICWORD_ROUTES };
    }
});
