
import {
    AppButton,
    AppCard,
    AppDateTimePicker,
    AppFileUpload,
    AppFormRow,
    AppImageHolder,
    AppInput,
    AppSelect
} from '@/components';
import { useNewsEdit } from '@/modules/news/model/edit';
import { NewsOptions } from '@/modules/news/model/mixins';
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'EditNews',
    components: {
        AppFormRow,
        AppInput,
        AppButton,
        AppCard,
        AppSelect,
        AppDateTimePicker,
        AppFileUpload,
        AppImageHolder
    },
    setup() {
        const route = useRoute();
        const { model, v$, fetch, actions } = useNewsEdit();

        onMounted(async () => {
            await fetch(route?.params?.id);
        });

        return { model, v$, NewsOptions, actions };
    }
});
