
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppCard, AppTable, AppButton, AppSelect, AppFormRow } from '@/components';
import { useRfmSegmentation } from '@/modules/rfm/segmentation/edit';
import { color } from '@/utils/helpers';

export default defineComponent({
    name: 'RfmSegmentation',
    components: { AppTable, AppButton, AppSelect, AppFormRow, AppCard },
    setup() {
        const { beforeFetch, fetch, model, rfmGroupId, rfmGroupOptions, renderValueRange, actions } =
            useRfmSegmentation();

        // Init table settings
        const table = reactive({
            rows: toRef(model, 'data'),
            columns: [
                { label: '#', field: '#' },
                {
                    label: 'Recency',
                    tooltip: 'How long does account take from last payment? (day)',
                    display: (row: any) => renderValueRange(row.fromDay, row?.toDay ?? -1)
                },
                {
                    label: 'Frequence',
                    tooltip: 'How many times has account purchased?',
                    display: (row: any) => renderValueRange(row.fromTransaction, row?.toTransaction ?? -1)
                },
                {
                    label: 'Monetary',
                    tooltip: 'How much did account spend?',
                    display: (row: any) => renderValueRange(row.fromPayment, row?.toPayment ?? -1)
                },
                {
                    label: 'Persona',
                    tooltip: 'What kind of persona does account belong to?',
                    columnStyles: (row: any) => `background: ${color(row.personaId)}`,
                    component: (row: any) => ({
                        type: markRaw(AppSelect),
                        props: {
                            options: model.personasByGroup,
                            modelValue: row.personaId,
                            onChange: (evt: any) => {
                                const selectedItem = model.data.find(item => item.index === row.index);
                                selectedItem.personaId = Number(evt.target.value);
                                actions.changePersona(selectedItem);
                            }
                        }
                    })
                }
            ] as Array<any>,
            actions: []
        });

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { table, fetch, model, rfmGroupId, rfmGroupOptions, actions };
    }
});
