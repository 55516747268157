import { useVDialog } from '@/plugins/dialog/v-dialog';
import { roleService } from '@/services/role';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';

export default function () {
    const toast = useToast();
    const dialog = useVDialog();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: roleService.app.list,
        pageFromStore: pageFromStore(STORE_MODULES.AppRole.name)
    });

    const actions = {
        removeAppRole: (accountId: any, role: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await roleService.app.remove(accountId, role);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, fetchList, actions };
}
