import http from '@/utils/axios';

export const duplicateService = {
    item: {
        create(
            name: any,
            type: any,
            betId: any,
            cost: any,
            prizes: any,
            round: any,
            game: any,
            timeUnit: any,
            timeDuration: any,
            prepareSeconds: any,
            openSeconds: any,
            closeSeconds: any,
            rankingSeconds: any,
            publishTime: any,
            unpublishTime: any,
            thumbnail: any
        ) {
            return http.doPost(
                'admin/duplicate/item',
                {
                    name: name,
                    type: type,
                    betId: betId,
                    cost: cost,
                    prizes: prizes,
                    round: round,
                    game: game,
                    timeUnit: timeUnit,
                    timeDuration: timeDuration,
                    prepareSeconds: prepareSeconds,
                    openSeconds: openSeconds,
                    closeSeconds: closeSeconds,
                    rankingSeconds: rankingSeconds,
                    publishTime: publishTime,
                    unpublishTime: unpublishTime
                },
                {
                    thumbnail: thumbnail
                }
            );
        },

        update(
            id: any,
            name: any,
            prepareSeconds: any,
            openSeconds: any,
            closeSeconds: any,
            rankingSeconds: any,
            unpublishTime: any,
            thumbnail: any
        ) {
            return http.doPut(
                'admin/duplicate/item/' + id,
                {
                    name: name,
                    prepareSeconds: prepareSeconds,
                    openSeconds: openSeconds,
                    closeSeconds: closeSeconds,
                    rankingSeconds: rankingSeconds,
                    unpublishTime: unpublishTime
                },
                {
                    thumbnail: thumbnail
                }
            );
        },

        enable(id: any) {
            return http.doPut('admin/duplicate/item/' + id + '/enable');
        },

        disable(id: any) {
            return http.doPut('admin/duplicate/item/' + id + '/disable');
        },

        remove(id: any) {
            return http.doDelete('admin/duplicate/item/' + id);
        },

        get(id: any) {
            return http.doGet('admin/duplicate/item/' + id);
        },

        list({ offset, limit }: any) {
            return http.doGet('admin/duplicate/item', { offset, limit });
        },

        getList(itemIds: any) {
            return http.doGet('admin/duplicate/item/status', {
                itemIds: itemIds
            });
        },

        updateText(id: any, locale: any, title: any) {
            return http.doPost('admin/duplicate/item/' + id + '/text/' + locale, {
                title: title
            });
        },

        removeText(id: any, locale: any) {
            return http.doDelete('admin/duplicate/item/' + id + '/text/' + locale);
        },

        getText(id: any, locale: any) {
            return http.doGet('admin/duplicate/item/' + id + '/text/' + locale);
        }
    },

    cycle: {
        list({ offset, limit }: any) {
            return http.doGet('admin/duplicate/cycle', { offset, limit });
        },

        get(id: any) {
            return http.doGet('admin/duplicate/cycle/' + id);
        },

        getDeck({ itemId, cycleId }: any) {
            return http.doGet('admin/duplicate/cycle/' + cycleId + '/item/' + itemId + '/deck');
        }
    },

    account: {
        getRankList({ offset, limit, duplicateItemId, duplicateCycleId, accountId }: any) {
            return http.doGet('admin/duplicate/account/' + duplicateItemId + '/rank', {
                duplicateCycleId,
                accountId,
                offset,
                limit
            });
        }
    },

    setting: {
        get() {
            return http.doGet('admin/duplicate/setting');
        },

        update(deckType: any, gameLeavePoint: any) {
            return http.doPut('admin/duplicate/setting', {
                deckType: deckType,
                gameLeavePoint: gameLeavePoint
            });
        },

        remove() {
            return http.doDelete('admin/duplicate/setting');
        }
    }
};
