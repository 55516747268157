export const AvatarBundleOptions = {
    Gender: [
        { label: 'Female', value: 0, background: 'success' },
        { label: 'Male', value: 1, background: 'info' }
    ],
    Status: [
        { label: 'Can link', value: 0, background: 'success' },
        { label: 'Can not link', value: 1, background: 'info' }
    ]
};
