import {
    convertChestRewardListFromStrings,
    convertChestRewardListToStrings,
    useMixins
} from '@/modules/picword/map/model/mixins';
import { PICWORD_ROUTES } from '@/modules/picword/router';
import { picwordService } from '@/services/picword';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePicwordMapEdit() {
    const router = useRouter();
    const toast = useToast();

    const { v$, model, addChestReward, removeChestReward, clearChestForm } = useMixins();

    const fetch = async () => {
        const response = await picwordService.map.get(model.id);
        if (response.code === 200) {
            const result = response.data;
            model.chestRewardList = convertChestRewardListFromStrings(
                result.chestByStar,
                result.chestRewardCredit,
                result.chestRewardHeart,
                result.chestRewardBooster
            );

            Object.assign(model, result);
        } else {
            toast.error(response.data);
            router.push(PICWORD_ROUTES.MAP.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const chests = convertChestRewardListToStrings(model.chestRewardList);
            const response = await picwordService.map.update(
                model.id,
                model.name,
                chests.chestByStars,
                chests.chestRewardCredits,
                chests.chestRewardHearts,
                chests.chestRewardBoosters
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push(PICWORD_ROUTES.MAP.INDEX());
            } else {
                toast.error(response.data);
            }
        },
        addChestReward,
        removeChestReward,
        clearChestForm
    };

    return { model, v$, fetch, actions };
}
