
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useRfmMonetary } from '@/modules/rfm/monetary/model/edit';

export default defineComponent({
    name: 'MonetaryAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, rfmGroupOptions, fetchRfmGroupAll, actions } = useRfmMonetary();

        onMounted(async () => {
            await fetchRfmGroupAll();
        });

        return { v$, model, rfmGroupOptions, actions };
    }
});
