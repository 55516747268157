import http from '@/utils/axios';

export const itemService = {
    setting: {
        list() {
            return http.doGet('admin/item/setting');
        },

        get(itemId: any) {
            return http.doGet('admin/item/' + itemId + '/setting');
        },

        create(timeUnit: any, timeDuration: any, startTime: any) {
            return http.doPost('admin/item/setting', {
                timeUnit: timeUnit,
                timeDuration: timeDuration,
                startTime: startTime
            });
        },

        update(itemId: any, timeUnit: any, timeDuration: any, startTime: any) {
            return http.doPut('admin/item/' + itemId + '/setting', {
                timeUnit: timeUnit,
                timeDuration: timeDuration,
                startTime: startTime
            });
        },

        remove(itemId: any) {
            return http.doDelete('admin/item/' + itemId + '/setting');
        }
    },

    item: {
        create(feature: any, data: any, name: any, ruby: any, quantity: any, image: any) {
            return http.doPost(
                'admin/item',
                {
                    feature: feature,
                    data: data,
                    name: name,
                    ruby: ruby,
                    quantity: quantity
                },
                {
                    image: image
                }
            );
        },

        update(id: any, name: any, ruby: any, quantity: any, image: any) {
            return http.doPost(
                'admin/item/' + id,
                {
                    name: name,
                    ruby: ruby,
                    quantity: quantity
                },
                {
                    image: image
                }
            );
        },

        remove(id: any) {
            return http.doDelete('admin/item/' + id);
        },

        list({ offset, limit, forAppId, name }: any) {
            return http.doGet('admin/item', { offset, limit, forAppId, name });
        },

        get(id: any) {
            return http.doGet('admin/item/' + id);
        },

        getLinkableApp(id: any) {
            return http.doGet('admin/item/' + id + '/app');
        },

        getApp(id: any, appId: any) {
            return http.doGet('admin/item/' + id + '/app/' + appId);
        },

        addForApp(id: any, appId: any) {
            return http.doPut('admin/item/' + id + '/app/' + appId);
        },

        removeFromApp(id: any, appId: any) {
            return http.doDelete('admin/item/' + id + '/app/' + appId);
        },

        addForAppNext(id: any, appId: any) {
            return http.doPut('admin/item/' + id + '/app/' + appId + '/next');
        },

        removeFromAppNext(id: any, appId: any) {
            return http.doDelete('admin/item/' + id + '/app/' + appId + '/next');
        }
    },

    text: {
        get(itemId: any, locale: any) {
            return http.doGet('admin/item/' + itemId + '/text/' + locale);
        },

        update(itemId: any, locale: any, title: any) {
            return http.doPut('admin/item/' + itemId + '/text/' + locale, {
                title: title
            });
        },

        remove(itemId: any, locale: any) {
            return http.doDelete('admin/item/' + itemId + '/text/' + locale);
        }
    }
};
