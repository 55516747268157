import { useStarterPackRewardMixins } from '@/modules/starter/pack/reward/model/mixins';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { starterPackService } from '@/services/starter';
import { useFetchList } from '@/utils/useFetchList';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useStarterPackRewardList() {
    const route = useRoute();
    const dialog = useVDialog();
    const toast = useToast();

    const starterPackId = route.params.starterPackId;
    const canUpdateReward = ref(false);

    const { fetchAllLinkableItems, getLinkableItem } = useStarterPackRewardMixins();

    const { itemList, total, fetchList } = useFetchList({
        request: starterPackService.reward.list,
        params: { starterPackId: starterPackId },
        beforeFetch: async () => {
            await fetchAllLinkableItems();
            const packResponse = await starterPackService.get(starterPackId);
            if (packResponse.code === 200) {
                canUpdateReward.value = packResponse.data.canEnable;
            }
        },
        transformResponse: (list: any[]) =>
            list.map(reward => {
                const linkableItem = getLinkableItem(reward.data, reward.type);
                return {
                    ...reward,
                    itemLink: linkableItem?.getLink(),
                    itemName: linkableItem?.itemName
                };
            })
    });

    const actions = {
        remove: (type: any, data: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await starterPackService.reward.remove(starterPackId, type, data);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, starterPackId, actions, canUpdateReward };
}
