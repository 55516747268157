import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "card-footer" }
const _hoisted_4 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_image_holder = _resolveComponent("app-image-holder")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Update Piggybank"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Curent Uses" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.totalLinked,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.totalLinked) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Status" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.renderOption(_ctx.PiggyBankStatus, _ctx.model.status)
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "IAP Item" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.iapOptions,
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.actions.selectIAP($event.target.value)))
            }, null, 8, ["options"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Package ID" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.packageId,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.packageId) = $event)),
              validations: _ctx.v$.packageId
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Value" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.value) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.value
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Price" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.price,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.price) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.price
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Base Logarithm" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.baseLog,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.baseLog) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.baseLog
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "From Avg Payment (c)" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.fromAvgPayment,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.fromAvgPayment) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.fromAvgPayment
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "To Avg Payment (c)" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.toAvgPayment,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.toAvgPayment) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.toAvgPayment
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Ads Boost" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.adsBoost,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.adsBoost) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.adsBoost
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Ads Boost Percent" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.adsBoostPercent,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.adsBoostPercent) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.adsBoostPercent
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Ads Boost Min Value" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.adsBoostMin,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.adsBoostMin) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.adsBoostMin
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Ads Boost Seconds" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.adsBoostSeconds,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.adsBoostSeconds) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.adsBoostSeconds
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Image" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.image
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.imageFile,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.imageFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Thumbnail" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.thumbnail
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.thumbnailFile,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.thumbnailFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_button, {
              name: "Update",
              theme: "info",
              icon: "fa-save",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"]),
            _createVNode(_component_app_button, {
              name: "Translate",
              theme: "info",
              icon: "fa-language",
              href: _ctx.PIGGYBANK_ROUTER.ITEM.TRANSLATE(_ctx.model.id)
            }, null, 8, ["href"]),
            (_ctx.model.canEnable)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  name: "Enable",
                  icon: "fa-play",
                  theme: "success",
                  onClick: _withModifiers(_ctx.actions.enable, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.model.canDisable)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 1,
                  name: "Disable",
                  icon: "fa-stop",
                  theme: "warning",
                  onClick: _withModifiers(_ctx.actions.disable, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.model.canDelete)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 2,
                  name: "Delete",
                  icon: "fa-trash-alt",
                  theme: "danger",
                  onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}