import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Add Quest"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.questTypeOptions,
              modelValue: _ctx.model.typeId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.typeId) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.typeId
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "From Avg Game" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.fromAvgGame,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.fromAvgGame) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.fromAvgGame
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "To Avg Game" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.toAvgGame,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.toAvgGame) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.toAvgGame
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "From Game Bet" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.fromGameBet,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.fromGameBet) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.fromGameBet
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "To Game Bet" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.toGameBet,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.toGameBet) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.toGameBet
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Game Type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.QuestItemGameType,
              modelValue: _ctx.model.gameType,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.gameType) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.gameType
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Game" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.game,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.game) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.game
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Reward" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.reward,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.reward) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.reward
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Ads Boost" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.adsBoost,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.adsBoost) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.adsBoost
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Ads Boost Rate" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.adsBoostRate,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.adsBoostRate) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.adsBoostRate
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: " Create",
              theme: "info",
              icon: "fa-save",
              onClick: _withModifiers(_ctx.actions.create, ["prevent"])
            }, null, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}