import { activityService } from '@/services/activity';
import { STORE_MODULES } from '@/store';
import { StoreActivityFilter } from '@/store/activity';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import useVuelidate from '@vuelidate/core';
import { minLength, numeric } from '@vuelidate/validators';
import { Ref } from 'vue';

export function useActivity() {
    const filter: Ref<StoreActivityFilter> = stateFromStore<StoreActivityFilter>({
        moduleName: STORE_MODULES.Activity.name,
        getterFuncName: 'filter',
        setterFuncName: 'setFilter'
    });

    const validations = {
        feature: { min: minLength(3) },
        accountId: { numeric }
    };

    const v$ = useVuelidate(validations, filter);

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: activityService.app.list,
        pageFromStore: pageFromStore(STORE_MODULES.Activity.name),
        params: filter,
        preCheck: () => {
            v$.value.$touch();
            return !v$.value.$invalid;
        }
    });

    return { filter, v$, itemList, total, currentPage, fetchList };
}
