import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { promotionService } from '@/services/promotion';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePromotionItemEdit() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params.id,
        name: '',
        image: null,
        imageStatus: null,
        imageFile: null
    });

    const validations = {
        name: { required },
        imageFile: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await promotionService.item.get(model.id);
        if (response.code === 200) {
            const result = response.data;
            model.id = result.id;
            model.name = result.name;
            model.image = result.image;
            model.imageStatus = result.imageStatus;
        } else {
            toast.error(response.data);
            router.push(PROMOTION_ROUTER.ITEM.INDEX());
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await promotionService.item.update(model.id, model.name, model.imageFile);
        if (response.code === 200) {
            toast.success('Update successfully');
            router.push(PROMOTION_ROUTER.ITEM.INDEX());
        }
    };

    return { model, v$, fetch, update };
}
