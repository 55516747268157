export const DisplayType = {
    Shop: { value: 0, label: 'Shop' },
    Popup: { value: 1, label: 'Popup' },
    Event: { value: 2, label: 'Event' },
    AccessUnlimited: { value: 3, label: 'AccessUnlimited' }
};

export const FilterType = {
    NoFilter: { label: 'No filter', value: 0 },
    Status: { label: 'Status', value: 1 },
    Persona: { label: 'Persona', value: 2 }
};

export const Options = {
    Status: [
        { label: 'All', value: -1 },
        { label: 'None', value: 0, background: 'info' },
        { label: 'Enabled', value: 1, background: 'success' },
        { label: 'Started', value: 2, background: 'success' },
        { label: 'Stopped', value: 3, background: 'warning' },
        { label: 'Disabled', value: 4, background: 'danger' },
        { label: 'Linkable', value: 5, background: 'info' }
    ],

    DisplayType: Object.entries(DisplayType).map(([, data]) => data),
    FilterType: Object.entries(FilterType).map(([, data]) => data)
};
