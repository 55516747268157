import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import router from '@/router';

export const Routes = {
    index: () => router.push(PROMOTION_ROUTER.CYCLE.INDEX()),
    detail: (id: any) => router.push(PROMOTION_ROUTER.CYCLE.DETAIL(id))
};

export const getPromotionPlanLink = (planId: number) =>
    planId ? router.resolve(PROMOTION_ROUTER.PLAN.DETAIL(planId))?.href : '';

export const statusOption = [
    { value: 0, label: 'None', background: 'secondary' },
    { value: 1, label: 'Started', background: 'info' },
    { value: 2, label: 'Ended', background: 'warning' },
    { value: 3, label: 'Disabled', background: 'danger' }
];
