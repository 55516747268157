import { ITEM_ROUTER } from '@/modules/item/router';
import { FEATURES } from '@/modules/promotion/code/model/mixins';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { SCRATCH_ROUTER } from '@/modules/scratch/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { scratchTicketService } from '@/services/scratch';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';

export function useTicketList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: scratchTicketService.list,
        pageFromStore: pageFromStore(STORE_MODULES.Ticket.name)
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await scratchTicketService.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => SCRATCH_ROUTER.TICKET.ADD(),
        edit: (id: any) => SCRATCH_ROUTER.TICKET.EDIT(id),
        specialShop: (type: any) => ITEM_ROUTER.SYSTEM.SPECIAL_SHOP(FEATURES.SCRATCH_TICKET, type),
        promoCode: (type: any) => PROMOTION_ROUTER.CODE.ADD_TO_FEATURE(FEATURES.SCRATCH_TICKET, type)
    };

    return { itemList, total, currentPage, fetchList, actions, routes };
}
