
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { useBonusLoginList } from '@/modules/bonus/login/model/list';
import { BonusLoginType } from '@/modules/bonus/login/model/mixins';
import { IAction, IColumn } from '@/components/table/model';
import { formatDate, renderOption, renderTimeUnit } from '@/utils/helpers';
import { BONUS_ROUTER } from '@/modules/bonus/router';

export default defineComponent({
    name: 'BonusLoginList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, fetchList, actions } = useBonusLoginList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Time Unit', display: (row: any) => renderTimeUnit(row.timeUnit) },
                { label: 'Time Duration', field: 'timeDuration' },
                { label: 'Type', display: (row: any) => renderOption(BonusLoginType, row.type) },
                { label: 'Value', field: 'value' },
                { label: 'Ads Boost', field: 'adsBoost' },
                { label: 'Ads Rescue', field: 'adsRescue' },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-pen-square',
                        theme: 'info',
                        tooltip: 'Edit ads',
                        href: BONUS_ROUTER.LOGIN.ADS(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        href: BONUS_ROUTER.LOGIN.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchList();
        });

        return { table, fetchList, BONUS_ROUTER };
    }
});
