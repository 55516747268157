
import { defineComponent, onMounted } from 'vue';
import {
    AppFormRow,
    AppInput,
    AppCard,
    AppButton,
    AppSelect,
    AppSwitch,
    AppFileUpload,
    AppAvatarBreadcrumb
} from '@/components';
import { useAvatarCategoryAdd } from '@/modules/avatar/category/model/add';
import { AvatarGender } from '@/modules/avatar/gender/model/gender';

export default defineComponent({
    name: 'AvatarCategoryAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppSwitch, AppFileUpload, AppAvatarBreadcrumb },
    setup() {
        const { model, v$, fetch, create } = useAvatarCategoryAdd();

        onMounted(() => {
            fetch();
        });

        return { v$, model, create, AvatarGender };
    }
});
