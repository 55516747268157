import { convertPetRewardListFromStrings, useMixins } from '@/modules/picword/pet/model/mixins';
import { PICWORD_ROUTES } from '@/modules/picword/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { picwordService } from '@/services/picword';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePicwordPetDetail() {
    const router = useRouter();
    const toast = useToast();
    const dialog = useVDialog();

    const { model } = useMixins();

    const fetch = async () => {
        const response = await picwordService.pet.get(model.id);
        if (response.code === 200) {
            const result = response.data;
            model.rewardList = convertPetRewardListFromStrings(
                result.experienceByLevel,
                result.creditReward,
                result.heartReward,
                result.boosterReward
            );

            Object.assign(model, result);
        } else {
            toast.error(response.data);
            router.push(PICWORD_ROUTES.PET.INDEX());
        }
    };

    const actions = {
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await picwordService.pet.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(PICWORD_ROUTES.PET.INDEX());
                        }
                    }
                }
            });
        }
    };

    return { model, fetch, actions };
}
