import store from '@/store';
import { Ref, computed } from 'vue';

const exists = (moduleName: string) => store.hasModule(moduleName);

export const storeGetter = <T>(params: { moduleName: string; getterFuncName: string }): T => {
    if (!exists(params.moduleName)) {
        throw new Error(`Module ${params.moduleName} does not exist`);
    }

    return store.getters[`${params.moduleName}/${params.getterFuncName}`] as T;
};

export const storeDispatch = <T>(params: { moduleName: string; action: string; payload?: T }) => {
    if (!exists(params.moduleName)) {
        throw new Error(`Module ${params.moduleName} does not exist`);
    }

    return store.dispatch(`${params.moduleName}/${params.action}`, params.payload);
};

export const stateFromStore = <T>(params: {
    moduleName: string;
    getterFuncName: string;
    setterFuncName: string;
}): Ref<T> => {
    if (!exists(params.moduleName)) {
        throw new Error(`Module ${params.moduleName} does not exist`);
    }

    return computed({
        get: () => store.getters[`${params.moduleName}/${params.getterFuncName}`],
        set: (newValue: T) => store.dispatch(`${params.moduleName}/${params.setterFuncName}`, newValue)
    });
};

export const pageFromStore = (moduleName: string): Ref<number> => {
    const getterFuncName = 'page';
    const setterFuncName = 'changePage';

    return stateFromStore<number>({ moduleName, getterFuncName, setterFuncName });
};
