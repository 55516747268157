
import { computed, defineComponent, PropType } from 'vue';
import { AppError } from '@/components';
import { generateID } from '@/utils/helpers';

export type Option = {
    label: string;
    value: string | number;
};

export default defineComponent({
    name: 'AppSelect',
    components: { AppError },
    props: {
        modelValue: { type: [String, Number], default: null },
        disabled: { type: Boolean, default: false },
        type: { type: String, default: 'default' },
        label: { type: String, default: null },
        options: { type: Array as PropType<Option[]> },
        validations: { type: Object, default: null }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const ID: string = generateID();

        function onValueChange(event: any) {
            emit('update:modelValue', event.target.value);

            if (props.validations && typeof props.validations?.$touch === 'function') {
                props.validations.$touch();
            }
        }

        const isNoneSelected = computed(() => {
            if (props.options.find(option => option.value == null)) {
                return false;
            }

            return props.options.findIndex(option => option.value === props.modelValue) < 0;
        });

        return { ID, isNoneSelected, onValueChange };
    }
});
