import { useAppList } from '@/modules/apps/model/list';
import { ItemSystemFeatures } from '@/modules/item/system/model/mixins';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { itemService } from '@/services/item';
import { STORE_MODULES } from '@/store';
import { SystemItemFilter } from '@/store/item';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import useVuelidate from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useSysItemList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const selectedFeature = ref(null);
    const { appOptionWithAll, mapApps } = useAppList();

    const filter: Ref<SystemItemFilter> = stateFromStore<SystemItemFilter>({
        moduleName: STORE_MODULES.Item.name,
        getterFuncName: 'filter',
        setterFuncName: 'setFilter'
    });

    const v$ = useVuelidate(
        {
            name: { minLength: minLength(3) }
        },
        filter
    );

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: itemService.item.list,
        params: filter,
        pageFromStore: pageFromStore(STORE_MODULES.Item.name),
        preCheck: () => {
            v$.value.$touch();
            return !v$.value.$invalid;
        },
        transformResponse: (list: any[]) =>
            list.map((item: any) => ({
                ...item,
                curAppNames: actions.convertToAppNames(item.curAppIds),
                nextAppNames: actions.convertToAppNames(item.nextAppIds)
            }))
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await itemService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchList();
                        }
                    }
                }
            });
        },
        routerByFeature: () => {
            const data = ItemSystemFeatures.find(feature => feature.value == selectedFeature.value);
            if (data) {
                router.push(data.route);
            }
        },
        convertToAppNames: (ids: number[]) => ids.map((id: number) => mapApps.value.get(id).name).join(', ')
    };

    return { selectedFeature, filter, v$, itemList, total, currentPage, fetchList, actions, appOptionWithAll };
}
