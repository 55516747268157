
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppError } from '@/components';
import { usePicwordMapAdd } from '@/modules/picword/map/model/add';

export default defineComponent({
    name: 'PicwordMapAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppError },
    setup() {
        const { model, v$, actions, iapOptions, fetchIapOptions, SelectableBooster } = usePicwordMapAdd();

        onMounted(async () => {
            await fetchIapOptions();
        });

        return { v$, model, actions, iapOptions, SelectableBooster };
    }
});
