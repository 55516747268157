import {
    convertPetRewardListFromStrings,
    convertPetRewardListToStrings,
    useMixins
} from '@/modules/picword/pet/model/mixins';
import { PICWORD_ROUTES } from '@/modules/picword/router';
import { picwordService } from '@/services/picword';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePicwordPetEdit() {
    const router = useRouter();
    const toast = useToast();

    const { v$, rewardV$, model, SelectableBooster } = useMixins();

    const fetch = async () => {
        const response = await picwordService.pet.get(model.id);
        if (response.code === 200) {
            const result = response.data;
            model.rewardList = convertPetRewardListFromStrings(
                result.experienceByLevel,
                result.creditReward,
                result.heartReward,
                result.boosterReward
            );

            Object.assign(model, result);
        } else {
            toast.error(response.data);
            router.push(PICWORD_ROUTES.PET.INDEX());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const reward = convertPetRewardListToStrings(model.rewardList);
            const response = await picwordService.pet.update(
                model.id,
                model.interactiveSeconds,
                model.gachaWeights,
                model.totalLevel,
                reward.experienceByLevels,
                reward.creditRewards,
                reward.heartRewards,
                reward.boosterRewards
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push(PICWORD_ROUTES.PET.INDEX());
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, rewardV$, fetch, actions, SelectableBooster };
}
