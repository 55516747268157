import { StarterPackRewardType, useStarterPackRewardMixins } from '@/modules/starter/pack/reward/model/mixins';
import { STARTER_PACK_ROUTER } from '@/modules/starter/pack/router';
import { starterPackService } from '@/services/starter';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { computed, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useStarterPackRewardAdd() {
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const starterPackId = route.params.starterPackId;
    const { fetchAllLinkableItems, linkableItems, getLinkableItem } = useStarterPackRewardMixins();
    const linkableItemOption = computed(() => {
        return linkableItems.value
            .filter(item => item.type === model.type)
            .map(item => ({ value: item.itemId, label: item.itemName }));
    });

    const model = reactive({
        type: null,
        data: null,
        quantity: null
    });

    const validations = {
        type: { required },
        data: { requiredIf: requiredIf(() => model.type !== StarterPackRewardType.NoAds.value) },
        quantity: { required }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await starterPackService.reward.add(starterPackId, model.type, model.data, model.quantity);

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push(STARTER_PACK_ROUTER.REWARD.INDEX(starterPackId));
        }
    };

    const quickCreate = async (forStarterPackId: any): Promise<boolean> => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await starterPackService.reward.add(forStarterPackId, model.type, model.data, model.quantity);
        return response.code === 200;
    };

    const changeType = (type: number) => {
        if (type === StarterPackRewardType.NoAds.value) {
            model.data = 0;
            model.quantity = 3; // 3 months no ads
        } else if (
            type === StarterPackRewardType.AvatarBackground.value ||
            type === StarterPackRewardType.AvatarBundle.value
        ) {
            model.quantity = 1;
        } else {
            model.data = null;
        }
    };

    watch(() => model.type, changeType);

    return { model, v$, fetchAllLinkableItems, getLinkableItem, linkableItemOption, create, quickCreate };
}
