
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker } from '@/components';
import { AppFileUpload, AppImageHolder } from '@/components';
import { useDuplicateItemEdit } from '@/modules/duplicate/item/model/edit';

export default defineComponent({
    name: 'DuplicateItemEdit',
    components: {
        AppFormRow,
        AppInput,
        AppCard,
        AppButton,
        AppSelect,
        AppDateTimePicker,
        AppFileUpload,
        AppImageHolder
    },
    setup() {
        const { model, v$, fetch, betOptions, timeUnits, actions } = useDuplicateItemEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, betOptions, timeUnits };
    }
});
