import { useIAPList } from '@/modules/iap/model/list';
import { useStarterPackRewardAdd } from '@/modules/starter/pack/reward/model/add';
import { STARTER_PACK_ROUTER } from '@/modules/starter/pack/router';
import { useVModal } from '@/plugins/modal/v-modal';
import { starterPackService } from '@/services/starter';
import useVuelidate from '@vuelidate/core';
import { decimal, minValue, required } from '@vuelidate/validators';
import { reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useStarterPackMixins() {
    const route = useRoute();
    const router = useRouter();

    const { fetchIapOptions, iapOptions } = useIAPList();

    const model = reactive({
        id: route.params?.id ?? null,
        name: null,
        selectedIAP: null,
        packageId: null,
        price: null,
        amount: 0,
        displayRate: 0,
        gameRequired: 0,
        timeUnit: null,
        durationSeconds: null,
        image: null,
        imageFile: null,
        imageStatus: null,
        isEnabled: false,
        canEnable: false,
        canDisable: false,
        canEdit: false,
        canDelete: false,
        rewards: []
    });

    const validations = {
        name: { required },
        price: { required, decimal, minValue: minValue(0) },
        amount: { required, decimal, minValue: minValue(0) },
        packageId: { required },
        displayRate: { required, minValue: minValue(1) },
        gameRequired: { required, minValue: minValue(1) },
        durationSeconds: { required, minValue: minValue(1) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchIapOptions();

        if (model.id) {
            const response = await starterPackService.get(model.id);
            if (response.code === 200) {
                Object.assign(model, response.data);
            } else {
                router.push(STARTER_PACK_ROUTER.INDEX());
            }
        }
    };

    const changeIAP = (iapId: number) => {
        iapOptions.value.forEach(item => {
            if (item.value == iapId) {
                model.packageId = item.data.packageId;
                model.price = item.data.price;
                model.amount = item.data.credit;
            }
        });
    };

    watch(() => model.selectedIAP, changeIAP);

    return { model, v$, fetch, iapOptions };
}

export function useStarterPackRewardCreationModal() {
    const vModal = useVModal();

    const MODAL_NAME = 'create-starter-pack-reward-modal';
    const { model, v$, fetchAllLinkableItems, quickCreate, linkableItemOption } = useStarterPackRewardAdd();

    const beforeFetch = async () => await fetchAllLinkableItems();

    const rewardActions = {
        quickCreate: async (forStarterPackId: any) => {
            const result = await quickCreate(forStarterPackId);
            if (result) {
                vModal.close({ name: MODAL_NAME });
            }
            return result;
        },
        openModal: async () => {
            v$.value.$reset();
            model.type = null;
            model.data = null;
            model.quantity = null;
            vModal.open({ name: MODAL_NAME });
        },
        closeModal: () => {
            vModal.close({ name: MODAL_NAME });
        }
    };

    const rewardCreationModal = { MODAL_NAME, v$, model, beforeFetch, linkableItemOption, rewardActions };

    return rewardCreationModal;
}
