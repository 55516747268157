export const DuplicateItemOptions = {
    Type: [
        { value: 1, label: 'SingleJoin', background: 'info' },
        { value: 2, label: 'RecurringJoin', background: 'info' }
    ],
    Status: [
        { value: 0, label: 'None', background: 'secondary' },
        { value: 1, label: 'Enabled', background: 'success' },
        { value: 2, label: 'Published', background: 'info' },
        { value: 3, label: 'Unpublished', background: 'warning' },
        { value: 4, label: 'Disabled', background: 'secondary' }
    ]
};
