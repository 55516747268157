
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { usePicwordPetEdit } from '@/modules/picword/pet/model/edit';

export default defineComponent({
    name: 'PicwordPetAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, rewardV$, fetch, actions, SelectableBooster } = usePicwordPetEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, rewardV$, model, actions, SelectableBooster };
    }
});
