export const ScratchTicketType = {
    Silver: { value: 1, label: 'Silver' },
    Golden: { value: 2, label: 'Golden' }
};

export const ScratchTicketOptions = {
    Type: Object.entries(ScratchTicketType).map(([, data]) => data),
    Status: [
        { value: 0, label: 'None', background: 'secondary' },
        { value: 1, label: 'Started', background: 'success' },
        { value: 2, label: 'Stopped', background: 'warning' }
    ]
};
