import { useArenaItemMixins } from '@/modules/arena/item/model/mixins';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { arenaService } from '@/services/arena';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';

export function useArenaList() {
    const { fetchAllBets, betMap } = useGameBetList();

    const { fetchList, itemList, total, currentPage } = useFetchList({
        request: arenaService.item.list,
        pageFromStore: pageFromStore(STORE_MODULES.ArenaItem.name),
        beforeFetch: async () => await fetchAllBets(),
        transformResponse: (list: any[]) =>
            list.map(item => ({ ...item, betName: betMap.value.get(item.betId)?.label ?? '' }))
    });

    const { archive, disable, enable, remove } = useArenaItemMixins().arenaActionMixins;

    const actions = {
        archive: (id: any) => archive(id, fetchList),
        disable: (id: any) => disable(id, fetchList),
        enable: (id: any) => enable(id, fetchList),
        remove: (id: any) => remove(id, fetchList)
    };

    return { itemList, total, fetchList, currentPage, actions };
}
