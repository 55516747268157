
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker } from '@/components';
import { AppFileUpload, AppImageHolder } from '@/components';
import { useDuplicateItemAdd } from '@/modules/duplicate/item/model/add';
import { DuplicateItemOptions } from '@/modules/duplicate/item/model/mixins';

export default defineComponent({
    name: 'DuplicateItemAdd',
    components: {
        AppFormRow,
        AppInput,
        AppCard,
        AppButton,
        AppSelect,
        AppDateTimePicker,
        AppFileUpload,
        AppImageHolder
    },
    setup() {
        const { model, v$, fetchAllBets, betOptions, timeUnits, actions } = useDuplicateItemAdd();

        onMounted(async () => {
            await fetchAllBets();
        });

        return { v$, model, actions, betOptions, timeUnits, DuplicateItemOptions };
    }
});
