
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker, AppFileUpload } from '@/components';
import { usePromotionPlanAdd } from '@/modules/promotion/plan/model/add';
import { Options } from '@/modules/promotion/plan/model/mixins';

export default defineComponent({
    name: 'PromotionPlanAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker, AppFileUpload },
    setup() {
        const { model, v$, beforeFetch, linkableItemOption, timeUnits, actions } = usePromotionPlanAdd();

        onMounted(async () => {
            await beforeFetch();
        });

        return { v$, model, actions, linkableItemOption, Options, timeUnits };
    }
});
