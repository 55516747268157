import http from '@/utils/axios';

export const starterPackService = {
    list() {
        return http.doGet('admin/starter/pack');
    },

    get(itemId: any) {
        return http.doGet('admin/starter/pack/' + itemId);
    },

    create(
        name: any,
        packageId: any,
        price: any,
        amount: any,
        displayRate: any,
        gameRequired: any,
        durationSeconds: any,
        image: any
    ) {
        return http.doPost(
            'admin/starter/pack',
            { name, packageId, price, amount, displayRate, gameRequired, durationSeconds },
            { image }
        );
    },

    update(itemId: any, name: any, amount: any, displayRate: any, gameRequired: any, durationSeconds: any, image: any) {
        return http.doPut(
            'admin/starter/pack/' + itemId,
            { name, amount, displayRate, gameRequired, durationSeconds },
            { image }
        );
    },

    remove(itemId: any) {
        return http.doDelete('admin/starter/pack/' + itemId);
    },

    enable(itemId: any) {
        return http.doPut('admin/starter/pack/' + itemId + '/enable');
    },

    disable(itemId: any) {
        return http.doPut('admin/starter/pack/' + itemId + '/disable');
    },

    reward: {
        list({ starterPackId }: any) {
            return http.doGet('admin/starter/pack/reward', { starterPackId });
        },

        getLinkableRewards() {
            return http.doGet('admin/starter/pack/reward/linkable');
        },

        remove(starterPackId: any, type: any, data: any) {
            return http.doDelete('admin/starter/pack/reward', { starterPackId, type, data });
        },

        add(starterPackId: any, type: any, data: any, quantity: any) {
            return http.doPut('admin/starter/pack/reward', { starterPackId, type, data, quantity });
        }
    }
};
