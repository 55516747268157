
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { usePromotionBoosterList } from '@/modules/promotion/booster/model/list';
import { RouterLink } from 'vue-router';
import { Routes } from '@/modules/promotion/booster/model/mixins';

export default defineComponent({
    name: 'PromotionBoosterList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, fetchList, actions } = usePromotionBoosterList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                {
                    label: 'Linked Promotion',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.linkedItemName,
                        props: { to: row.linkedItemLink }
                    })
                },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        onclick: () => Routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        onclick: () => Routes.detail(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchList();
        });

        return { table, fetchList, Routes };
    }
});
