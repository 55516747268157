import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { promotionService } from '@/services/promotion';
import { reactive } from 'vue';
import { getPromotionPlanLink } from './mixins';
import { useRoute, useRouter } from 'vue-router';

export function usePromotionCycleDetail() {
    const route = useRoute();
    const router = useRouter();

    const model = reactive({
        id: route.params?.id,
        promotionPlanId: null,
        promotionPlanName: '',
        status: null,
        startTime: null,
        endTime: null,

        get promotionPlanLink() {
            return getPromotionPlanLink(this.promotionPlanId);
        }
    });

    const fetch = async () => {
        const response = await promotionService.cycle.get(model.id);
        if (response.code === 200) {
            const promotionPlanResponse = await promotionService.plan.get(response.data.promotionPlanId);
            if (promotionPlanResponse.code === 200) {
                model.promotionPlanName = promotionPlanResponse.data.name;
            }

            response.startTime = new Date(response.data.startTime);
            response.endTime = new Date(response.data.endTime);
            Object.assign(model, response.data);
        } else {
            router.push(PROMOTION_ROUTER.CYCLE.INDEX());
        }
    };

    return { model, fetch };
}
