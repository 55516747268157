import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_card, {
      label: "Function",
      classes: "pb-0"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_app_button, {
            name: "Search",
            icon: "fa-search",
            theme: "info",
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.fetchList(1)), ["prevent"]))
          }),
          _createVNode(_component_app_button, {
            name: "Add new text",
            icon: "fa-plus",
            theme: "info",
            href: _ctx.TRANSLATION_ROUTER.ADD()
          }, null, 8, ["href"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_app_form_row, {
          label: "Locale",
          class: "col-md-6 mx-auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.localeSupportedOptions,
              modelValue: _ctx.filter.locale,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.locale) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchList(1)))
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        }),
        (_ctx.filter.locale)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 0,
              label: "Filter By",
              class: "col-md-6 mx-auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_select, {
                  options: _ctx.TranslationTypes,
                  modelValue: _ctx.filter.filterType,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.filterType) = $event)),
                  modelModifiers: { number: true }
                }, null, 8, ["options", "modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.filter.locale)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 1,
              label: "Criteria",
              class: "col-md-6 mx-auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  modelValue: _ctx.filter.criteria,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.criteria) = $event)),
                  validations: _ctx.v$.criteria
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          "is-slot-mode": true,
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          page: _ctx.table.page,
          actions: _ctx.table.actions,
          onRefreshData: _ctx.fetchList
        }, null, 8, ["columns", "rows", "total", "page", "actions", "onRefreshData"])
      ]),
      _: 1
    })
  ], 64))
}