
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppFileUpload, AppButton, AppSelect } from '@/components';
import { useItemAdd } from '@/modules/item/system/model/add';
import { ItemSystemFeatures } from '@/modules/item/system/model/mixins';

export default defineComponent({
    name: 'SystemItemAdd',
    components: { AppFormRow, AppInput, AppCard, AppFileUpload, AppButton, AppSelect },
    setup() {
        const { model, v$, actions } = useItemAdd();

        return { v$, model, actions, ItemSystemFeatures };
    }
});
