
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useBoosterItemList } from '@/modules/booster/model/list';
import { BOOSTER_ROUTES } from '@/modules/booster/router';
import { Options } from '@/modules/booster/model/mixins';
import { renderOption } from '@/utils/helpers';

export default defineComponent({
    name: 'BoosterItemList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, fetchList, actions } = useBoosterItemList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                { label: 'Type', display: row => renderOption(Options.ItemType, row.type) },
                { label: 'Costs', field: 'costs' },
                {
                    label: 'Thumbnail',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.thumbnail, status: row.thumbnailStatus }
                    })
                },
                { label: 'Actions', field: 'type' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        href: BOOSTER_ROUTES.EDIT(row.type)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.type)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, BOOSTER_ROUTES };
    }
});
