
import { defineComponent, onMounted } from 'vue';
import { AppCard } from '@/components';
import { usePromotionCycleDetail } from '@/modules/promotion/cycle/model/detail';
import { formatDate, renderOption } from '@/utils/helpers';
import { statusOption } from '@/modules/promotion/cycle/model/mixins';

export default defineComponent({
    name: 'PromotionCycleDetail',
    components: { AppCard },
    setup() {
        const { model, fetch } = usePromotionCycleDetail();

        onMounted(async () => {
            await fetch();
        });

        return { model, statusOption, formatDate, renderOption };
    }
});
