
import {
    AppButton,
    AppCard,
    AppDateTimePicker,
    AppFileUpload,
    AppImageHolder,
    AppFormRow,
    AppInput,
    AppSelect
} from '@/components';
import { useNewsAdd } from '@/modules/news/model/add';
import { NewsOptions } from '@/modules/news/model/mixins';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'CreateNews',
    components: {
        AppCard,
        AppFormRow,
        AppButton,
        AppDateTimePicker,
        AppFileUpload,
        AppImageHolder,
        AppSelect,
        AppInput
    },
    setup() {
        const { model, v$, actions, appOptions, fetchItem } = useNewsAdd();

        onMounted(async () => {
            await fetchItem();
        });

        return { model, v$, NewsOptions, appOptions, actions };
    }
});
