import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function usePromotionPlanDetail() {
    const dialog = useVDialog();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const { fetchLinkableItems, getItemName, getItemLink } = useMixins();

    const model = reactive({
        id: route.params?.id,
        name: null,
        status: null,
        timeUnit: 0,
        timeDuration: 0,
        publishTime: null,
        unpublishTime: null,
        itemId: null,
        type: null,
        image: null,
        imageStatus: null,
        canEdit: false,
        canEnable: false,
        canDisable: false,

        get itemName() {
            return getItemName(this.itemId, this.type);
        },
        get itemLink() {
            return getItemLink(this.itemId, this.type);
        }
    });

    const beforeFetch = async () => {
        await fetchLinkableItems();
    };

    const fetch = async () => {
        const response = await promotionService.plan.get(model.id);
        if (response.code === 200) {
            response.data.publishTime = new Date(response.data.publishTime);
            response.data.unpublishTime = response.data.unpublishTime ? new Date(response.data.unpublishTime) : null;

            Object.assign(model, response.data);
        } else {
            router.push(PROMOTION_ROUTER.PLAN.INDEX());
        }
    };

    const actions = {
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.plan.disable(model.id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        enable: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.plan.enable(model.id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, beforeFetch, fetch, actions };
}
