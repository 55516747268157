import { creditService } from '@/services/credit';
import { rubyService } from '@/services/ruby';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { TransactionType } from './mixins';

export function useAccountTransaction() {
    const route = useRoute();

    const model = reactive({
        accountId: Number(route.params.accountId),
        type: route.query?.type ? Number(route.query.type) : null,

        get displayInfo() {
            switch (this.type) {
                case TransactionType.Credit:
                    return { label: 'Credit', field: 'credit', title: 'Credit' };
                case TransactionType.Payment:
                    return { label: 'Credit', field: 'credit', title: 'Payment' };
                case TransactionType.Ruby:
                    return { label: 'Ruby', field: 'ruby', title: 'Ruby' };
                default:
                    return { label: '', field: '', title: '' };
            }
        }
    });

    const request = (params: any) => {
        if (model.type === TransactionType.Credit) {
            return creditService.transaction.list(params);
        } else if (model.type === TransactionType.Payment) {
            return creditService.transaction.listPayment(params);
        } else if (model.type === TransactionType.Ruby) {
            return rubyService.transaction.list(params);
        }

        return Promise.resolve({ code: 0, data: null }); // default promise
    };

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: request,
        params: { accountId: model.accountId },
        pageFromStore: pageFromStore(STORE_MODULES.AccountTransaction.name),
        preCheck: () => {
            return model.type != null;
        }
    });

    return { model, itemList, total, currentPage, fetchList };
}
