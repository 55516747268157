
import { defineComponent, onMounted } from 'vue';
import {
    AppFormRow,
    AppInput,
    AppCard,
    AppButton,
    AppSelect,
    AppDateTimePicker,
    AppFileUpload,
    AppImageHolder
} from '@/components';
import { usePromotionPlanEdit } from '@/modules/promotion/plan/model/edit';
import { Options } from '@/modules/promotion/plan/model/mixins';
import { renderOption } from '@/utils/helpers';

export default defineComponent({
    name: 'PromotionPlanEdit',
    components: {
        AppFormRow,
        AppInput,
        AppCard,
        AppButton,
        AppSelect,
        AppDateTimePicker,
        AppFileUpload,
        AppImageHolder
    },
    setup() {
        const { model, v$, beforeFetch, fetch, linkableItemOption, timeUnits, actions } = usePromotionPlanEdit();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { v$, model, actions, timeUnits, linkableItemOption, Options, renderOption };
    }
});
