
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton } from '@/components';
import { usePromotionPrototypeAdd } from '@/modules/promotion/prototype/model/add';

export default defineComponent({
    name: 'PrototypeAdd',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppButton },

    setup() {
        const { model, v$, fetch, validateModel, beforeFetch, rfmGroupOptions, iapOptions, actions } =
            usePromotionPrototypeAdd();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { v$, model, validateModel, fetch, rfmGroupOptions, iapOptions, actions };
    }
});
