import http from '@/utils/axios';

export const activityService = {
    account: {
        list({ offset, limit, type, criteria }: any) {
            return http.doGet('admin/activity/login/account', { offset, limit, type, criteria });
        },

        getLastLogin(accountId: any) {
            return http.doGet('admin/activity/login/account/' + accountId);
        }
    },

    app: {
        list({ offset, limit, accountId, feature, action }: any) {
            return http.doGet('admin/activity', { offset, limit, accountId, feature, action });
        },

        get(id: any) {
            return http.doGet('admin/activity/' + id);
        }
    }
};
