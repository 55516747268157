import { useVDialog } from '@/plugins/dialog/v-dialog';
import { progressService } from '@/services/progress';
import { useFetchList } from '@/utils/useFetchList';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useProgressLevelList() {
    const dialog = useVDialog();
    const route = useRoute();
    const toast = useToast();

    const progressId = route.params.progressId;

    const { itemList, total, fetchList } = useFetchList({
        request: progressService.level.list,
        params: { progressId }
    });

    const actions = {
        remove: (level: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await progressService.level.remove(progressId, level);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { progressId, itemList, total, fetchList, actions };
}
