
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import { useGameBetEdit } from '@/modules/game/bet/model/edit';
import { formatDate, renderOption } from '@/utils/helpers';
import { GAME_ROUTER } from '@/modules/game/router';
import { GameBetOptions } from '@/modules/game/bet/model/mixins';

export default defineComponent({
    name: 'GameBetDetail',
    components: { AppCard, AppButton },
    setup() {
        const { model, fetch, actions } = useGameBetEdit();

        onMounted(async () => {
            await fetch();
        });

        return { model, formatDate, actions, GAME_ROUTER, GameBetOptions, renderOption };
    }
});
