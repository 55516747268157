import { useVDialog } from '@/plugins/dialog/v-dialog';
import { surpriseService } from '@/services/surprise';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useSurpriseItemList() {
    const dialog = useVDialog();
    const route = useRoute();
    const toast = useToast();

    const categoryId = route.params.categoryId;

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: surpriseService.item.list,
        pageFromStore: pageFromStore(STORE_MODULES.SurpriseItem.name),
        params: { categoryId }
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await surpriseService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList(currentPage.value);
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, fetchList, actions, categoryId };
}
