import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_card, {
      label: "Function",
      classes: "pb-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_app_form_row, {
          label: "Locale",
          class: "col-md-6 mx-auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.localeSupportedOptions,
              modelValue: _ctx.filter.locale,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.locale) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchList(1)))
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          "is-slot-mode": true,
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          page: _ctx.table.page,
          actions: _ctx.table.actions,
          onRefreshData: _ctx.fetchList
        }, null, 8, ["columns", "rows", "total", "page", "actions", "onRefreshData"])
      ]),
      _: 1
    })
  ], 64))
}