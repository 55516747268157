import { ITEM_ROUTER } from '@/modules/item/router';
import { FEATURES } from '@/modules/promotion/code/model/mixins';
import { QUEST_ROUTER } from '@/modules/quest/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { questService } from '@/services/quest';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';

export function useQuestBoostList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList({ request: questService.boost.list });

    const actions = {
        disable: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.boost.disable(typeId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        enable: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.boost.enable(typeId);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        remove: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.boost.remove(typeId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => QUEST_ROUTER.BOOST.ADD(),
        edit: (id: any) => QUEST_ROUTER.BOOST.EDIT(id),
        detail: (id: any) => QUEST_ROUTER.BOOST.DETAIL(id),
        translate: (id: any) => QUEST_ROUTER.BOOST.TRANSLATE(id),
        specialShop: (id: any) => ITEM_ROUTER.SYSTEM.SPECIAL_SHOP(FEATURES.QUEST_BOOST, id)
    };

    return { itemList, total, fetchList, actions, routes };
}
