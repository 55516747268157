
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useAvatarDefaultList } from '@/modules/avatar/default/model/list';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { renderBoolean, renderOption } from '@/utils/helpers';
import { AvatarGender } from '@/modules/avatar/gender/model/gender';

export default defineComponent({
    name: 'SysItem',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppButton },
    setup() {
        const { filter, itemList, total, currentPage, fetchList, actions } = useAvatarDefaultList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                { label: 'Type', display: (row: any) => renderOption(AvatarDefaultType, row.type) },
                { label: 'Gender', display: (row: any) => renderOption(AvatarGender, row.gender) },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Is published', display: (row: any) => renderBoolean(row.isPublished) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: AVATAR_ROUTER.DEFAULT.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { filter, table, fetchList, actions, AvatarDefaultType, AVATAR_ROUTER };
    }
});

const AvatarDefaultType = [
    { label: 'All', value: '', background: 'info' },
    { label: 'Bot', value: '1', background: 'info' },
    { label: 'Admin', value: '2', background: 'info' },
    { label: 'DefaultAvatar', value: '3', background: 'info' },
    { label: 'FakePlayer', value: '4', background: 'info' }
];
