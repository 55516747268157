import { useVDialog } from '@/plugins/dialog/v-dialog';
import { boosterService } from '@/services/booster';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';

export function useBoosterItemList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: boosterService.list,
        pageFromStore: pageFromStore(STORE_MODULES.Booster.name)
    });

    const actions = {
        remove: (type: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await boosterService.remove(type);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, fetchList, actions };
}
