import { useIAPList } from '@/modules/iap/model/list';
import { SCRATCH_ROUTER } from '@/modules/scratch/router';
import { ScratchTicketType } from '@/modules/scratch/ticket/model/mixins';
import { scratchTicketService } from '@/services/scratch';
import { timeUnits } from '@/utils/helpers';
import { validateDate, validateDateAfter, validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { minValue, numeric, required, requiredIf } from '@vuelidate/validators';
import { reactive, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useTicketAdd() {
    const router = useRouter();
    const toast = useToast();

    const { iapOptions, fetchIapOptions } = useIAPList();

    const model = reactive({
        id: null,
        freeLimit: null,
        type: null,
        cost: null,
        price: null,
        packageId: null,
        adsView: null,
        quantity: null,
        baseValue: 0,
        payoutRate: null,
        adsPayoutRate: null,
        displayRate: null,
        timeUnit: null,
        timeDuration: null,
        startTime: new Date(),
        endTime: null,
        iapId: null
    });

    const validations = {
        type: { required },
        cost: { required, numeric, minValue: minValue(0) },
        price: { required, numeric, minValue: minValue(0) },
        baseValue: { required, numeric, minValue: minValue(0) },
        timeUnit: { required, minValue: minValue(0) },
        timeDuration: { required, numeric, minValue: minValue(0) },
        freeLimit: { required, numeric, minValue: minValue(0) },
        adsView: { required, numeric, minValue: minValue(0) },
        quantity: { required, numeric, minValue: minValue(0) },
        startTime: { validateDate: validateDate() },
        endTime: { validateDateAfter: validateDateAfter('startTime') },
        payoutRate: {
            required,
            validateFormat: validateFormat(
                /^\d+(.\d+)?:\d+(.\d+)?(,\d+(.\d+)?:\d+(.\d+)?)*$/g,
                `Please enter in the correct format: X1:Y1,X2:Y2... Ex: 0.3:10,4:100`
            )
        },
        adsPayoutRate: {
            requiredIf: requiredIf(() => model.type == 1),
            validateFormat: validateFormat(
                /^\d+(.\d+)?:\d+(.\d+)?(,\d+(.\d+)?:\d+(.\d+)?)*$/g,
                `Please enter in the correct format: X1:Y1,X2:Y2... Ex: 0.3:10,4:100`
            )
        },
        displayRate: {
            required,
            validateFormat: validateFormat(
                /^\d+(,\d+)*$/g,
                `Please enter in the correct format: Ex: 2,3,5 [in integer numbers]`
            )
        }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await scratchTicketService.create(
                model.type,
                model.packageId,
                model.price,
                model.cost,
                model.quantity,
                model.baseValue,
                model.payoutRate,
                model.adsPayoutRate,
                model.adsView,
                model.freeLimit,
                model.displayRate,
                model.timeUnit,
                model.timeDuration,
                model.startTime.getTime(),
                model?.endTime ? model.endTime.getTime() : null
            );
            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(SCRATCH_ROUTER.TICKET.INDEX());
            }
        }
    };

    watch(
        () => model.type,
        () => {
            v$.value.$reset();

            if (model.type == ScratchTicketType.Silver.value) {
                model.price = 0;
                model.packageId = '';
                model.quantity = 0;
            } else if (model.type == ScratchTicketType.Golden.value) {
                model.cost = 0;
                model.adsView = 0;
                model.freeLimit = 0;
                model.timeUnit = 0;
                model.timeDuration = 0;
            }
        }
    );

    watch(
        () => model.iapId,
        value => {
            iapOptions.value.forEach(item => {
                if (item.value == value) {
                    model.packageId = item.data.packageId;
                    model.price = item.data.price;
                }
            });
        }
    );

    return { model, v$, timeUnits, fetchIapOptions, iapOptions, actions };
}
