import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Picword Setting"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Total Map" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.totalMap,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.totalMap) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.totalMap
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Max Level" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.maxLevel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.maxLevel) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.maxLevel
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Extraword Capacity" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.extrawordCapacity,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.extrawordCapacity) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.extrawordCapacity
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Heart To Pet" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.heartToPet,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.heartToPet) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.heartToPet
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Interactive Seconds" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.interactiveSeconds,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.interactiveSeconds) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.interactiveSeconds
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Interactive Cost" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.interactiveCost,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.interactiveCost) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.interactiveCost
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Extraword Goal To Credit" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.extrawordGoalToCredit,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.extrawordGoalToCredit) = $event)),
              validations: _ctx.v$.extrawordGoalToCredit,
              placeholder: "Extraword1:Credit1;Extraword2:Credit2... Ex: 10:100;20:50;100:10"
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Update",
              icon: "fa-save",
              theme: "info",
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["onClick"]),
            (_ctx.model.canDelete)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  name: "Remove",
                  icon: "fa-trash-alt",
                  theme: "danger",
                  onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}