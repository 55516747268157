import { useVDialog } from '@/plugins/dialog/v-dialog';
import { applicationService } from '@/services/applications';
import { chatService } from '@/services/chat';
import { useToast } from 'vue-toastification';

export function useAccountAppMixins() {
    const toast = useToast();
    const dialog = useVDialog();

    const actionMixins = {
        deletee: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to delete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.delete(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        undelete: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to undelete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.undelete(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        ban: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.ban(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        unban: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.unban(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        mute: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to mute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.mute(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        unmute: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to unmute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unmute(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        banChat: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.ban(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        unbanChat: (id: any, onSuccess: () => void | Promise<void> = () => {}) => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unban(id);
                        if (response.code === 200) {
                            toast.success('Successfully');
                            await onSuccess();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { actionMixins };
}

export enum TransactionType {
    Credit = 0,
    Payment = 1,
    Ruby = 2
}

export const ActivityFilterType = {
    AccountId: { value: 0, label: 'Account Id' },
    IpAddress: { value: 1, label: 'Ip Address' },
    DeviceId: { value: 2, label: 'Device Id' }
};

export const AccountFilterType = {
    All: { value: 0, label: 'No filter' },
    Name: { value: 1, label: 'Name' },
    Email: { value: 2, label: 'Email' },
    FacebookID: { value: 3, label: 'Facebook Id' },
    AccountID: { value: 4, label: 'Account Id' },
    CreditBuy: { value: 5, label: 'Bought credit' }
};

export const AccountAppStore = {
    Apple: { value: 0, label: 'Apple' },
    Google: { value: 1, label: 'Google' },
    Facebook: { value: 3, label: 'Facebook' },
    Website: { value: 4, label: 'Website' }
};

export const AccountAppStatus = {
    None: { value: 0, label: '', background: 'success' },
    Banned: { value: 1, label: 'Banned', background: 'warning' },
    BanChat: { value: 2, label: 'Banned-chat', background: 'warning' },
    Mute: { value: 3, label: 'Muted', background: 'warning' },
    Deleting: { value: 4, label: 'Deleting', background: 'danger' }
};

export const AccountAppOptions = {
    ActivityFilterType: Object.entries(ActivityFilterType).map(([, data]) => data),
    AccountFilterType: Object.entries(AccountFilterType).map(([, data]) => data),
    Store: Object.entries(AccountAppStore).map(([, data]) => data),
    Status: Object.entries(AccountAppStatus).map(([, data]) => data)
};
