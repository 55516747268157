import { HOME_PAGE_ROUTER } from '@/modules/main/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { picwordService } from '@/services/picword';
import { validateFormat } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePicwordSetting() {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();

    const model = reactive({
        totalMap: null,
        maxLevel: null,
        extrawordCapacity: null,
        extrawordGoalToCredit: '',
        heartToPet: null,
        interactiveSeconds: null,
        interactiveCost: null,
        canDelete: false
    });

    const validations = {
        totalMap: { required, numeric, min: minValue(1) },
        maxLevel: { required, numeric, min: minValue(1) },
        interactiveSeconds: { required, numeric, min: minValue(1) },
        interactiveCost: { required, numeric, min: minValue(1) },
        extrawordCapacity: { required, numeric, min: minValue(1) },
        heartToPet: { required, numeric, min: minValue(1) },
        extrawordGoalToCredit: { required, format: validateFormat(/^\d+:\d+(;\d+:\d+)*$/) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await picwordService.setting.get();
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push(HOME_PAGE_ROUTER());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await picwordService.setting.update(
                model.totalMap,
                model.maxLevel,
                model.extrawordCapacity,
                model.heartToPet,
                model.interactiveSeconds,
                model.interactiveCost,
                model.extrawordGoalToCredit
            );

            if (response.code === 200) {
                toast.success('Update successfully');
            }

            fetch();
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await picwordService.setting.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                        }

                        fetch();
                    }
                }
            });
        }
    };

    return { v$, model, fetch, actions };
}
