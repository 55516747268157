import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "card-footer" }
const _hoisted_4 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_image_holder = _resolveComponent("app-image-holder")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Update Shop Item"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Type" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.renderOption(_ctx.Options.ShopItemType, _ctx.model.type)
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "IAP Item" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.iapOptions,
              modelValue: _ctx.model.selectedIAP,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.selectedIAP) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.packageId
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Package ID" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.packageId,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.packageId) = $event)),
              validations: _ctx.v$.packageId
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Price" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.price,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.price) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.price
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        (_ctx.model.type === _ctx.ShopItemType.Credit.value)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 0,
              label: "Credit"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  type: "number",
                  modelValue: _ctx.model.credit,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.credit) = $event)),
                  modelModifiers: { number: true },
                  validations: _ctx.v$.credit
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_app_form_row, { label: "Is Published" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_switch, {
              modelValue: _ctx.model.isPublished,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.isPublished) = $event)),
              validations: _ctx.v$.isPublished
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Is Best Seller" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_switch, {
              modelValue: _ctx.model.isBestseller,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.isBestseller) = $event)),
              validations: _ctx.v$.isBestseller
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Image" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.image
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.imageFile,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.imageFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Thumbnail" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.thumbnail
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.thumbnailFile,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.thumbnailFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_button, {
              name: "Update",
              icon: "fa-save",
              theme: "info",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"]),
            _createVNode(_component_app_button, {
              name: "Delete",
              icon: "fa-trash-alt",
              theme: "danger",
              onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
            }, null, 8, ["onClick"]),
            _createVNode(_component_app_button, {
              name: "Clone",
              icon: "fa-copy",
              theme: "success",
              href: _ctx.SHOP_ROUTER.CLONE(_ctx.model.id)
            }, null, 8, ["href"]),
            _createVNode(_component_app_button, {
              name: "Texts",
              icon: "fa-language",
              theme: "info",
              href: _ctx.SHOP_ROUTER.TRANSLATE(_ctx.model.id)
            }, null, 8, ["href"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}