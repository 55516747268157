import http from '@/utils/axios';

const item = {
    list() {
        return http.doGet('/admin/shop/item');
    },
    get(id: any) {
        return http.doGet('/admin/shop/item/' + id);
    },
    create(
        cloneId: any,
        type: any,
        name: any,
        price: any,
        credit: any,
        isPublished: any,
        packageId: any,
        isBestseller: any,
        image: any,
        thumbnail: any
    ) {
        return http.doPost(
            '/admin/shop/item',
            {
                cloneId: cloneId,
                type: type,
                name: name,
                price: price,
                credit: credit,
                isPublished: isPublished,
                packageId: packageId,
                isBestseller: isBestseller
            },
            {
                image: image,
                thumbnail: thumbnail
            }
        );
    },
    update(
        id: any,
        name: any,
        price: any,
        credit: any,
        isPublished: any,
        packageId: any,
        isBestseller: any,
        image: any,
        thumbnail: any
    ) {
        return http.doPost(
            '/admin/shop/item/' + id,
            {
                name: name,
                price: price,
                credit: credit,
                isPublished: isPublished,
                packageId: packageId,
                isBestseller: isBestseller
            },
            {
                image: image,
                thumbnail: thumbnail
            }
        );
    },
    remove(id: any) {
        return http.doDelete('/admin/shop/item/' + id);
    }
};

const text = {
    get(itemId: any, locale: any) {
        return http.doGet('admin/shop/item/' + itemId + '/text/' + locale);
    },
    update(itemId: any, locale: any, title: any, description: any) {
        return http.doPut('admin/shop/item/' + itemId + '/text/' + locale, {
            title: title,
            description: description
        });
    },
    remove(itemId: any, locale: any) {
        return http.doDelete('admin/shop/item/' + itemId + '/text/' + locale);
    }
};

export const shopService = {
    item,
    text
};
