import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { duplicateService } from '@/services/duplicate';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useDuplicateCycleDetail() {
    const route = useRoute();
    const router = useRouter();

    const cycle = ref(null);
    const { fetchAllBets, betMap } = useGameBetList();

    const fetch = async () => {
        await fetchAllBets();

        const cycleResponse = await duplicateService.cycle.get(route.params.cycleId);
        if (cycleResponse.code !== 200) {
            router.push(DUPLICATE_ROUTER.CYCLE.INDEX());
            return;
        }

        const result = cycleResponse.data;
        const duplicateResponse = await duplicateService.item.get(result.duplicateItemId);
        if (duplicateResponse.code !== 200) {
            router.push(DUPLICATE_ROUTER.CYCLE.INDEX());
            return;
        }

        const duplicate = duplicateResponse.data;
        result.itemName = duplicate.name;
        result.itemGame = duplicate.game;
        result.betId = duplicate.betId;
        result.betName = betMap.value.get(duplicate.betId)?.label;

        cycle.value = result;
    };

    return { cycle, fetch };
}
