import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { usePromotionPrototypeList } from './list';

export function usePromotionPrototypeDetail() {
    const route = useRoute();

    const { prototypeAll, fetchPrototypeAll } = usePromotionPrototypeList();

    const model = reactive({
        id: route.params.id,
        name: null,
        personas: []
    });

    const fetch = async () => {
        await fetchPrototypeAll();

        const prototypeId = Number(model.id);
        const prototype = prototypeAll.value.find(item => item.id === prototypeId);
        if (prototype) {
            model.name = prototype.name;
            model.personas = prototype.personas;
        }
    };

    return { model, fetch };
}
