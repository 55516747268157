
import { AppButton, AppCard, AppFormRow, AppInput, AppSelect } from '@/components';
import { useStarterPackRewardAdd } from '@/modules/starter/pack/reward/model/add';
import { StarterPackRewardOptions, StarterPackRewardType } from '@/modules/starter/pack/reward/model/mixins';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'StarterPackRewardAdd',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton },
    setup() {
        const { model, v$, fetchAllLinkableItems, create, linkableItemOption } = useStarterPackRewardAdd();

        onMounted(async () => {
            await fetchAllLinkableItems();
        });

        return { v$, model, create, linkableItemOption, StarterPackRewardOptions, StarterPackRewardType };
    }
});
