import { useStarterPackMixins } from '@/modules/starter/pack/model/mixins';
import { STARTER_PACK_ROUTER } from '@/modules/starter/pack/router';
import { starterPackService } from '@/services/starter';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useStarterPackAdd() {
    const toast = useToast();
    const router = useRouter();

    const { model, v$, fetch, iapOptions } = useStarterPackMixins();

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await starterPackService.create(
                model.name,
                model.packageId,
                model.price,
                model.amount,
                model.displayRate,
                model.gameRequired,
                model.durationSeconds,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(STARTER_PACK_ROUTER.INDEX());
            }
        }
    };

    return { model, v$, fetch, actions, iapOptions };
}
