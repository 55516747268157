import { useStarterPackMixins, useStarterPackRewardCreationModal } from '@/modules/starter/pack/model/mixins';
import { useStarterPackRewardMixins } from '@/modules/starter/pack/reward/model/mixins';
import { STARTER_PACK_ROUTER } from '@/modules/starter/pack/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { starterPackService } from '@/services/starter';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useStarterPackDetail() {
    const toast = useToast();
    const dialog = useVDialog();
    const router = useRouter();

    const { model, v$, fetch, iapOptions } = useStarterPackMixins();
    const { fetchAllLinkableItems, getLinkableItem } = useStarterPackRewardMixins();
    const rewardCreationModal = useStarterPackRewardCreationModal();

    const rewardDetailtList = computed(() =>
        model.rewards.map(reward => {
            const linkableItem = getLinkableItem(reward.data, reward.type);
            return {
                ...reward,
                itemLink: linkableItem?.getLink(),
                itemName: linkableItem?.itemName
            };
        })
    );

    const beforeFetch = async () => {
        await fetchAllLinkableItems();
        await fetch();
        await rewardCreationModal.beforeFetch();
    };

    const actions = {
        enable: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await starterPackService.enable(model.id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await starterPackService.disable(model.id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await starterPackService.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(STARTER_PACK_ROUTER.INDEX());
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        removeReward: (type: any, data: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await starterPackService.reward.remove(model.id, type, data);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        createReward: async () => {
            const result = rewardCreationModal.rewardActions.quickCreate(model.id);
            if (result) {
                fetch();
            }
        }
    };

    return { model, v$, beforeFetch, rewardDetailtList, actions, iapOptions, rewardCreationModal };
}
