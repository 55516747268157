import { useLocale } from '@/modules/locale/model/locale';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { translationService } from '@/services/translation';
import { STORE_MODULES } from '@/store';
import { StoreTranslationFilter } from '@/store/translation';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import useVuelidate from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';

export function useTranslationList() {
    const toast = useToast();

    const { localeSupportedOptions, getListSupported } = useLocale();

    const filter = stateFromStore<StoreTranslationFilter>({
        moduleName: STORE_MODULES.Translation.name,
        getterFuncName: 'filter',
        setterFuncName: 'setFilter'
    });

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: translationService.list,
        params: filter,
        pageFromStore: pageFromStore(STORE_MODULES.Translation.name),
        beforeFetch: async () => await getListSupported(),
        preCheck: () => {
            if (filter.value.criteria?.length == 0) {
                filter.value.criteria = null;
            }

            return filter.value.locale != null;
        }
    });

    const validations = {
        criteria: { minLength: minLength(3) }
    };

    const v$ = useVuelidate(validations, filter);

    const dialog = useVDialog();
    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await translationService.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { filter, v$, itemList, total, currentPage, fetchList, actions, localeSupportedOptions };
}
