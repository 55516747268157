import { useQuestTypeList } from '@/modules/quest/type/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { questService } from '@/services/quest';
import { useFetchList } from '@/utils/useFetchList';
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';

export function useQuestItemList() {
    const dialog = useVDialog();
    const toast = useToast();

    const typeId = ref('');
    const { questTypeOptionWithAll, fetchList: fetchQuestTypeList } = useQuestTypeList();
    const { itemList, total, fetchList } = useFetchList({
        request: questService.item.list,
        params: computed(() => ({ typeId: typeId.value })),
        beforeFetch: async () => await fetchQuestTypeList(),
        transformResponse: (list: any[]) =>
            list.map(item => ({
                ...item,
                avgGameRange: renderValueRange(item.fromAvgGame, item.toAvgGame),
                betRange: renderValueRange(item.fromGameBet, item.toGameBet)
            }))
    });

    const actions = {
        disable: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.disable(itemId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        disableNext: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.disableNext(itemId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enable: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.enable(itemId);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enableNext: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item in next cycle?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.enableNext(itemId);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.item.remove(itemId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    // refresh whenever typeId changes
    watch(typeId, () => fetchList());

    return { itemList, total, fetchList, typeId, questTypeOptionWithAll, actions };
}

function renderValueRange(fromValue: any, toValue: any) {
    const from = fromValue >= 0 ? `[${fromValue}, ` : '(∞, ';
    const to = toValue >= 0 ? `${toValue}]` : '∞)';

    return from + to;
}
