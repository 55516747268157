import { LEAGUE_ROUTER } from '@/modules/league/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { leagueService } from '@/services/league';
import { useFetchList } from '@/utils/useFetchList';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useLeagueRewardList() {
    const dialog = useVDialog();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const canAddNew = ref(false);
    const params = {
        itemId: +route.params.itemId,
        tier: +route.params.tier
    };

    const { itemList, total, fetchList } = useFetchList({
        request: leagueService.reward.list,
        params: params,
        beforeFetch: async () => {
            const response = await leagueService.tier.get(params.itemId, params.tier);
            if (response.code === 200) {
                canAddNew.value = response.data.season === 2;
            } else {
                router.push(LEAGUE_ROUTER.INDEX());
            }
        }
    });

    const actions = {
        remove: (pos: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await leagueService.reward.remove(params.itemId, params.tier, pos);

                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => LEAGUE_ROUTER.TIER.REWARD.ADD(params.itemId, params.tier),
        edit: (pos: any) => LEAGUE_ROUTER.TIER.REWARD.EDIT(params.itemId, params.tier, pos)
    };

    return { itemList, total, fetchList, canAddNew, actions, routes };
}
