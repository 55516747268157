
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useAvatarBundleItem } from '@/modules/avatar/bundle/model/item';
import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { renderBoolean } from '@/utils/helpers';

export default defineComponent({
    name: 'AvatarBundleItems',
    components: { AppTable, AppCard },
    setup() {
        const { itemList, total, fetchList, bundle, actions } = useAvatarBundleItem();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                { label: 'Price', field: 'price' },
                { label: 'Is published', display: (row: any) => renderBoolean(row.isPublished) },
                { label: 'Is new', display: (row: any) => renderBoolean(row.isNew) },
                { label: 'Is default', display: (row: any) => renderBoolean(row.isDefault) },
                { label: 'Total sale', field: 'totalSale' },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: AVATAR_ROUTER.ITEM.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-image',
                        theme: 'info',
                        tooltip: 'View images',
                        href: AVATAR_ROUTER.ITEM.IMAGE.INDEX(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-unlink',
                        theme: 'warning',
                        tooltip: 'Unlink',
                        isShow: bundle.value.status === 0,
                        onclick: () => actions.unlink(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList };
    }
});
