
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppImageHolder } from '@/components';
import { useSurpriseItemList } from '@/modules/surprise/category/item/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { SURPRISE_ROUTER } from '@/modules/surprise/router';
import { renderBoolean } from '@/utils/helpers';

export default defineComponent({
    name: 'SurpriseItemList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, fetchList, actions, categoryId } = useSurpriseItemList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Price', field: 'price' },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Is published', display: (row: any) => renderBoolean(row.isPublished) },
                { label: 'Total sale', field: 'totalSale' },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: SURPRISE_ROUTER.ITEM.EDIT(categoryId, row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchList(1);
        });

        return { table, fetchList, categoryId, SURPRISE_ROUTER };
    }
});
