import { activityService } from '@/services/activity';
import { applicationService } from '@/services/applications';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { validateIpAddress } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { helpers, numeric, or, required } from '@vuelidate/validators';
import { reactive, watch } from 'vue';
import { LocationQuery, useRoute } from 'vue-router';
import { ActivityFilterType, useAccountAppMixins } from './mixins';

export function useAccountActivity() {
    const route = useRoute();
    const extractFilterFromQuery = (query: LocationQuery) => {
        return {
            type: query.type ? Number(query.type) : ActivityFilterType.AccountId.value,
            criteria: query.criteria?.toString() || ''
        };
    };

    // monitor to refetch whenever query changes
    watch(
        () => route.query,
        (query: LocationQuery) => {
            model.filter = extractFilterFromQuery(query);
            fetchList();
        }
    );

    const model = reactive({
        isBanned: false,
        filter: extractFilterFromQuery(route.query),
        get accountId(): number {
            v$.value.$touch();
            if (v$.value.$invalid || model.filter.type !== ActivityFilterType.AccountId.value) {
                return null;
            }

            return Number(model.filter.criteria);
        }
    });

    const validations = {
        filter: {
            criteria: {
                required,
                validIpAddress: helpers.withMessage(
                    `Invalid ip address`,
                    or(() => model.filter.type !== ActivityFilterType.IpAddress.value, validateIpAddress())
                ),
                validAccountId: helpers.withMessage(
                    `Value must be numeric`,
                    or(() => model.filter.type !== ActivityFilterType.AccountId.value, numeric)
                )
            }
        }
    };

    const v$ = useVuelidate(validations, model);

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: activityService.account.list,
        params: model.filter,
        pageFromStore: pageFromStore(STORE_MODULES.AccountActivity.name),
        preCheck: () => {
            v$.value.$touch();
            return !v$.value.$invalid;
        },
        onSuccess: async () => {
            if (model.accountId) {
                const response = await applicationService.account.isBanned(model.accountId);
                model.isBanned = response?.data ?? false;
            }
        }
    });

    const { ban, unban } = useAccountAppMixins().actionMixins;
    const actions = {
        ban: () => ban(model.accountId, fetchList),
        unban: () => unban(model.accountId, fetchList)
    };

    return { model, v$, itemList, total, currentPage, fetchList, actions };
}
