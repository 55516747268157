import { useAccountAppMixins } from '@/modules/account/app/model/mixins';
import { activityService } from '@/services/activity';
import { applicationService } from '@/services/applications';
import { creditService } from '@/services/credit';
import { rfmService } from '@/services/rfm';
import { statisticsService } from '@/services/statistics';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useAccountDetail() {
    const router = useRouter();
    const route = useRoute();

    const model = reactive({
        accountId: Number(route.params.accountId),
        account: {} as any,
        lastLogin: {} as any,
        avgPayment: {} as any,
        rfmSegmentation: {} as any,
        onlineTime: 0
    });

    const fetch = async () => {
        const [accountResponse, avgPaymentResponse, segmentationResponse, loginResponse, statsResponse] =
            await Promise.all([
                applicationService.account.get(model.accountId),
                creditService.getAvgPayment(model.accountId),
                rfmService.segmentation.get(model.accountId),
                activityService.account.getLastLogin(model.accountId),
                statisticsService.stats(model.accountId)
            ]);

        if (accountResponse.code === 200) {
            model.account = accountResponse.data;
        } else {
            router.go(-1);
        }

        if (avgPaymentResponse.code === 200) {
            model.avgPayment = avgPaymentResponse.data;
        }

        if (segmentationResponse.code === 200) {
            model.rfmSegmentation = segmentationResponse.data;
        }

        if (loginResponse.code === 200) {
            model.lastLogin = loginResponse.data;
        }

        if (statsResponse.code === 200) {
            model.onlineTime = Number(statsResponse.data) * 1000;
        }
    };

    const { ban, unban, banChat, unbanChat, mute, unmute, deletee, undelete } = useAccountAppMixins().actionMixins;
    const actions = {
        delete: () => deletee(model.accountId, fetch),
        undelete: () => undelete(model.accountId, fetch),
        ban: () => ban(model.accountId, fetch),
        unban: () => unban(model.accountId, fetch),
        mute: () => mute(model.accountId, fetch),
        unmute: () => unmute(model.accountId, fetch),
        banChat: () => banChat(model.accountId, fetch),
        unbanChat: () => unbanChat(model.accountId, fetch)
    };

    return { model, fetch, actions };
}
