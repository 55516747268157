import { BOOSTER_ROUTES } from '@/modules/booster/router';
import router from '@/router';
import { boosterService } from '@/services/booster';
import { Characters } from '@/utils/stringUtils';
import { validateFormat } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

const TOTAL_COST_OPTION = 3;
export class BoosterItemCost {
    constructor(public quantity: number = 0, public cost: number = 0, public discountRate: number = 0) {}

    toString(): string {
        return [this.quantity, this.cost, this.discountRate].join(Characters.Colon);
    }

    static fromString(data: string): BoosterItemCost | null {
        const [quantity, cost, discountRate] = data.split(Characters.Colon).map(Number);
        return new BoosterItemCost(quantity, cost, discountRate);
    }
}

export function useMixins() {
    const route = useRoute();
    const toast = useToast();

    const model = reactive({
        type: route.params?.type ? Number(route.params.type) : null,
        name: null,
        thumbnail: null,
        thumbnailFile: null,
        costList: Array(TOTAL_COST_OPTION)
            .fill(null)
            .map(() => new BoosterItemCost()),

        get costs() {
            return this.costList.map(item => item.toString()).join(Characters.Semicolon);
        }
    });

    const validations = {
        name: { required },
        type: { required },
        costs: { required, format: validateFormat(/^\d+:\d+:\d+(;\d+:\d+:\d+)*$/) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        if (model.type) {
            const response = await boosterService.get(model.type);
            if (response.code === 200) {
                model.name = response.data.name;
                model.thumbnail = response.data.thumbnail;
                model.costList = response.data.costs.split(Characters.Semicolon).map(BoosterItemCost.fromString);
                while (model.costList.length < TOTAL_COST_OPTION) model.costList.push(new BoosterItemCost());
            }
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await boosterService.create(model.type, model.name, model.costs, model.thumbnailFile);

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(BOOSTER_ROUTES.INDEX());
            }
        },

        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await boosterService.update(model.type, model.name, model.costs, model.thumbnailFile);

            if (response.code === 200) {
                toast.success('Update successfully');
                router.push(BOOSTER_ROUTES.INDEX());
            }
        }
    };

    return { v$, model, fetch, actions };
}

export const Options = {
    ItemType: [
        { value: 1, label: 'Shuffle' },
        { value: 2, label: 'Search' },
        { value: 3, label: 'Locate' },
        { value: 4, label: 'Fifty' },
        { value: 5, label: 'TimeExtend' },
        { value: 6, label: 'ChangeQuestion' }
    ]
};
