import { useVDialog } from '@/plugins/dialog/v-dialog';
import { iapService } from '@/services/iap';
import { useFetchList } from '@/utils/useFetchList';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';

export function useIAPList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList({ request: iapService.list });
    const iapOptions = computed(() => itemList.value.map(item => ({ value: item.id, label: item.name, data: item })));

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await iapService.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, iapOptions, fetchIapOptions: fetchList, actions };
}
