import { useAppList } from '@/modules/apps/model/list';
import { usePromotionPrototypeList } from '@/modules/promotion/prototype/model/list';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { useRfmPersonaList } from '@/modules/rfm/persona/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { STORE_MODULES } from '@/store';
import { StorePromotionItemFilter } from '@/store/promotion/item';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import FileSaver from 'file-saver';
import { Ref, watch } from 'vue';
import { useToast } from 'vue-toastification';

export function usePromotionItemList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { currentAppInfo } = useAppList();
    const { personaOptions, fetchPersonaAll } = useRfmPersonaList();
    const { prototypeMap, fetchPrototypeAll } = usePromotionPrototypeList();

    const filter: Ref<StorePromotionItemFilter> = stateFromStore<StorePromotionItemFilter>({
        moduleName: STORE_MODULES.PromotionItem.name,
        getterFuncName: 'filter',
        setterFuncName: 'setFilter'
    });

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: promotionService.item.list,
        params: filter,
        pageFromStore: pageFromStore(STORE_MODULES.PromotionItem.name),
        beforeFetch: async () => {
            await Promise.all([fetchPersonaAll(), fetchPrototypeAll()]);
        },
        transformResponse: (list: any[]) =>
            list.map(item => {
                const prototype = prototypeMap.value.get(item.usedPrototypeId);
                const prototypeLink = prototype ? PROMOTION_ROUTER.PROTOTYPE.DETAIL(item.usedPrototypeId).href : '';
                const prototypeName = prototype ? `${item.usedPrototypeId} - ${prototype.name}` : item.usedPrototypeId;
                return { ...item, prototypeLink, prototypeName };
            })
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        export: async () => {
            const response = await promotionService.item.export();
            if (response.code === 200) {
                const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
                const fileName = currentAppInfo.value?.name + 'Promotion.csv';
                FileSaver.saveAs(blob, fileName);
            }
        }
    };

    // clear filter when type changed
    watch(
        () => filter.value.type,
        () => {
            filter.value.status = -1;
            filter.value.rfmPersonaId = null;
        }
    );

    return { itemList, total, currentPage, fetchList, actions, filter, personaOptions };
}
