
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppCard, AppTable, AppButton } from '@/components';
import { useTicketList } from '@/modules/scratch/ticket/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { formatDate, renderOption } from '@/utils/helpers';
import { ScratchTicketOptions } from '@/modules/scratch/ticket/model/mixins';

export default defineComponent({
    name: 'ScratchList',
    components: { AppTable, AppButton, AppCard },
    setup() {
        const { itemList, total, currentPage, fetchList, actions, routes } = useTicketList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'Id', field: 'id' },
                { label: 'Type', display: (row: any) => renderOption(ScratchTicketOptions.Type, row.type) },
                { label: 'Package', field: 'packageId' },
                { label: 'Price', field: 'price' },
                { label: 'Cost', field: 'cost' },
                { label: 'Quantity', field: 'quantity' },
                { label: 'Base Value', field: 'baseValue' },
                { label: 'Status', display: (row: any) => renderOption(ScratchTicketOptions.Status, row.status) },
                { label: 'End Time', display: (row: any) => formatDate(row.endTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-shipping-fast',
                        theme: 'info',
                        tooltip: 'Add special shop',
                        disabled: !row.canLink,
                        href: routes.specialShop(row.type)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-tags',
                        theme: 'info',
                        tooltip: 'Add promo code',
                        disabled: !row.canLink,
                        href: routes.promoCode(row.type)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, routes };
    }
});
