import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_avatar_breadcrumb = _resolveComponent("app-avatar-breadcrumb")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_avatar_breadcrumb, {
      class: "col-lg-8 mx-auto",
      "category-id": _ctx.model.parentId
    }, null, 8, ["category-id"]),
    _createVNode(_component_app_card, {
      class: "col-lg-8 mx-auto",
      label: "Add Avatar Category"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", _hoisted_1, [
          (_ctx.model.parentId)
            ? (_openBlock(), _createBlock(_component_app_form_row, {
                key: 0,
                label: "Parent Category"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.model.parentName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.parentName) = $event)),
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_app_form_row, { label: "Name" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_input, {
                modelValue: _ctx.model.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event)),
                validations: _ctx.v$.name
              }, null, 8, ["modelValue", "validations"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Position" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_input, {
                type: "number",
                modelValue: _ctx.model.position,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.position) = $event)),
                modelModifiers: { number: true },
                validations: _ctx.v$.position
              }, null, 8, ["modelValue", "validations"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Gender" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_select, {
                options: _ctx.AvatarGender,
                modelValue: _ctx.model.gender,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.gender) = $event)),
                modelModifiers: { number: true },
                validations: _ctx.v$.gender
              }, null, 8, ["options", "modelValue", "validations"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Image" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_file_upload, {
                accept: "image/png",
                modelValue: _ctx.model.imageFile,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.imageFile) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Left position" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_input, {
                type: "number",
                modelValue: _ctx.model.posX,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.posX) = $event)),
                modelModifiers: { number: true }
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Top position" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_input, {
                type: "number",
                modelValue: _ctx.model.posY,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.posY) = $event)),
                modelModifiers: { number: true }
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Is published" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_switch, {
                modelValue: _ctx.model.isPublished,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.isPublished) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Is required" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_switch, {
                modelValue: _ctx.model.isRequired,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.isRequired) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Is body" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_switch, {
                modelValue: _ctx.model.isBody,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.isBody) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_button, {
                name: "Create",
                theme: "info",
                icon: "fa-save",
                onClick: _withModifiers(_ctx.create, ["prevent"])
              }, null, 8, ["onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}