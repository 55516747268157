import { Role } from '@/interfaces/state';
import { applicationService } from '@/services/applications';
import { App } from '@/store/applications';
import { useFetchList } from '@/utils/useFetchList';
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useAppList() {
    const store = useStore();

    const isSuperUser = computed((): boolean => store.getters['auth/roles'].includes(Role.SUPER_USER));
    const allApps = computed(() => store.getters['application/apps'] as Array<App>);
    const selectedAppId = computed(() => store.getters['application/appId']);
    const currentAppInfo = computed(() => allApps.value.find(app => app.id == selectedAppId.value));
    const mapApps = computed(() => new Map(allApps.value.map(app => [app.id, app])));
    const appOption = computed(() => allApps.value.map(app => ({ label: app.name, value: app.id })));
    const appOptionWithAll = computed(() => [{ label: 'All', value: '' }, ...appOption.value]);

    const { itemList, total, fetchList } = useFetchList({
        request: applicationService.app.list,
        transformResponse: (list: any[]) =>
            list.map((item: any) => ({ ...item, status: item.removeTime ? 'Deleting' : '' }))
    });

    return {
        itemList,
        total,
        fetchList,
        isSuperUser,
        allApps,
        mapApps,
        selectedAppId,
        currentAppInfo,
        appOption,
        appOptionWithAll
    };
}
