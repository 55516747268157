import { ChestReward, convertChestRewardListFromStrings } from '@/modules/picword/map/model/mixins';
import { PICWORD_ROUTES } from '@/modules/picword/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { picwordService } from '@/services/picword';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePicwordMapDetail() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const dialog = useVDialog();

    const model = reactive({
        id: route.params.id,
        name: null,
        selectedIap: null,
        packageId: '',
        price: 0,
        totalLevel: 0,
        totalAccount: 0,
        chestByStar: '',
        chestRewardCredit: '',
        chestRewardHeart: '',
        chestRewardBooster: '',
        canEdit: false,
        canDelete: false,

        chestRewardList: [] as Array<ChestReward>
    });

    const fetch = async () => {
        const response = await picwordService.map.get(model.id);
        if (response.code === 200) {
            const result = response.data;
            model.chestRewardList = convertChestRewardListFromStrings(
                result.chestByStar,
                result.chestRewardCredit,
                result.chestRewardHeart,
                result.chestRewardBooster
            );

            Object.assign(model, result);
        } else {
            toast.error(response.data);
            router.push(PICWORD_ROUTES.MAP.INDEX());
        }
    };

    const actions = {
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await picwordService.map.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(PICWORD_ROUTES.MAP.INDEX());
                        }
                    }
                }
            });
        }
    };

    return { model, fetch, actions };
}
