import { Option } from '@/components/Select.vue';
import { useAppList } from '@/modules/apps/model/list';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { promotionService } from '@/services/promotion';
import { rfmService } from '@/services/rfm';
import { useFetchList } from '@/utils/useFetchList';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, required, requiredIf } from '@vuelidate/validators';
import { computed, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { DisplayType } from './mixins';

export function usePromotionItemAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { appOption, selectedAppId } = useAppList();

    const personaMap = computed(() => new Map(personaAllForAppId.value.map((item: any) => [item.id, item])));
    const { itemList: personaAllForAppId, fetchList: fetchPersonaAllForAppId } = useFetchList({
        request: rfmService.persona.listForOtherApp,
        params: computed(() => ({ forAppId: model.forAppId }))
    });

    const { itemList: prototypeAllForAppId, fetchList: fetchPrototypeAllForAppId } = useFetchList({
        request: promotionService.prototype.listAll,
        params: computed(() => ({ forAppId: model.forAppId })),
        preCheck: async () => {
            await fetchPersonaAllForAppId();
            return model.forAppId && personaAllForAppId.value.length > 0;
        },
        transformResponse: (list: any[]) =>
            list.map((item: any) => {
                item.personas.forEach((per: any) => {
                    per.rfmPersonaName = personaMap.value.get(per.rfmPersonaId)?.name;
                });
                return item;
            })
    });

    const model = reactive({
        cloneId: route.params?.id ?? null,
        forAppId: selectedAppId.value,
        name: '',
        status: null,
        displayType: 0,
        fromTime: null,
        toTime: null,
        expectedPackage: 0,
        image: '',
        imageStatus: null,
        imageFile: null,
        prototypeId: null,
        get prototypeOptions(): Option[] {
            return prototypeAllForAppId.value.map((item: any) => ({
                value: item.id,
                label: `[${item.id}] ${item.name}`
            }));
        },
        get personas() {
            return prototypeAllForAppId.value.find(item => item.id === model.prototypeId)?.personas ?? [];
        }
    });

    const validations = {
        name: { required },
        displayType: { required },
        expectedPackage: { integer, minValue: minValue(0) },
        prototypeId: { required, integer, minValue: minValue(0) },
        fromTime: { requiredIf: requiredIf(() => model.displayType !== DisplayType.AccessUnlimited.value) },
        toTime: {
            requiredIf: requiredIf(() => model.displayType !== DisplayType.AccessUnlimited.value),
            validDate: validateDateAfter('fromTime')
        }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchPrototypeAllForAppId();

        // clone case:
        if (model.cloneId) {
            const response = await promotionService.item.get(model.cloneId);
            if (response.code === 200) {
                const result = response.data;
                model.name = result.name;
                model.displayType = result.displayType;
                model.fromTime = new Date(result.fromTime);
                model.toTime = new Date(result.toTime);
                model.expectedPackage = result.expectedPackage;
                model.image = result.image;
                model.prototypeId = result.usedPrototypeId;
            } else {
                toast.error('Not found');
                router.push(PROMOTION_ROUTER.ITEM.INDEX());
            }
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }
            const forAppId = model.cloneId ? model.forAppId : null;
            const response = await promotionService.item.create(
                model.name,
                model.cloneId,
                forAppId,
                model.fromTime?.getTime(),
                model.toTime?.getTime(),
                model.displayType,
                model.expectedPackage,
                model.prototypeId,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(PROMOTION_ROUTER.ITEM.INDEX());
            }
        },
        createForLink: async (): Promise<number> => {
            model.displayType = DisplayType.AccessUnlimited.value;

            v$.value.$touch();
            if (v$.value.$invalid) {
                return -1;
            }

            const response = await promotionService.item.create(
                model.name,
                null,
                null,
                model.fromTime?.getTime(),
                model.toTime?.getTime(),
                model.displayType,
                model.expectedPackage,
                model.prototypeId,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Create promotion successfully');
                return response.data;
            }

            return -1;
        }
    };

    // reload prototypes when appId changed
    watch(() => model.forAppId, fetchPrototypeAllForAppId);
    watch(
        () => model.displayType,
        displayType => {
            if (displayType === DisplayType.AccessUnlimited.value) {
                model.fromTime = null;
                model.toTime = null;
            }
        }
    );

    return { model, v$, fetch, appOption, actions };
}
