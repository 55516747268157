import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_table, {
        "is-slot-mode": true,
        columns: _ctx.table.columns,
        rows: _ctx.table.rows,
        total: _ctx.table.totalRecordCount,
        "page-size": Number.MAX_SAFE_INTEGER,
        actions: _ctx.table.actions,
        onRefreshData: _ctx.fetchList
      }, null, 8, ["columns", "rows", "total", "page-size", "actions", "onRefreshData"])
    ]),
    _: 1
  }))
}