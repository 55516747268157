import { useRfmGroupList } from '@/modules/rfm/group/model/list';
import { RFM_ROUTER } from '@/modules/rfm/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { rfmService } from '@/services/rfm';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useRfmPersona() {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const { rfmGroupId, rfmGroupOptions, fetchRfmGroupAll } = useRfmGroupList();
    const model = reactive({
        id: null,
        name: '',
        get groupId() {
            return rfmGroupId.value;
        },
        set groupId(value) {
            rfmGroupId.value = value;
        }
    });

    const validations = {
        name: { required },
        groupId: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchRfmGroupAll();

        const response = await rfmService.persona.get(route.params.id);
        if (response.code === 200) {
            model.id = response.data.id;
            model.name = response.data.name;
            model.groupId = response.data.groupId;
        } else if (response.code === 404) {
            model.id = null;
            model.name = '';
            model.groupId = null;
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await rfmService.persona.create(model.name, model.groupId);
            if (response.code === 200) {
                toast.success('create successfully');
                router.push(RFM_ROUTER.PERSONA.INDEX());
            }
        },

        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await rfmService.persona.update(model.id, model.name);
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push(RFM_ROUTER.PERSONA.INDEX());
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await rfmService.persona.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(RFM_ROUTER.PERSONA.INDEX());
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, rfmGroupOptions, fetchRfmGroupAll, actions };
}
