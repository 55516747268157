import { useVDialog } from '@/plugins/dialog/v-dialog';
import versionService from '@/services/version';
import { STORE_MODULES } from '@/store';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';

export function useVersionList() {
    const toast = useToast();
    const dialog = useVDialog();

    const storeId = stateFromStore<number>({
        moduleName: STORE_MODULES.Version.name,
        getterFuncName: 'store',
        setterFuncName: 'setStore'
    });

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: versionService.list,
        pageFromStore: pageFromStore(STORE_MODULES.Version.name),
        params: computed(() => ({ store: storeId.value }))
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await versionService.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        drop: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to drop this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await versionService.drop(id);
                        if (response.code === 200) {
                            toast.success('Drop successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        undrop: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to undrop this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await versionService.undrop(id);
                        if (response.code === 200) {
                            toast.success('Undrop successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        activate: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to active this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await versionService.activate(id);
                        if (response.code === 200) {
                            toast.success('Active successfully');
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    return { storeId, itemList, total, currentPage, fetchList, actions };
}
