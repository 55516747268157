import { promotionService } from '@/services/promotion';
import useVuelidate from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins, usePromotionModalProps } from './mixins';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';

export function usePromotionBoosterAdd() {
    const router = useRouter();
    const toast = useToast();

    const { MODAL_NAME, model: promotionModel, v$: promotionV$, actions: modalActions } = usePromotionModalProps();
    const { fetchLinkableItems, promotionOption, boosterOption } = useMixins();

    const boosterOptionExcludeAdded = computed(() => {
        const addedTypes = model.addedBoosterList.map(item => item.type);
        return boosterOption.value.filter(item => !addedTypes.includes(item.value));
    });

    const model = reactive({
        name: null,
        promotionId: null,
        selectedBooster: null,
        quantity: 1,
        addedBoosterList: []
    });

    const validations = {
        name: { required },
        promotionId: { required },
        quantity: { required, minValue: minValue(1) },
        addedBoosterList: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        await fetchLinkableItems();
        model.selectedBooster = boosterOptionExcludeAdded.value?.[0]?.value ?? null;
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }
            const boosterList: Array<number> = [];
            const quantityList: Array<number> = [];
            model.addedBoosterList.forEach(item => {
                boosterList.push(item.type);
                quantityList.push(item.quantity);
            });

            const response = await promotionService.booster.create(
                model.name,
                model.promotionId,
                boosterList,
                quantityList
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(PROMOTION_ROUTER.BOOSTER.INDEX());
            }
        },

        addBoosterItem: () => {
            if (v$.value.quantity.$invalid) {
                return;
            }

            const selectedItem = boosterOption.value.find(item => item.value === model.selectedBooster);
            if (selectedItem) {
                model.addedBoosterList.push({
                    type: selectedItem.value,
                    name: selectedItem.label,
                    quantity: model.quantity
                });

                model.selectedBooster = boosterOptionExcludeAdded.value?.[0]?.value ?? null;
            }
        },

        removeBoosterItem: (type: number) => {
            model.addedBoosterList.splice(
                model.addedBoosterList.findIndex(item => item.type === type),
                1
            );
            model.selectedBooster = boosterOptionExcludeAdded.value?.[0]?.value ?? null;
        },

        // Modal actions
        createPromotionForLink: async () => {
            const itemId = await modalActions.createForLink();
            if (itemId > 0) {
                await fetchLinkableItems();
                model.promotionId = itemId;
                modalActions.closeModal();
            }
        },
        openModal: () => modalActions.openModal(),
        closeModal: () => modalActions.closeModal()
    };

    return {
        v$,
        model,
        fetch,
        boosterOptionExcludeAdded,
        promotionOption,
        actions,
        MODAL_NAME,
        promotionModel,
        promotionV$
    };
}
