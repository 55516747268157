
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppCard, AppTable, AppButton, AppSelect, AppFormRow, AppImageHolder } from '@/components';
import { usePromotionItemList } from '@/modules/promotion/item/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { formatDate, renderOption } from '@/utils/helpers';
import { RouterLink } from 'vue-router';
import { Options, FilterType } from '@/modules/promotion/item/model/mixins';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';

export default defineComponent({
    name: 'PromotionItemList',
    components: { AppTable, AppButton, AppSelect, AppFormRow, AppCard },
    setup() {
        const { itemList, total, currentPage, fetchList, actions, filter, personaOptions } = usePromotionItemList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'Id', field: 'id' },
                { label: 'Name', field: 'name' },
                {
                    label: 'Used Prototype Id',
                    component: (row: any) => ({
                        type: row.prototypeLink.length ? markRaw(RouterLink) : 'span',
                        message: row.prototypeName,
                        props: { to: row.prototypeLink }
                    })
                },
                { label: 'Display Type', display: (row: any) => renderOption(Options.DisplayType, row.displayType) },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Status', display: (row: any) => renderOption(Options.Status, row.status) },
                { label: 'To Time', display: (row: any) => formatDate(row.toTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: PROMOTION_ROUTER.ITEM.DETAIL(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: PROMOTION_ROUTER.ITEM.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        href: PROMOTION_ROUTER.ITEM.TRANSLATE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        href: PROMOTION_ROUTER.ITEM.CLONE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onclick: () => actions.enable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchList();
        });

        return { table, fetchList, actions, filter, personaOptions, Options, FilterType, PROMOTION_ROUTER };
    }
});
