
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppCard, AppTable, AppButton, AppImageHolder } from '@/components';
import { useStarterPackList } from '@/modules/starter/pack/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { STARTER_PACK_ROUTER } from '@/modules/starter/pack/router';
import { renderBoolean } from '@/utils/helpers';

export default defineComponent({
    name: 'StarterPackList',
    components: { AppTable, AppButton, AppCard },
    setup() {
        const { itemList, total, fetchList, actions } = useStarterPackList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'Id', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Package', field: 'packageId' },
                { label: 'Price', field: 'price' },
                { label: 'Amount', field: 'amount' },
                { label: 'Duration Seconds', field: 'durationSeconds' },
                { label: 'Is Enabled', display: (row: any) => renderBoolean(row.isEnabled) },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-gift',
                        theme: 'info',
                        tooltip: 'View rewards',
                        href: STARTER_PACK_ROUTER.REWARD.INDEX(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        href: STARTER_PACK_ROUTER.DETAIL(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        href: STARTER_PACK_ROUTER.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onClick: () => actions.enable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onClick: () => actions.disable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, STARTER_PACK_ROUTER };
    }
});
