import { Option } from '@/components/Select.vue';
import { useGameVariantList } from '@/modules/game/variant/model/list';
import { gameService } from '@/services/game';
import { useFetchList } from '@/utils/useFetchList';
import { computed, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMixins } from './mixins';

export function useGameBetList() {
    const route = useRoute();

    const query = reactive({
        variantId: Number(route.query.variantId || null)
    });

    const { variantOptions, fetchList: fetchVariantList } = useGameVariantList();
    const { remove } = useMixins();

    const { itemList, total, fetchList } = useFetchList({
        request: gameService.bet.list,
        params: query,
        beforeFetch: async () => await fetchVariantList(),
        preCheck: () => query.variantId != null
    });

    // to use {betMap, betOptions}, fetchAllBets first
    const betMap = ref(new Map<number, Option & { data: any }>());
    const betOptions = computed((): Array<Option> => Array.from(betMap.value.values()));
    const fetchAllBets = async () => {
        betMap.value.clear();

        const [betResponse] = await Promise.all([gameService.bet.list(), fetchVariantList()]);
        if (betResponse.code !== 200) {
            return;
        }

        const variantMap = new Map(variantOptions.value.map(({ value, label }) => [value, label]));

        for (const bet of betResponse.data) {
            const variantName = variantMap.get(bet.variantId);
            if (variantName) {
                const item = {
                    value: bet.id,
                    label: `${variantName}: ${bet.name} - ${bet.maxRound} ${bet.maxRound > 1 ? 'rounds' : 'round'}`,
                    data: bet
                };
                betMap.value.set(bet.id, item);
            }
        }
    };

    const actions = {
        remove: (id: any) => remove(id, fetchList)
    };

    return {
        query,
        fetchVariantList,
        variantOptions,
        fetchList,
        itemList,
        total,
        fetchAllBets,
        betMap,
        betOptions,
        actions
    };
}
