import { Role } from '@/interfaces/state';
import store from '@/store';
import { computed, ref } from 'vue';

const selectedAppId = computed(() => store.getters['application/appId']);
const roles = computed((): Array<Role> => store.getters['auth/roles']);
const appRoles = computed((): Array<Role> => store.getters['auth/appRoles']);

export const getMenu = () =>
    ref([
        {
            name: 'System Wide',
            icon: 'fas fa-server',
            hasPermission: true,
            children: [
                {
                    name: 'Account',
                    icon: 'fa fa-users',
                    hasPermission: appRoles.value.includes(Role.ADMIN),
                    children: [
                        {
                            name: 'New',
                            path: '/system/account/add',
                            icon: 'fa fa-plus',
                            hasPermission: true
                        },
                        {
                            name: 'Password',
                            path: '/system/account/password',
                            icon: 'fa fa-key',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Avatar',
                    icon: 'fa fa-tshirt',
                    hasPermission: roles.value.includes(Role.ADMIN),
                    children: [
                        {
                            name: 'Background',
                            path: '/system/avatar/background',
                            icon: 'fa fa-image',
                            hasPermission: true
                        },
                        {
                            name: 'Category',
                            path: '/system/avatar/category',
                            icon: 'fa fa-list-alt',
                            hasPermission: true
                        },
                        {
                            name: 'Item',
                            path: '/system/avatar/item',
                            icon: 'fa fa-leaf',
                            hasPermission: true
                        },
                        {
                            name: 'Bundle',
                            path: '/system/avatar/bundle',
                            icon: 'fa fa-box',
                            hasPermission: true
                        },
                        {
                            name: 'Default Avatar',
                            path: '/system/avatar/default',
                            icon: 'fa fa-user-astronaut',
                            hasPermission: true
                        },
                        {
                            name: 'Gender Avatar',
                            path: '/system/avatar/gender',
                            icon: 'fa fa-venus-mars',
                            hasPermission: true
                        },
                        {
                            name: 'Color',
                            path: '/system/avatar/color',
                            icon: 'fa fa-palette',
                            hasPermission: true
                        },
                        {
                            name: 'Layer',
                            path: '/system/avatar/layer',
                            icon: 'fa fa-layer-group',
                            hasPermission: true
                        },
                        {
                            name: 'Import',
                            path: '/system/avatar/import',
                            icon: 'fa fa-file-import',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Item',
                    path: '/system/item',
                    icon: 'fa fa-store-alt',
                    hasPermission: true
                },
                {
                    name: 'Role',
                    path: '/system/role',
                    icon: 'fa fa-users-cog',
                    hasPermission: true
                },
                {
                    name: 'Maintenance',
                    path: '/system/maintenance',
                    icon: 'fa fa-procedures',
                    hasPermission: roles.value.includes(Role.SUPER_USER)
                }
            ]
        },
        {
            name: 'Management',
            icon: 'fas fa-user-tie',
            hasPermission: true,
            children: [
                {
                    name: 'Account',
                    path: '/app/account',
                    icon: 'fa fa-users',
                    hasPermission: true
                },
                {
                    name: 'News',
                    path: '/app/news',
                    icon: 'fas fa-newspaper',
                    hasPermission: true
                },
                {
                    name: 'Send Message',
                    path: '/app/message/send',
                    icon: 'fas fa-envelope',
                    hasPermission: true
                },
                {
                    name: 'Credit Ticket',
                    path: '/app/credit/ticket',
                    icon: 'fas fa-ticket-alt',
                    hasPermission: true
                },
                {
                    name: 'Arena',
                    icon: 'fas fa-flag-checkered',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Item',
                            path: '/app/arena/item',
                            icon: 'fas fa-leaf',
                            hasPermission: true
                        },
                        {
                            name: 'Medal',
                            path: '/app/arena/medal',
                            icon: 'fas fa-medal',
                            hasPermission: true
                        },
                        {
                            name: 'Ticket',
                            path: '/app/arena/ticket',
                            icon: 'fas fa-ticket-alt',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'League',
                    path: '/app/league',
                    icon: 'fas fa-trophy',
                    hasPermission: true
                },
                {
                    name: 'Bonus',
                    icon: 'fas fa-gift',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Login',
                            path: '/app/bonus/login',
                            icon: 'fas fa-sign-in-alt',
                            hasPermission: true
                        },
                        {
                            name: 'Boost',
                            path: '/app/bonus/boost',
                            icon: 'fas fa-angle-double-up',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Surprise',
                    path: '/app/surprise/category',
                    icon: 'fas fa-mortar-pestle',
                    hasPermission: true
                },
                {
                    name: 'Quest',
                    icon: 'fas fa-tasks',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Type',
                            path: '/app/quest/type',
                            icon: 'fa fa-tags',
                            hasPermission: true
                        },
                        {
                            name: 'Item',
                            path: '/app/quest/item',
                            icon: 'fa fa-check',
                            hasPermission: true
                        },
                        {
                            name: 'Boost',
                            path: '/app/quest/boost',
                            icon: 'fas fa-syringe',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Piggybank',
                    icon: 'fas fa-piggy-bank',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Item',
                            path: '/app/piggybank/item',
                            icon: 'fas fa-leaf',
                            hasPermission: true
                        },
                        {
                            name: 'Boost',
                            path: '/app/piggybank/boost',
                            icon: 'fas fa-syringe',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Promo Code',
                    path: '/app/promotion/code',
                    icon: 'fas fa-tags',
                    hasPermission: true
                },
                {
                    name: 'Progress',
                    path: '/app/progress',
                    icon: 'far fa-calendar-check',
                    hasPermission: true
                },
                {
                    name: 'Challenge',
                    path: '/app/challenge',
                    icon: 'fas fa-gamepad',
                    hasPermission: true
                },
                {
                    name: 'Chat Sentence',
                    path: '/app/chat/sentence',
                    icon: 'fas fa-laugh',
                    hasPermission: true
                },
                {
                    name: 'Scratch Ticket',
                    path: '/app/scratch/ticket',
                    icon: 'fas fa-eraser',
                    hasPermission: true
                },
                {
                    name: 'Duplicate',
                    icon: 'fas fa-clone',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Item',
                            path: '/app/duplicate/item',
                            icon: 'fas fa-leaf',
                            hasPermission: true
                        },
                        {
                            name: 'Cycle',
                            path: '/app/duplicate/cycle',
                            icon: 'fas fa-sync-alt',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Tournament',
                    icon: 'fas fa-rocket',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Leaderboard',
                            path: '/app/tournament/leaderboard',
                            icon: 'fas fa-leaf',
                            hasPermission: true
                        },
                        {
                            name: 'Cycle',
                            path: '/app/tournament/cycle',
                            icon: 'fas fa-sync-alt',
                            hasPermission: true
                        },
                        {
                            name: 'Medal',
                            path: '/app/tournament/medal',
                            icon: 'fas fa-medal',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Booster Item',
                    path: '/app/booster',
                    icon: 'fas fa-syringe',
                    hasPermission: true
                },
                {
                    name: 'Pix2Word',
                    icon: 'fas fa-spell-check',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Map',
                            path: '/app/picword/map',
                            icon: 'fas fa-map',
                            hasPermission: true
                        },
                        {
                            name: 'Pet',
                            path: '/app/picword/pet',
                            icon: 'fas fa-cat',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Starter Pack',
                    path: '/app/starter/pack',
                    icon: 'fas fa-door-open',
                    hasPermission: true
                },
                {
                    name: 'Role',
                    path: '/app/role',
                    icon: 'fa fa-users-cog',
                    hasPermission: appRoles.value.includes(Role.ADMIN)
                },
                {
                    name: 'Activity',
                    path: '/app/activity',
                    icon: 'fa fa-history',
                    hasPermission: true
                }
            ]
        },
        {
            name: 'Configuration',
            icon: 'fas fa-wrench',
            hasPermission: appRoles.value.includes(Role.ADMIN),
            children: [
                {
                    name: 'Application',
                    path: '/apps/' + selectedAppId.value + '/edit',
                    icon: 'fas fa-pen-square',
                    hasPermission: true
                },
                {
                    name: 'Translation',
                    icon: 'fas fa-language',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Item',
                            path: '/app/translation/item',
                            icon: 'fas fa-leaf',
                            hasPermission: true
                        },
                        {
                            name: 'Import / Export',
                            path: '/app/translation/export',
                            icon: 'fas fa-file-import',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Version',
                    path: '/app/version',
                    icon: 'fas fa-anchor',
                    hasPermission: true
                },
                {
                    name: 'Bad Word',
                    path: '/app/badword',
                    icon: 'fas fa-grimace',
                    hasPermission: true
                },
                {
                    name: 'Gift',
                    icon: 'fas fa-gift',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Lucky',
                            path: '/app/gift/lucky',
                            icon: 'fas fa-leaf',
                            hasPermission: true
                        },
                        {
                            name: 'Setting',
                            path: '/app/gift/setting',
                            icon: 'fas fa-cog',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Totem',
                    path: '/app/totem',
                    icon: 'fas fa-video',
                    hasPermission: true
                },
                {
                    name: 'Slot',
                    path: '/app/slot',
                    icon: 'fas fa-dice',
                    hasPermission: true
                },
                {
                    name: 'Mail Setting',
                    path: '/app/mail',
                    icon: 'fas fa-envelope',
                    hasPermission: true
                },
                {
                    name: 'Transaction Text',
                    icon: 'fas fa-clipboard-list',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Item',
                            path: '/app/transaction/text/item',
                            icon: 'fas fa-leaf',
                            hasPermission: true
                        },
                        {
                            name: 'Import / Export',
                            path: '/app/transaction/text/export',
                            icon: 'fas fa-file-import',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Game',
                    icon: 'fas fa-dna',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Variant',
                            path: '/app/game/variant',
                            icon: 'fas fa-shapes',
                            hasPermission: true
                        },
                        {
                            name: 'Bet',
                            path: '/app/game/bet',
                            icon: 'fas fa-dollar-sign',
                            hasPermission: true
                        },
                        {
                            name: 'Parameter',
                            path: '/app/game/parameter',
                            icon: 'fas fa-sliders-h',
                            hasPermission: true
                        },
                        {
                            name: 'Report',
                            path: '/app/game/report',
                            icon: 'far fa-file-alt',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Arena Setting',
                    path: '/app/arena/setting',
                    icon: 'fas fa-flag-checkered',
                    hasPermission: true
                },
                {
                    name: 'Surprise Setting',
                    path: '/app/surprise/setting',
                    icon: 'fas fa-mortar-pestle',
                    hasPermission: true
                },
                {
                    name: 'Chat Setting',
                    path: '/app/chat/setting',
                    icon: 'fas fa-meh',
                    hasPermission: true
                },
                {
                    name: 'Duplicate Setting',
                    path: '/app/duplicate/setting',
                    icon: 'fas fa-clone',
                    hasPermission: true
                },
                {
                    name: 'Locale',
                    path: '/app/locale',
                    icon: 'fas fa-globe',
                    hasPermission: true
                },
                {
                    name: 'Special shop setting',
                    path: '/app/item/setting',
                    icon: 'fas fa-store-alt',
                    hasPermission: true
                },
                {
                    name: 'Pix2Word Setting',
                    path: '/app/picword/setting',
                    icon: 'fas fa-spell-check',
                    hasPermission: true
                }
            ]
        },
        {
            name: 'Payment',
            icon: 'fas fa-suitcase',
            hasPermission: true,
            children: [
                {
                    name: 'IAP',
                    path: '/app/iap',
                    icon: 'far fa-money-bill-alt',
                    hasPermission: true
                },
                {
                    name: 'RFM System',
                    icon: 'fas fa-cubes',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Group',
                            path: '/app/rfm/group',
                            icon: 'fas fa-layer-group',
                            hasPermission: true
                        },
                        {
                            name: 'Recency',
                            path: '/app/rfm/recency',
                            icon: 'fas fa-calendar-alt',
                            hasPermission: true
                        },
                        {
                            name: 'Frequency',
                            path: '/app/rfm/frequency',
                            icon: 'fas fa-file-invoice-dollar',
                            hasPermission: true
                        },
                        {
                            name: 'Monetary',
                            path: '/app/rfm/monetary',
                            icon: 'fas fa-dollar-sign',
                            hasPermission: true
                        },
                        {
                            name: 'Persona',
                            path: '/app/rfm/persona',
                            icon: 'fas fa-users-cog',
                            hasPermission: true
                        },
                        {
                            name: 'Segmentation',
                            path: '/app/rfm/segmentation',
                            icon: 'fas fa-sliders-h',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Promotion',
                    icon: 'fas fa-percentage',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Item',
                            path: '/app/promotion/item',
                            icon: 'fas fa-clipboard-list',
                            hasPermission: true
                        },
                        {
                            name: 'Prototype',
                            path: '/app/promotion/prototype',
                            icon: 'far fa-smile-beam',
                            hasPermission: true
                        },
                        {
                            name: 'Booster',
                            path: '/app/promotion/booster',
                            icon: 'fas fa-syringe',
                            hasPermission: true
                        },
                        {
                            name: 'Plan',
                            path: '/app/promotion/plan',
                            icon: 'fas fa-calendar',
                            hasPermission: true
                        },
                        {
                            name: 'Cycle',
                            path: '/app/promotion/cycle',
                            icon: 'fas fa-sync-alt',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Subscription',
                    icon: 'fas fa-shopping-cart',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Item',
                            path: '/app/subscription/item',
                            icon: 'fas fa-clipboard-list',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Shop',
                    path: '/app/shop',
                    icon: 'fa fa-store',
                    hasPermission: true
                }
            ]
        },
        {
            name: 'Third Party',
            icon: 'fas fa-cogs',
            hasPermission: true,
            children: [
                {
                    name: 'Apple',
                    path: '/app/apple',
                    icon: 'fab fa-apple',
                    hasPermission: true
                },
                {
                    name: 'Facebook',
                    path: '/app/facebook',
                    icon: 'fab fa-facebook',
                    hasPermission: true
                },
                {
                    name: 'Google',
                    icon: 'fab fa-google',
                    hasPermission: true,
                    children: [
                        {
                            name: 'Payment',
                            path: '/app/google/payment',
                            icon: 'fas fa-money-bill-wave',
                            hasPermission: true
                        },
                        {
                            name: 'Linked app',
                            path: '/app/google/linkedapp',
                            icon: 'fas fa-link',
                            hasPermission: true
                        }
                    ]
                },
                {
                    name: 'Stripe',
                    path: '/app/stripe',
                    icon: 'fab fa-stripe',
                    hasPermission: true
                }
            ]
        }
    ]);
