import { CREDIT_ROUTER } from '@/modules/credit/ticket/router';
import { ITEM_ROUTER } from '@/modules/item/router';
import { FEATURES } from '@/modules/promotion/code/model/mixins';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { creditService } from '@/services/credit';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';

export function useCreditList() {
    const dialog = useVDialog();
    const toast = useToast();
    const { itemList, total, fetchList } = useFetchList({ request: creditService.ticket.list });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await creditService.ticket.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => CREDIT_ROUTER.TICKET.ADD(),
        edit: (id: any) => CREDIT_ROUTER.TICKET.EDIT(id),
        specialShop: (id: any) => ITEM_ROUTER.SYSTEM.SPECIAL_SHOP(FEATURES.CREDIT_TICKET, id),
        promoCode: (id: any) => PROMOTION_ROUTER.CODE.ADD_TO_FEATURE(FEATURES.CREDIT_TICKET, id)
    };

    return { itemList, total, actions, routes, fetchList };
}
