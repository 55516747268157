import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import router from '@/router';
import { promotionService } from '@/services/promotion';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

export const PromotionPlanType = {
    Promotion: { value: 1, label: 'Promotion' },
    PromotionBooster: { value: 2, label: 'PromotionBooster' }
};

export function useMixins() {
    const router = useRouter();

    const selectedItemType = ref(null as number);
    const linkableItems = ref([] as Array<{ type: number; itemId: number; itemName: string }>);
    const linkableItemOption = computed(() =>
        linkableItems.value
            .filter(item => selectedItemType.value && item.type === selectedItemType.value)
            .map(item => ({ value: item.itemId, label: item.itemName }))
    );

    const fetchLinkableItems = async () => {
        const response = await promotionService.plan.getLinkableItems();
        if (response.code === 200) {
            linkableItems.value.push(...response.data);
        }
    };

    const getItemName = (id: number, type: number) =>
        linkableItems.value.find(item => item.itemId === id && item.type === type)?.itemName;

    const getItemLink = (id: number, type: number) => {
        if (!id || !type) return '';

        switch (type) {
            case PromotionPlanType.Promotion.value:
                return router.resolve(PROMOTION_ROUTER.ITEM.DETAIL(id))?.href;
            case PromotionPlanType.PromotionBooster.value:
                return router.resolve(PROMOTION_ROUTER.BOOSTER.DETAIL(id))?.href;
            default:
                return '';
        }
    };

    return { selectedItemType, linkableItemOption, linkableItems, fetchLinkableItems, getItemName, getItemLink };
}

export const Routes = {
    Index: () => router.push(PROMOTION_ROUTER.PLAN.INDEX()),
    Add: () => router.push(PROMOTION_ROUTER.PLAN.ADD()),
    Edit: (id: any) => router.push(PROMOTION_ROUTER.PLAN.EDIT(id)),
    Detail: (id: any) => router.push(PROMOTION_ROUTER.PLAN.DETAIL(id)),
    Translate: (id: any) => router.push(PROMOTION_ROUTER.PLAN.TRANSLATE(id))
};

export const Options = {
    Status: [
        { value: 0, label: 'None', background: 'secondary' },
        { value: 1, label: 'Enabled', background: 'info' },
        { value: 2, label: 'Publish', background: 'success' },
        { value: 3, label: 'Unpublish', background: 'warning' },
        { value: 4, label: 'Disabled', background: 'warning' }
    ],

    Type: Object.entries(PromotionPlanType).map(([, data]) => data)
};
