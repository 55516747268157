import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { duplicateService } from '@/services/duplicate';
import { timeUnits } from '@/utils/helpers';
import { validateDateAfter, validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useDuplicateItemAdd() {
    const router = useRouter();
    const toast = useToast();

    const { fetchAllBets, betOptions, betMap } = useGameBetList();

    const model = reactive({
        name: null,
        type: 0,
        betId: null,
        cost: 0,
        prizes: '',
        round: 0,
        game: 1,
        timeUnit: 0,
        timeDuration: 0,
        prepareSeconds: 0,
        openSeconds: 0,
        closeSeconds: 0,
        rankingSeconds: 0,
        publishTime: new Date(),
        unpublishTime: null,
        thumbnail: null,
        thumbnailFile: null
    });

    const validations = {
        name: { required },
        type: { required },
        betId: { required },
        cost: { required, integer, min: minValue(0) },
        openSeconds: { required, integer, min: minValue(1) },
        closeSeconds: { required, integer, min: minValue(1) },
        rankingSeconds: { required, integer, min: minValue(0) },
        round: { required, integer, min: minValue(1) },
        game: { required, integer, min: minValue(1) },
        prizes: { required, format: validateFormat(/^\d+:\d+(;\d+:\d+)*$/) },
        publishTime: { required, validateDate: validateDateAfter() }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await duplicateService.item.create(
                model.name,
                model.type,
                model.betId,
                model.cost,
                model.prizes,
                model.round,
                model.game,
                model.timeUnit,
                model.timeDuration,
                model.prepareSeconds,
                model.openSeconds,
                model.closeSeconds,
                model.rankingSeconds,
                model.publishTime.getTime(),
                model.unpublishTime?.getTime() ?? null,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push(DUPLICATE_ROUTER.ITEM.INDEX());
            }
        }
    };

    watch(
        () => model.betId,
        betId => {
            const bet = betMap.value.get(betId);
            model.cost = bet.data.cost;
            model.round = bet.data.maxRound;
        }
    );

    return { model, v$, fetchAllBets, betOptions, actions, timeUnits };
}
