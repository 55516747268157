import { NEWS_ROUTER } from '@/modules/news/router';
import { newsService } from '@/services/news';
import { App } from '@/store/applications';
import { useVuelidate } from '@vuelidate/core';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { useMixins } from './mixins';

export function useNewsAdd() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const { model, validations, fetch } = useMixins();
    const v$ = useVuelidate(validations, model);
    const appOptions = ref(null);

    // in case: clone item
    const fetchItem = async () => {
        const itemId = route.params?.id ?? null;
        if (itemId) {
            await fetch(itemId);
            model.cloneId = Number(itemId);
            model.forAppId = store.getters['application/appId'];
            appOptions.value = (store.getters['application/apps'] as Array<App>).map(app => ({
                label: app.name,
                value: app.id
            }));
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const startTime = model?.startTime?.getTime() ?? null;
            const endTime = model?.endTime?.getTime() ?? null;
            const publishTime = model.publishTime.getTime();
            const unpublishTime = model.unpublishTime.getTime();
            const fromRegisterTime = model?.fromRegisterTime?.getTime() ?? null;
            const toRegisterTime = model?.toRegisterTime?.getTime() ?? null;

            let target = null;
            if (model.targetType == 0) {
                target = 'link:' + model.target;
            } else if (model.targetType == 1) {
                target = 'popup:' + model.target;
            } else if (model.targetType == 2) {
                target = 'deeplink:' + model.target;
            }

            const response = await newsService.create(
                model.name,
                model.cloneId,
                model.forAppId,
                target,
                startTime,
                endTime,
                publishTime,
                unpublishTime,
                fromRegisterTime,
                toRegisterTime,
                model.imageFile,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push(NEWS_ROUTER.INDEX());
            }
        }
    };

    return { model, v$, appOptions, fetchItem, actions };
}
