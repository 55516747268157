export const PiggyBankStatus = [
    { label: 'Disabled', value: 3, background: 'warning' },
    { label: 'Cleaning', value: 2, background: 'warning' },
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'None', value: 0, background: 'secondary' }
];

export const PiggyBankBoostStatus = [
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'Started', value: 2, background: 'success' },
    { label: 'Ended', value: 3, background: 'warning' },
    { label: 'Disabled', value: 4, background: 'danger' },
    { label: 'None', value: 0, background: 'secondary' }
];
