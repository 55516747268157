import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-center mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_avatar_breadcrumb = _resolveComponent("app-avatar-breadcrumb")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_avatar_breadcrumb, {
      "category-id": _ctx.query.parentId
    }, null, 8, ["category-id"]),
    _createVNode(_component_app_card, {
      classes: "pb-0",
      label: "Function"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_app_button, {
            name: "Search",
            icon: "fa-search",
            theme: "info",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.fetchList(1)), ["prevent"])),
            disabled: _ctx.v$.$invalid
          }, null, 8, ["disabled"]),
          _createVNode(_component_app_button, {
            name: "Add category",
            icon: "fa-plus",
            theme: "info",
            href: _ctx.AVATAR_ROUTER.CATEGORY.ADD(_ctx.query.parentId)
          }, null, 8, ["href"]),
          (_ctx.query?.parentId)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_app_button, {
                  name: "Edit",
                  icon: "fa-edit",
                  theme: "info",
                  href: _ctx.AVATAR_ROUTER.CATEGORY.EDIT(_ctx.query.parentId)
                }, null, 8, ["href"]),
                _createVNode(_component_app_button, {
                  name: "View items",
                  icon: "fa-leaf",
                  theme: "info",
                  href: _ctx.AVATAR_ROUTER.ITEM.INDEX({ categoryId: _ctx.query.parentId })
                }, null, 8, ["href"])
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_app_form_row, {
          label: "Name",
          class: "col-md-6 mx-auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.query.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query.name) = $event)),
              validations: _ctx.v$.query,
              placeholder: "Please enter at least 3 characters"
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          page: _ctx.table.page,
          actions: _ctx.table.actions,
          onRefreshData: _ctx.fetchList
        }, null, 8, ["columns", "rows", "total", "page", "actions", "onRefreshData"])
      ]),
      _: 1
    })
  ], 64))
}