import { BOOSTER_ROUTES } from '@/modules/booster/router';
import { usePromotionItemAdd } from '@/modules/promotion/item/model/add';
import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { useVModal } from '@/plugins/modal/v-modal';
import router from '@/router';
import { promotionService } from '@/services/promotion';
import { computed, ref } from 'vue';

export enum PromotionBoosterLinkableType {
    PromotionItem = 1,
    BoosterItem = 2
}

export function useMixins() {
    const linkableItems = ref([]);
    const fetchLinkableItems = async () => {
        const response = await promotionService.booster.getLinkableItems();
        if (response.code === 200) {
            linkableItems.value = response.data.map((item: any) => ({
                id: item.itemId,
                name: item.itemName,
                type: item.type
            }));
        } else {
            linkableItems.value = [];
        }
    };

    const promotionOption = computed(() =>
        linkableItems.value
            .filter(item => item.type === PromotionBoosterLinkableType.PromotionItem)
            .map(item => ({ label: item.name, value: item.id }))
    );

    const boosterOption = computed(() =>
        linkableItems.value
            .filter(item => item.type === PromotionBoosterLinkableType.BoosterItem)
            .map(item => ({ label: item.name, value: item.id }))
    );

    const getItemName = (type: number, id: number) => {
        return linkableItems.value.find(item => item.id === id && item.type === type)?.name ?? '';
    };

    const getItemLink = (type: number, id: number) => {
        if (!type || !id) return '';

        switch (type) {
            case PromotionBoosterLinkableType.PromotionItem:
                return PROMOTION_ROUTER.ITEM.DETAIL(id);
            case PromotionBoosterLinkableType.BoosterItem:
                return BOOSTER_ROUTES.EDIT(id);
            default:
                return '';
        }
    };

    return { fetchLinkableItems, promotionOption, boosterOption, getItemLink, getItemName };
}

export function usePromotionModalProps() {
    const vModal = useVModal();

    const MODAL_NAME = 'create-promotion-modal';
    const { fetch, v$, model, actions: promotionActions } = usePromotionItemAdd();

    const actions = {
        createForLink: async () => {
            return await promotionActions.createForLink();
        },
        openModal: async () => {
            await fetch();
            v$.value.$reset();
            vModal.open({ name: MODAL_NAME });
        },
        closeModal: () => {
            vModal.close({ name: MODAL_NAME });
        }
    };

    return { MODAL_NAME, v$, model, actions };
}

export const Routes = {
    index: () => router.push(PROMOTION_ROUTER.BOOSTER.INDEX()),
    add: () => router.push(PROMOTION_ROUTER.BOOSTER.ADD()),
    edit: (id: any) => router.push(PROMOTION_ROUTER.BOOSTER.EDIT(id)),
    detail: (id: any) => router.push(PROMOTION_ROUTER.BOOSTER.DETAIL(id))
};
