import { useVDialog } from '@/plugins/dialog/v-dialog';
import { leagueService } from '@/services/league';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';

export function useLeagueList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList({ request: leagueService.item.list });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await leagueService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, fetchList, actions };
}
