
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppButton, AppCard, AppFileUpload } from '@/components';
import { useBoosterItemAdd } from '@/modules/booster/model/add';

export default defineComponent({
    name: 'BoosterItemAdd',
    components: { AppFormRow, AppInput, AppSelect, AppButton, AppCard, AppFileUpload },
    setup() {
        const { v$, model, actions, Options } = useBoosterItemAdd();

        return { v$, model, actions, Options };
    }
});
