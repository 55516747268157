import http from '@/utils/axios';

const item = {
    list({ offset, limit }: any) {
        return http.doGet('admin/subscription', { offset, limit });
    },

    get(itemId: string) {
        return http.doGet('admin/subscription/' + itemId);
    },

    create(
        cloneId: any,
        forAppId: any,
        name: any,
        packageId: any,
        priceId: any,
        price: any,
        maxReceivableTime: any,
        cycleChip: any,
        timeUnit: any,
        timeDuration: any,
        startTime: any
    ) {
        return http.doPost('admin/subscription', {
            name: name,
            packageId: packageId,
            priceId: priceId,
            price: price,
            maxReceivableTime: maxReceivableTime,
            cycleChip: cycleChip,
            timeUnit: timeUnit,
            timeDuration: timeDuration,
            startTime: startTime
        });
    },

    update(itemId: string, name: any, maxReceivableTime: any, cycleChip: any) {
        return http.doPost('admin/subscription/' + itemId, {
            name: name,
            maxReceivableTime: maxReceivableTime,
            cycleChip: cycleChip
        });
    },

    remove(itemId: string) {
        return http.doDelete('admin/subscription/' + itemId);
    }
};

const text = {
    get(itemId: string, locale: string) {
        return http.doGet('admin/subscription/' + itemId + '/text/' + locale);
    },

    update(itemId: string, locale: string, title: any, description: any) {
        return http.doPut('admin/subscription/' + itemId + '/text/' + locale, {
            title: title,
            description: description
        });
    },

    remove(itemId: string, locale: string) {
        return http.doDelete('admin/subscription/' + itemId + '/text/' + locale);
    }
};

export const subscriptionService = {
    item,
    text
};
