import { HOME_PAGE_ROUTER } from '@/modules/main/router';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export const AvatarGender = [
    { label: 'Female', value: 0, background: 'success' },
    { label: 'Male', value: 1, background: 'info' }
];

export function useAvatarGender() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        gender: null,
        image: null,
        imageStatus: null,
        imageFile: null,
        canDelete: false
    });

    const validations = {
        gender: { required },
        imageFile: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        if (!model.gender || model.gender < 0) {
            return;
        }

        const response = await avatarService.gender.get(model.gender);
        if (response.code === 200) {
            Object.assign(model, response.data);
            model.canDelete = true;
            model.imageFile = null;
        } else if (response.code === 404) {
            model.canDelete = false;
            model.imageFile = null;
        } else {
            toast.error(response.data);
            router.push(HOME_PAGE_ROUTER());
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await avatarService.gender.update(model.gender, model.imageFile);
            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.gender.remove(model.gender);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions, AvatarGender };
}
