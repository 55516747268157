import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { STORE_MODULES } from '@/store';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { Options } from './mixins';

export function usePromotionCode() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const featureId = stateFromStore<number>({
        moduleName: STORE_MODULES.PromotionCode.name,
        getterFuncName: 'feature',
        setterFuncName: 'changeFeature'
    });

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: promotionService.code.list,
        pageFromStore: pageFromStore(STORE_MODULES.PromotionCode.name)
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        routerByFeature: () => {
            const data = Options.features.find(feature => feature.value == featureId.value);
            if (data) {
                router.push({ name: data.routeName });
            }
        }
    };

    return { featureId, itemList, total, currentPage, actions, Options, fetchList };
}
