import { useVDialog } from '@/plugins/dialog/v-dialog';
import { arenaService } from '@/services/arena';
import { useFetchList } from '@/utils/useFetchList';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useArenaItemMedal() {
    const dialog = useVDialog();
    const route = useRoute();
    const toast = useToast();

    const arenaId = route.params.id;
    const model = reactive({
        linkedItems: [],
        unlinkedItems: []
    });

    const { itemList, total, fetchList } = useFetchList({
        request: arenaService.medal.getLinked,
        params: { arenaId },
        onSuccess: () => {
            model.linkedItems = [];
            model.unlinkedItems = [];
            itemList.value.forEach(medal => {
                if (medal.linkedArena) {
                    model.linkedItems.push(medal.id);
                } else {
                    model.unlinkedItems.push(medal.id);
                }
            });
        }
    });

    const actions = {
        link: (medalId: any) => {
            dialog.confirm({
                message: 'Are you want to link this medal?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.medal.link(arenaId, medalId);
                        if (response.code === 200) {
                            toast.success('Success');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        unlink: (medalId: any) => {
            dialog.confirm({
                message: 'Are you want to unlink this medal?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.medal.unlink(arenaId, medalId);
                        if (response.code === 200) {
                            toast.success('Success');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        linkAll: () => {
            dialog.confirm({
                message: 'Are you want to link all medals?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.medal.link(arenaId, model.unlinkedItems.join(','));

                        if (response.code === 200) {
                            toast.success('Success');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        unlinkAll: () => {
            dialog.confirm({
                message: 'Are you want to unlink all medals?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.medal.unlink(arenaId, model.linkedItems.join(','));

                        if (response.code === 200) {
                            toast.success('Success');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, itemList, total, fetchList, actions };
}
