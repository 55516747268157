import { useGameBetList } from '@/modules/game/bet/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { duplicateService } from '@/services/duplicate';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';

export function useDuplicateList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { fetchAllBets, betMap } = useGameBetList();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: duplicateService.item.list,
        pageFromStore: pageFromStore(STORE_MODULES.DuplicateItem.name),
        beforeFetch: async () => await fetchAllBets(),
        transformResponse: (list: any[]) =>
            list.map(item => ({ ...item, betName: betMap.value.get(item.betId)?.label ?? '' }))
    });

    const actions = {
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await duplicateService.item.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await duplicateService.item.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await duplicateService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, fetchList, actions };
}
