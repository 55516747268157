
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppCard, AppTable, AppButton } from '@/components';
import { useStarterPackRewardList } from '@/modules/starter/pack/reward/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { STARTER_PACK_ROUTER } from '@/modules/starter/pack/router';
import { renderOption } from '@/utils/helpers';
import { StarterPackRewardOptions } from '@/modules/starter/pack/reward/model/mixins';
import { RouterLink } from 'vue-router';

export default defineComponent({
    name: 'StarterPackRewardList',
    components: { AppTable, AppButton, AppCard },
    setup() {
        const { itemList, total, fetchList, starterPackId, canUpdateReward, actions } = useStarterPackRewardList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                {
                    label: 'Type',
                    display: (row: any) => renderOption(StarterPackRewardOptions.StarterPackRewardType, row.type)
                },
                {
                    label: 'Item',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.itemName,
                        props: { to: row.itemLink }
                    })
                },
                { label: 'Quantity', field: 'quantity' },
                { label: 'Actions', field: 'type' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !canUpdateReward.value,
                        onClick: () => actions.remove(row.type, row.data)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, starterPackId, canUpdateReward, STARTER_PACK_ROUTER };
    }
});
