
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppCard, AppTable, AppButton, AppSelect, AppFormRow } from '@/components';
import { useRfmPersonaList } from '@/modules/rfm/persona/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { RFM_ROUTER } from '@/modules/rfm/router';

export default defineComponent({
    name: 'RfmPersonaList',
    components: { AppTable, AppButton, AppSelect, AppFormRow, AppCard },
    setup() {
        const { itemList, total, fetchList, rfmGroupId, rfmGroupOptions, actions } = useRfmPersonaList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'Name', display: (row: any) => row.nameWithColor },
                { label: 'Rfm Group', field: 'groupName' },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        href: RFM_ROUTER.PERSONA.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchList();
        });

        return { table, fetchList, rfmGroupId, rfmGroupOptions, RFM_ROUTER };
    }
});
