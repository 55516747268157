import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function usePromotionPlanList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { fetchLinkableItems, getItemName, getItemLink } = useMixins();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: promotionService.plan.list,
        pageFromStore: pageFromStore(STORE_MODULES.PromotionPlan.name),
        beforeFetch: async () => await fetchLinkableItems(),
        transformResponse: (list: any[]) =>
            list.map(promotionPlan => ({
                ...promotionPlan,
                linkedItemName: getItemName(promotionPlan.itemId, promotionPlan.type),
                linkedItemLink: getItemLink(promotionPlan.itemId, promotionPlan.type)
            }))
    });

    const actions = {
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.plan.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.plan.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        }
                    }
                }
            });
        },
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.plan.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, fetchList, actions };
}
