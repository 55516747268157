import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_date_time_picker = _resolveComponent("app-date-time-picker")!
  const _component_app_image_holder = _resolveComponent("app-image-holder")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Update Duplicate"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Bet" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.betOptions,
              modelValue: _ctx.model.betId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.betId) = $event)),
              modelModifiers: { number: true },
              disabled: ""
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Time Unit" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.timeUnits,
              modelValue: _ctx.model.timeUnit,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.timeUnit) = $event)),
              modelModifiers: { number: true },
              disabled: ""
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Time Duration" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.timeDuration,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.timeDuration) = $event)),
              modelModifiers: { number: true },
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Prepare Seconds" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.prepareSeconds,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.prepareSeconds) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.prepareSeconds
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Open Seconds" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.openSeconds,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.openSeconds) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.openSeconds
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Close Seconds" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.closeSeconds,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.closeSeconds) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.closeSeconds
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Ranking Seconds" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.rankingSeconds,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.rankingSeconds) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.rankingSeconds
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Cost (chip)" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.cost,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.cost) = $event)),
              modelModifiers: { number: true },
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Prizes" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.prizes,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.prizes) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Game" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.game,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.game) = $event)),
              modelModifiers: { number: true },
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Round" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.round,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.round) = $event)),
              modelModifiers: { number: true },
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Publish Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.publishTime,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.publishTime) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Unpublish Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.unpublishTime,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.unpublishTime) = $event)),
              validations: _ctx.v$.unpublishTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Thumbnail" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.thumbnail
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.thumbnailFile,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.thumbnailFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Update",
              theme: "info",
              icon: "fa-save",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}