
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppInput, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useLocale } from '@/modules/locale/model/locale';
import { renderOption } from '@/utils/helpers';

export default defineComponent({
    name: 'LocaleList',
    components: { AppTable, AppCard, AppInput, AppButton },
    setup() {
        const { criteria, itemList, total, fetchList, currentPage, actions } = useLocale();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Language', field: 'language' },
                { label: 'Country', field: 'country' },
                { label: 'Mode', display: (row: any) => renderOption(statusList, row.status) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-plus',
                        theme: 'primary',
                        tooltip: 'Add',
                        isShow: row.status === 0,
                        onclick: async () => await actions.add(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-eye-slash',
                        theme: 'warning',
                        tooltip: 'Unpublish',
                        isShow: row.status === 2,
                        onclick: async () => await actions.unpublish(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-check',
                        theme: 'success',
                        tooltip: 'Set default',
                        isShow: row.status === 2,
                        onclick: async () => await actions.setDefault(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'primary',
                        tooltip: 'Publish',
                        isShow: row.status === 1,
                        onclick: async () => await actions.publish(row.id)
                    }),
                    group: 3
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        isShow: row.status === 1,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { fetchList, currentPage, table, criteria };
    }
});

const statusList = [
    { label: 'Default', background: 'success', value: 3 },
    { label: 'Published', background: 'primary', value: 2 },
    { label: 'Unpublished', background: 'info', value: 1 },
    { label: 'Unsupported', background: 'secondary', value: 0 }
];
