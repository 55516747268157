import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { duplicateService } from '@/services/duplicate';
import { timeUnits } from '@/utils/helpers';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useDuplicateItemDetail() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { fetchAllBets, betMap } = useGameBetList();

    const model = reactive({
        id: route.params.id,
        name: null,
        type: 0,
        betId: '',
        betName: '',
        cost: 0,
        prizes: '',
        round: 0,
        game: 1,
        timeUnit: 0,
        timeDuration: 0,
        prepareSeconds: 0,
        openSeconds: 0,
        closeSeconds: 0,
        rankingSeconds: 0,
        status: 0,
        publishTime: null,
        unpublishTime: null,
        nextStartTime: null,
        nextRankingTime: null,
        thumbnail: null,
        thumbnailFile: null,
        thumbnailStatus: null,
        canDelete: false
    });

    const fetch = async () => {
        await fetchAllBets();

        const response = await duplicateService.item.get(model.id);
        if (response.code === 200) {
            const data = response.data;
            data.betName = betMap.value.get(data.betId)?.label ?? '';

            Object.assign(model, data);
        } else {
            toast.error(response.data);
            router.push(DUPLICATE_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await duplicateService.item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({ name: 'DuplicateItemList' });
                        }
                    }
                }
            });
        }
    };

    return { model, fetch, actions, timeUnits };
}
