import { Characters } from '@/utils/stringUtils';
import { validateFormat } from '@/utils/validatorHelpers';
import useVuelidate, { Validation } from '@vuelidate/core';
import { helpers, minValue, numeric, required } from '@vuelidate/validators';
import { computed, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

export function useMixins() {
    const route = useRoute();

    const model = reactive({
        id: route.params?.id ? Number(route.params?.id) : null,
        totalLevel: 0,
        gachaWeights: '',
        interactiveSeconds: 0,
        rewardList: [] as Array<PetReward>
    });

    // monitoring totalLevel to update reward size
    watch(
        () => model.totalLevel,
        totalLevel => {
            if (!totalLevel) return;

            const { rewardList } = model;
            const currentSize = rewardList.length;

            if (currentSize < totalLevel) {
                for (let i = currentSize; i < totalLevel; i++) {
                    rewardList.push(new PetReward(i + 1));
                }
            } else if (currentSize > totalLevel) {
                rewardList.splice(totalLevel);
            }

            // the requiredExperience of the last level must be 0
            if (rewardList.length > 0) {
                rewardList[rewardList.length - 1].requiredExperience = 0;
            }
        }
    );

    const validations = {
        id: { required, numeric },
        totalLevel: { required, numeric, min: minValue(1) },
        interactiveSeconds: { required, numeric, min: minValue(1) },
        gachaWeights: { required, format: validateFormat(/^\d+:\d+(,\d+:\d+)*$/) },
        rewardList: {
            $each: helpers.forEach({
                level: { required, numeric, min: minValue(1) },
                requiredExperience: { required, numeric, min: minValue(0) },
                creditReward: { required, numeric, min: minValue(0) },
                heartReward: { required, numeric, min: minValue(0) },
                boosterTypeReward: { required, numeric, min: minValue(0) },
                boosterQuantityReward: { required, numeric, min: minValue(0) }
            }),
            required: helpers.withMessage(`There is not any pet reward`, (value: string) => value.length > 0)
        }
    };

    const v$ = useVuelidate(validations, model);

    const rewardV$ = (index: number, propName: string) =>
        computed(() => {
            const $errors = (v$.value.rewardList as Validation)?.$each?.$response?.$errors?.[index]?.[propName];
            return { $errors };
        });

    return { model, v$, rewardV$, SelectableBooster };
}

export class PetReward {
    constructor(
        public level: number,
        public requiredExperience: number = 0,
        public creditReward: number = 0,
        public heartReward: number = 0,
        public boosterTypeReward: number = 0,
        public boosterQuantityReward: number = 0,
        public boosterTypeRewardName: string = getBoosterName(boosterTypeReward)
    ) {}

    convertBoosterRewardToString(): string {
        return [this.boosterTypeReward, this.boosterQuantityReward].join(Characters.Colon);
    }
}

// TODO: use Options.BoosterItemType from importing module 'Booster' instead of
export const SelectableBooster = [
    { value: 0, label: 'None' },
    { value: 1, label: 'Shuffle' },
    { value: 2, label: 'Search' },
    { value: 3, label: 'Locate' },
    { value: 4, label: 'Fifty' },
    { value: 5, label: 'TimeExtend' },
    { value: 6, label: 'ChangeQuestion' }
];

export const getBoosterName = (type: any) => SelectableBooster.find(item => item.value == type)?.label;

export const convertPetRewardListFromStrings = (
    experienceByLevel: string,
    creditReward: string,
    heartReward: string,
    boosterReward: string
): Array<PetReward> => {
    const result: Array<PetReward> = [];
    const experienceByLevelValues = experienceByLevel.split(Characters.Comma);
    const creditRewardValues = creditReward.split(Characters.Comma);
    const heartRewardValues = heartReward.split(Characters.Comma);
    const boosterRewardValues = boosterReward.split(Characters.Comma);
    let curboosterRewardValue;

    for (let i = 0, level = 1; i < experienceByLevelValues.length; i++, level++) {
        curboosterRewardValue = boosterRewardValues[i].split(Characters.Colon);
        result.push(
            new PetReward(
                level,
                Number(experienceByLevelValues[i]),
                Number(creditRewardValues[i]),
                Number(heartRewardValues[i]),
                Number(curboosterRewardValue[0]),
                Number(curboosterRewardValue[1])
            )
        );
    }

    return result;
};

export const convertPetRewardListToStrings = (petRewards: Array<PetReward>) => {
    const experienceByLevels: Array<number> = [];
    const creditRewards: Array<number> = [];
    const heartRewards: Array<number> = [];
    const boosterRewards: Array<string> = [];

    petRewards.forEach(reward => {
        experienceByLevels.push(reward.requiredExperience);
        creditRewards.push(reward.creditReward);
        heartRewards.push(reward.heartReward);
        boosterRewards.push(reward.convertBoosterRewardToString());
    });

    return {
        experienceByLevels: experienceByLevels.join(Characters.Comma),
        creditRewards: creditRewards.join(Characters.Comma),
        heartRewards: heartRewards.join(Characters.Comma),
        boosterRewards: boosterRewards.join(Characters.Comma)
    };
};
