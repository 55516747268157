
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppCard, AppTable, AppButton, AppImageHolder } from '@/components';
import useShop from '@/modules/shop/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { SHOP_ROUTER } from '@/modules/shop/router';
import { renderBoolean, renderOption } from '@/utils/helpers';
import { Options } from '@/modules/shop/model/mixins';

export default defineComponent({
    name: 'ShopList',
    components: { AppTable, AppButton, AppCard },
    setup() {
        const { itemList, total, fetchList, actions } = useShop();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'Id', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Type', display: (row: any) => renderOption(Options.ShopItemType, row.type) },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Credit', field: 'credit' },
                { label: 'Price', field: 'price' },
                { label: 'Is published', display: (row: any) => renderBoolean(row.isPublished) },
                { label: 'Is best seller', display: (row: any) => renderBoolean(row.isBestseller) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        href: SHOP_ROUTER.TRANSLATE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        href: SHOP_ROUTER.EDIT(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        isShow: row.canClone,
                        href: SHOP_ROUTER.CLONE(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, SHOP_ROUTER };
    }
});
