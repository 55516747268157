import { DUPLICATE_ROUTER } from '@/modules/duplicate/router';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { duplicateService } from '@/services/duplicate';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useRouter } from 'vue-router';

export function useDuplicateCycleList() {
    const router = useRouter();

    const { fetchAllBets, betMap } = useGameBetList();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: duplicateService.cycle.list,
        pageFromStore: pageFromStore(STORE_MODULES.DuplicateCycle.name),
        beforeFetch: async () => await fetchAllBets(),
        transformResponse: async (list: any[]) => {
            const itemIds = new Set(list.map(cycle => cycle.duplicateItemId));
            const itemResponse = await duplicateService.item.getList(Array.from(itemIds).join(','));
            if (itemResponse.code === 200) {
                const mapItems = new Map<number, any>(itemResponse.data.map((item: any) => [item.id, item]));

                return list.map(cycle => {
                    const item = mapItems.get(cycle.duplicateItemId);
                    return {
                        ...cycle,
                        itemName: item?.name,
                        itemGame: item?.game,
                        betId: item?.betId,
                        betName: betMap.value.get(item?.betId)?.label
                    };
                });
            } else {
                router.push(DUPLICATE_ROUTER.ITEM.INDEX());
                return [];
            }
        }
    });

    return { itemList, total, currentPage, fetchList };
}
