
import { defineComponent } from 'vue';
import { AppError } from '@/components';

export default defineComponent({
    name: 'AppInput',
    components: { AppError },
    props: {
        modelValue: { type: [String, Number], default: null },
        icon: { type: String, default: null },
        type: { type: String, default: null },
        placeholder: { type: String, default: '' },
        autocomplete: { type: String, default: 'off' },
        disabled: { type: Boolean, default: false },
        validations: { type: Object, default: null }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        function onValueChange(event: any) {
            emit('update:modelValue', event.target.value);

            if (props.validations && typeof props.validations?.$touch === 'function') {
                props.validations.$touch();
            }
        }

        return { onValueChange };
    }
});
