import { Characters } from '@/utils/stringUtils';
import useVuelidate from '@vuelidate/core';
import { helpers, minValue, numeric, required, requiredIf } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';

export class ChestReward {
    constructor(
        public index: number,
        public requiredStar: number,
        public creditReward: number,
        public heartReward: number,
        public boosterTypeReward: number,
        public boosterQuantityReward: number,
        public boosterTypeRewardName: string = getBoosterName(boosterTypeReward)
    ) {}

    convertBoosterRewardToString(): string {
        return [this.boosterTypeReward, this.boosterQuantityReward].join(Characters.Colon);
    }
}

// TODO: use Options.BoosterItemType from importing module 'Booster' instead of
export const SelectableBooster = [
    { value: 0, label: 'None' },
    { value: 1, label: 'Shuffle' },
    { value: 2, label: 'Search' },
    { value: 3, label: 'Locate' },
    { value: 4, label: 'Fifty' },
    { value: 5, label: 'TimeExtend' },
    { value: 6, label: 'ChangeQuestion' }
];

export const getBoosterName = (type: any) => SelectableBooster.find(item => item.value == type)?.label;

export const convertChestRewardListFromStrings = (
    chestByStar: string,
    chestRewardCredit: string,
    chestRewardHeart: string,
    chestRewardBooster: string
): Array<ChestReward> => {
    const result: Array<ChestReward> = [];
    const chestByStarValues = chestByStar.split(Characters.Comma);
    const chestRewardCreditValues = chestRewardCredit.split(Characters.Comma);
    const chestRewardHeartValues = chestRewardHeart.split(Characters.Comma);
    const chestRewardBoosterValues = chestRewardBooster.split(Characters.Comma);
    let curChestRewardBoosterValues;

    for (let index = 0; index < chestByStarValues.length; index++) {
        curChestRewardBoosterValues = chestRewardBoosterValues[index].split(Characters.Colon);
        result.push(
            new ChestReward(
                index,
                Number(chestByStarValues[index]),
                Number(chestRewardCreditValues[index]),
                Number(chestRewardHeartValues[index]),
                Number(curChestRewardBoosterValues[0]),
                Number(curChestRewardBoosterValues[1])
            )
        );
    }

    return result;
};

export const convertChestRewardListToStrings = (chests: Array<ChestReward>) => {
    const chestByStars: Array<number> = [];
    const chestRewardCredits: Array<number> = [];
    const chestRewardHearts: Array<number> = [];
    const chestRewardBoosters: Array<string> = [];

    chests.forEach(chest => {
        chestByStars.push(chest.requiredStar);
        chestRewardCredits.push(chest.creditReward);
        chestRewardHearts.push(chest.heartReward);
        chestRewardBoosters.push(chest.convertBoosterRewardToString());
    });

    return {
        chestByStars: chestByStars.join(Characters.Comma),
        chestRewardCredits: chestRewardCredits.join(Characters.Comma),
        chestRewardHearts: chestRewardHearts.join(Characters.Comma),
        chestRewardBoosters: chestRewardBoosters.join(Characters.Comma)
    };
};

export function useMixins() {
    const route = useRoute();

    const model = reactive({
        id: route.params?.id ? Number(route.params?.id) : null,
        name: null,
        selectedIap: null,
        packageId: '',
        price: 0,
        chestRequiredStar: 1,
        chestCreditReward: 0,
        chestHeartReward: 0,
        chestBoosterTypeReward: 0,
        chestBoosterQuantityReward: 0,
        chestRewardList: [] as Array<ChestReward>,

        get chestIndex() {
            return this.chestRewardList.length;
        }
    });

    const validations = {
        id: { required, numeric },
        name: { required },
        packageId: { requiredIf: requiredIf(() => model.price > 0) },
        price: { required, numeric, min: minValue(0) },
        chestRequiredStar: { required, numeric, min: minValue(1) },
        chestCreditReward: { required, numeric, min: minValue(0) },
        chestHeartReward: { required, numeric, min: minValue(0) },
        chestBoosterQuantityReward: { required, numeric, min: minValue(0) },
        chestBoosterTypeReward: { requiredIf: requiredIf(() => model.chestBoosterQuantityReward > 0) },
        chestRewardList: {
            validData: helpers.withMessage(`There is not any chest reward`, (value: string) => value.length > 0)
        }
    };

    const v$ = useVuelidate(validations, model);

    const addChestReward = () => {
        v$.value.$touch();
        if (
            v$.value.chestRequiredStar.$invalid ||
            v$.value.chestCreditReward.$invalid ||
            v$.value.chestHeartReward.$invalid ||
            v$.value.chestBoosterQuantityReward.$invalid ||
            v$.value.chestBoosterTypeReward.$invalid
        ) {
            return;
        }

        model.chestRewardList.push(
            new ChestReward(
                model.chestRewardList.length,
                model.chestRequiredStar,
                model.chestCreditReward,
                model.chestHeartReward,
                model.chestBoosterTypeReward,
                model.chestBoosterQuantityReward
            )
        );

        clearChestForm();
    };

    const removeChestReward = (index: number) => {
        model.chestRewardList = model.chestRewardList.filter(chest => chest.index < index);
    };

    const clearChestForm = () => {
        model.chestRequiredStar = 1;
        model.chestCreditReward = 0;
        model.chestHeartReward = 0;
        model.chestBoosterQuantityReward = 0;
        model.chestBoosterTypeReward = 0;
    };

    return {
        model,
        v$,
        addChestReward,
        removeChestReward,
        clearChestForm,
        SelectableBooster,
        convertChestRewardListFromStrings
    };
}
