/* eslint-disable @typescript-eslint/no-unused-vars */
import http from '@/utils/axios';

const recency = {
    list({ groupId }: any = {}) {
        return http.doGet('/admin/rfm/recency', { groupId: groupId });
    },
    get(id: any) {
        return http.doGet('/admin/rfm/recency/' + id);
    },
    create(fromDay: any, groupId: any) {
        return http.doPost('/admin/rfm/recency', {
            fromDay: fromDay,
            groupId: groupId
        });
    },
    update(id: any, fromDay: any) {
        return http.doPut('/admin/rfm/recency/' + id, { fromDay: fromDay });
    },
    remove(id: any) {
        return http.doDelete('/admin/rfm/recency/' + id);
    }
};

const frequency = {
    list({ groupId }: any = {}) {
        return http.doGet('/admin/rfm/frequency', { groupId: groupId });
    },
    get(id: any) {
        return http.doGet('/admin/rfm/frequency/' + id);
    },
    create(fromTransaction: any, groupId: any) {
        return http.doPost('/admin/rfm/frequency', {
            fromTransaction: fromTransaction,
            groupId: groupId
        });
    },
    update(id: any, fromTransaction: any) {
        return http.doPut('/admin/rfm/frequency/' + id, {
            fromTransaction: fromTransaction
        });
    },
    remove(id: any) {
        return http.doDelete('/admin/rfm/frequency/' + id);
    }
};

const monetary = {
    list({ groupId }: any = {}) {
        return http.doGet('/admin/rfm/monetary', { groupId: groupId });
    },
    get(id: any) {
        return http.doGet('/admin/rfm/monetary/' + id);
    },
    create(fromPayment: any, groupId: any) {
        return http.doPost('/admin/rfm/monetary', {
            fromPayment: fromPayment,
            groupId: groupId
        });
    },
    update(id: any, fromPayment: any) {
        return http.doPut('/admin/rfm/monetary/' + id, {
            fromPayment: fromPayment
        });
    },
    remove(id: any) {
        return http.doDelete('/admin/rfm/monetary/' + id);
    }
};

const persona = {
    list({ groupId }: any = {}) {
        return http.doGet('/admin/rfm/persona', { groupId: groupId });
    },
    listForOtherApp({ forAppId }: any = {}) {
        const params = forAppId ? { appId: forAppId } : {};
        return http.doGet('/admin/rfm/persona', params);
    },
    get(id: any) {
        return http.doGet('/admin/rfm/persona/' + id);
    },
    create(name: any, groupId: any) {
        return http.doPost('/admin/rfm/persona', {
            name: name,
            groupId: groupId
        });
    },
    update(id: any, name: any) {
        return http.doPut('/admin/rfm/persona/' + id, { name: name });
    },
    remove(id: any) {
        return http.doDelete('/admin/rfm/persona/' + id);
    }
};

const segmentation = {
    list({ groupId }: any) {
        return http.doGet('admin/rfm/segmentation/group/' + groupId);
    },

    get(accountId: any) {
        return http.doGet('admin/rfm/segmentation/account/' + accountId);
    },

    update(groupId: any, recencyList: any, frequencyList: any, monetaryList: any, personaList: any) {
        return http.doPut('admin/rfm/segmentation', {
            groupId: groupId,
            recencyList: recencyList,
            frequencyList: frequencyList,
            monetaryList: monetaryList,
            personaList: personaList
        });
    }
};

const group = {
    list() {
        return http.doGet('/admin/rfm/group');
    },
    get(id: any) {
        return http.doGet('/admin/rfm/group/' + id);
    },
    create(name: any) {
        return http.doPost('/admin/rfm/group', {
            name: name
        });
    },
    update(id: any, name: any) {
        return http.doPut('/admin/rfm/group/' + id, { name: name });
    },
    remove(id: any) {
        return http.doDelete('/admin/rfm/group/' + id);
    }
};

export const rfmService = {
    recency,
    frequency,
    monetary,
    persona,
    segmentation,
    group
};
