import { useVDialog } from '@/plugins/dialog/v-dialog';
import { itemService } from '@/services/item';
import { useFetchList } from '@/utils/useFetchList';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';

export function useItemSettingList() {
    const toast = useToast();
    const dialog = useVDialog();

    const canAddNewItem = ref(false);
    const { itemList, total, fetchList } = useFetchList({
        request: itemService.setting.list,
        onSuccess: () => {
            const now = new Date().getTime();
            // already have next item or not
            canAddNewItem.value = !itemList.value.some(item => item.startTime > now);
        }
    });

    const actions = {
        remove: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await itemService.setting.remove(itemId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, canAddNewItem, fetchList, actions };
}
