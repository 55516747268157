import { AVATAR_ROUTER } from '@/modules/avatar/router';
import { ITEM_ROUTER } from '@/modules/item/router';
import { FEATURES } from '@/modules/promotion/code/model/mixins';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { STORE_MODULES } from '@/store';
import { pageFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import { useToast } from 'vue-toastification';

export function useAvatarBundleList() {
    const dialog = useVDialog();
    const toast = useToast();

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: avatarService.bundle.list,
        pageFromStore: pageFromStore(STORE_MODULES.AvatarBundle.name)
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.bundle.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchList();
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => AVATAR_ROUTER.BUNDLE.ADD(),
        edit: (bundleId: any) => AVATAR_ROUTER.BUNDLE.EDIT(bundleId),
        item: (bundleId: any) => AVATAR_ROUTER.BUNDLE.ITEM(bundleId),
        specialShop: (bundleId: any) => ITEM_ROUTER.SYSTEM.SPECIAL_SHOP(FEATURES.AVATAR_BUNDLE, bundleId)
    };

    return { itemList, total, currentPage, fetchList, actions, routes };
}
