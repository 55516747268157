import { applicationService } from '@/services/applications';
import { STORE_MODULES } from '@/store';
import { StoreAccountFilter } from '@/store/account';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';
import useVuelidate from '@vuelidate/core';
import { email, minLength, numeric, requiredIf } from '@vuelidate/validators';
import { Ref, computed } from 'vue';
import { AccountAppStatus, AccountFilterType, useAccountAppMixins } from './mixins';

export function useAccountList() {
    const filter: Ref<StoreAccountFilter> = stateFromStore<StoreAccountFilter>({
        moduleName: STORE_MODULES.Account.name,
        getterFuncName: 'filter',
        setterFuncName: 'setFilter'
    });

    const validations = {
        criteria: {
            requiredIf: requiredIf(() => filter.value.type !== AccountFilterType.All.value),
            valueValid: computed(() => {
                switch (filter.value.type) {
                    case AccountFilterType.All.value:
                        return true;
                    case AccountFilterType.Name.value:
                        return minLength(3);
                    case AccountFilterType.Email.value:
                        return email;
                    case AccountFilterType.FacebookID.value:
                    case AccountFilterType.AccountID.value:
                    case AccountFilterType.CreditBuy.value:
                        return numeric;
                    default:
                        return false;
                }
            })
        }
    };

    const v$ = useVuelidate(validations, filter);

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: applicationService.account.list,
        params: filter,
        pageFromStore: pageFromStore(STORE_MODULES.Account.name),
        preCheck: () => {
            v$.value.$touch();
            return !v$.value.$invalid;
        },
        transformResponse: (list: any[]) =>
            list.map(item => {
                let status = AccountAppStatus.None.value;
                if (item.isBanned) status = AccountAppStatus.Banned.value;
                if (item.isBanChat) status = AccountAppStatus.BanChat.value;
                if (item.isMute) status = AccountAppStatus.Mute.value;
                if (item.isDeleting) status = AccountAppStatus.Deleting.value;

                return { ...item, status };
            })
    });

    const { ban, unban, banChat, unbanChat, mute, unmute, deletee, undelete } = useAccountAppMixins().actionMixins;
    const actions = {
        delete: (id: any) => deletee(id, fetchList),
        undelete: (id: any) => undelete(id, fetchList),
        ban: (id: any) => ban(id, fetchList),
        unban: (id: any) => unban(id, fetchList),
        mute: (id: any) => mute(id, fetchList),
        unmute: (id: any) => unmute(id, fetchList),
        banChat: (id: any) => banChat(id, fetchList),
        unbanChat: (id: any) => unbanChat(id, fetchList)
    };

    return { v$, filter, itemList, total, currentPage, fetchList, actions };
}
