import { useLocale } from '@/modules/locale/model/locale';
import { transactionService } from '@/services/transaction';
import { STORE_MODULES } from '@/store';
import { StoreTransactionFilter } from '@/store/transaction';
import { pageFromStore, stateFromStore } from '@/utils/storeHelper';
import { useFetchList } from '@/utils/useFetchList';

export function useTransactionList() {
    const { localeSupportedOptions, getListSupported } = useLocale();

    const filter = stateFromStore<StoreTransactionFilter>({
        moduleName: STORE_MODULES.Transaction.name,
        getterFuncName: 'filter',
        setterFuncName: 'setFilter'
    });

    const { itemList, total, currentPage, fetchList } = useFetchList({
        request: transactionService.text.list,
        params: filter,
        pageFromStore: pageFromStore(STORE_MODULES.Transaction.name),
        preCheck: () => filter.value.locale != null,
        beforeFetch: async () => await getListSupported()
    });

    return { filter, itemList, total, currentPage, fetchList, localeSupportedOptions };
}
