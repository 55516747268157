
import { defineComponent, onMounted, reactive, toRef } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard, AppSelect } from '@/components';
import { AppTable, AppSimpleTable, AppError, AppFileUpload } from '@/components';
import { usePromotionBoosterAdd } from '@/modules/promotion/booster/model/add';
import { IColumn, IAction } from '@/components/table/model';
import { Options as BoosterOptions } from '@/modules/booster/model/mixins';
import { renderOption } from '@/utils/helpers';

export default defineComponent({
    name: 'PromotionBoosterAdd',
    components: {
        AppFormRow,
        AppInput,
        AppButton,
        AppCard,
        AppSelect,
        AppTable,
        AppSimpleTable,
        AppError,
        AppFileUpload
    },
    setup() {
        const {
            v$,
            model,
            fetch,
            promotionOption,
            boosterOptionExcludeAdded,
            actions,
            MODAL_NAME,
            promotionModel,
            promotionV$
        } = usePromotionBoosterAdd();

        const table = reactive({
            rows: toRef(model, 'addedBoosterList'),
            columns: [
                { label: 'Type', display: row => renderOption(BoosterOptions.ItemType, row.type) },
                { label: 'Name', field: 'name' },
                { label: 'Quantity', field: 'quantity' },
                { label: 'Actions', field: 'type' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: (evt: any) => {
                            evt.preventDefault();
                            actions.removeBoosterItem(row.type);
                        }
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetch();
        });

        return {
            v$,
            model,
            table,
            promotionOption,
            boosterOptionExcludeAdded,
            actions,
            MODAL_NAME,
            promotionModel,
            promotionV$
        };
    }
});
