
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton } from '@/components';
import { AppFileUpload, AppImageHolder, AppSimpleTable, AppDateTimePicker } from '@/components';
import { usePromotionItemAdd } from '@/modules/promotion/item/model/add';
import { Options, DisplayType } from '@/modules/promotion/item/model/mixins';

export default defineComponent({
    name: 'PromotionItemAdd',
    components: {
        AppFormRow,
        AppInput,
        AppCard,
        AppFileUpload,
        AppImageHolder,
        AppSelect,
        AppSimpleTable,
        AppButton,
        AppDateTimePicker
    },
    setup() {
        const { model, v$, fetch, actions, appOption } = usePromotionItemAdd();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, appOption, Options, DisplayType };
    }
});
