export const VersionOptions = {
    Store: [
        { value: 0, label: 'Apple' },
        { value: 1, label: 'Google' }
        // { value: 2, label: 'WebGL' }
    ],

    Status: [
        { value: 0, label: 'Unsupport' },
        { value: 1, label: 'Support' },
        { value: 2, label: 'Active' }
    ]
};
