
import { defineComponent, markRaw, onMounted, reactive, toRef } from 'vue';
import { AppCard, AppTable, AppButton, AppFormRow, AppInput, AppSelect, AppImageHolder } from '@/components';
import { useStarterPackDetail } from '@/modules/starter/pack/model/detail';
import { IAction, IColumn } from '@/components/table/model';
import { renderOption, renderBoolean } from '@/utils/helpers';
import { StarterPackRewardOptions, StarterPackRewardType } from '@/modules/starter/pack/reward/model/mixins';
import { RouterLink } from 'vue-router';

export default defineComponent({
    name: 'StarterPackDetail',
    components: { AppCard, AppTable, AppButton, AppFormRow, AppInput, AppSelect, AppImageHolder },
    setup() {
        const { model, rewardDetailtList, beforeFetch, actions, rewardCreationModal } = useStarterPackDetail();

        const table = reactive({
            rows: toRef(rewardDetailtList, 'value'),
            columns: [
                {
                    label: 'Type',
                    display: row => renderOption(StarterPackRewardOptions.StarterPackRewardType, row.type)
                },
                {
                    label: 'Item',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.itemName,
                        props: { to: row.itemLink }
                    })
                },
                { label: 'Quantity', field: 'quantity' },
                { label: 'Actions', field: 'type' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !model.canEdit,
                        onClick: () => actions.removeReward(row.type, row.data)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await beforeFetch();
        });

        return {
            model,
            table,
            actions,
            rewardCreationModal,
            StarterPackRewardOptions,
            StarterPackRewardType,
            renderBoolean
        };
    }
});
