import { PROMOTION_ROUTER } from '@/modules/promotion/router';
import { useRfmPersonaList } from '@/modules/rfm/persona/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePromotionItemDetail() {
    const dialog = useVDialog();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const { personaMap, fetchPersonaAll } = useRfmPersonaList();

    const model = reactive({
        id: route.params.id,
        name: '',
        status: null,
        displayType: 0,
        fromTime: null,
        toTime: null,
        expectedPackage: 0,
        prototypeId: null,
        personas: [],
        canEnable: false,
        canDisable: false,
        canDelete: false
    });

    const fetch = async () => {
        await fetchPersonaAll();

        const response = await promotionService.item.get(model.id);
        if (response.code === 200) {
            const result = response.data;
            model.id = result.id;
            model.name = result.name;
            model.status = result.status;
            model.displayType = result.displayType;
            model.fromTime = new Date(result.fromTime);
            model.toTime = result?.toTime ? new Date(result.toTime) : null;
            model.expectedPackage = result.expectedPackage;
            model.prototypeId = result.usedPrototypeId;
            model.canEnable = result.canEnable;
            model.canDisable = result.canDisable;
            model.canDelete = result.canDelete;
            model.personas = result.personas.map((per: any) => ({
                ...per,
                rfmPersonaName: personaMap.value.get(per.rfmPersonaId)?.name
            }));
        } else {
            toast.error('Not found');
            router.push(PROMOTION_ROUTER.ITEM.INDEX());
        }
    };

    const actions = {
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push(PROMOTION_ROUTER.ITEM.INDEX());
                        }
                    }
                }
            });
        },
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.disable(model.id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetch();
                        }
                    }
                }
            });
        },
        enable: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.enable(model.id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, fetch, actions };
}
